import css from './WhiteForm.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from './Text'

const WhiteForm = ({ h1, h2, header, intro, form, footer }) => {
  return (
    <section className={css.container}>
      {h1 && (
        <header className={css.h1}>
          <Text tag="h1" variant="h1">
            {h1}
          </Text>
        </header>
      )}

      <div className={css.form}>
        {h2 && (
          <div className={css.h2}>
            <Text tag="h2" variant="h3">
              {h2}
            </Text>
          </div>
        )}

        {header && <div className={css.header}>{header}</div>}

        {intro && (
          <div className={css.intro}>
            {typeof intro === 'string' ? <Text tag="p">{intro}</Text> : intro}
          </div>
        )}

        {form}
      </div>

      {footer && <footer className={css.footer}>{footer}</footer>}
    </section>
  )
}

WhiteForm.propTypes = {
  h1: PropTypes.node,
  h2: PropTypes.string,
  header: PropTypes.node,
  intro: PropTypes.node,
  form: PropTypes.node,
  footer: PropTypes.node,
}

export default WhiteForm
