const getValue = (obj) => {
  return obj[process.env.REACT_APP_ENV] || obj.development
}

export const STRIPE_API_KEY = getValue({
  production: 'pk_live_YkNVV2unSxFIzsLS32uZeUKk00RB4pO4yF',
  development: 'pk_test_K7Rpa9Xafhp9L2xJlsETmAeK006Nz42oBC',
})

export const PUBNUB_PUBLISH_KEY = getValue({
  production: 'pub-c-e082cba6-53a7-4d2b-abb2-7491a2ca6087',
  staging: 'pub-c-9a555a57-66ef-4cfb-9e2c-b4c948a71835',
  development: 'pub-c-0e872333-a8c7-492a-b725-227ac0ab3ecf',
})

export const PUBNUB_SUBSCRIBE_KEY = getValue({
  production: 'sub-c-37f1572a-9c28-11e9-8994-3e832ec25d8b',
  staging: 'sub-c-1b05ca7e-9c28-11e9-8e9d-1623aee89087',
  development: 'sub-c-ac13efdc-99b7-11e9-95d5-d6b10db480fa',
})
