import css from './ToggleWrap.module.sass'

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from '../../Icon'

const ToggleWrap = forwardRef(
  ({ hasError, clearable, focused, loading, icon, children, onClick }, ref) => (
    <div
      className={classNames(css.container, {
        [css.clearable]: clearable,
        [css.focused]: focused,
        [css.error]: hasError,
        [css.loading]: loading,
        [css.withIcon]: icon,
      })}
      ref={ref}
      onClick={onClick}
    >
      {icon && <i className={css.icon}>{icon}</i>}

      {children}

      <i className={css.arrowDown}>
        <Icon name="arrowDownGray" />
      </i>
    </div>
  )
)

ToggleWrap.displayName = 'ToggleWrap'

ToggleWrap.propTypes = {
  hasError: PropTypes.bool,
  clearable: PropTypes.bool,
  focused: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default ToggleWrap
