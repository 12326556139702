import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Layout from '../components/Layout'
import InvestorConnections from '../components/InvestorConnections'
import { Consumer as DevicesConsumer } from '../components/DevicesContext'

import { ACTIVITY_PATH } from '../constants/routes'
import { toWithParams } from '../helpers/routes'

const ActivityScreen = ({ match }) => {
  const type = match.params.type

  if (!type) {
    return <Redirect to={toWithParams(ACTIVITY_PATH, { type: 'connected' })} />
  }

  const selectedConnectionId = match.params.connectionId

  return (
    <DevicesConsumer>
      {({ mobile }) => (
        <Layout
          noHeader={mobile && Boolean(selectedConnectionId)}
          variant="fullHeight"
          noFooter
          background="white"
        >
          <InvestorConnections
            selectedConnectionId={selectedConnectionId}
            type={type}
          />
        </Layout>
      )}
    </DevicesConsumer>
  )
}

ActivityScreen.propTypes = {
  match: PropTypes.object,
}

export default ActivityScreen
