import { gql } from 'graphql-tag'

import {
  COMPANY_MEMBER_FRAGMENT,
  USER_COMPANY_MAIN_FRAGMENT,
  USER_INVESTOR_PROFILE_FRAGMENT,
  LOCATION_FRAGMENT,
  CONNECTED_INVESTOR_FRAGMENT,
  CONNECTED_COMPANY_FRAGMENT,
  SAVED_COMPANY_FRAGMENT,
  CONCATENATED_VIDEO_FRAGMENT,
  COMPANY_FRAGMENT,
  VIDEO_TOPIC_FRAGMENT,
  ERRORS_FOR_FIELDS_FRAGMENT,
  COMPANY_TELEPROMPT_FRAGMENT,
} from './fragments'

export const GET_USER_COMPANY_ID = gql`
  query getUserCompany {
    company: getUserCompany {
      id
    }
  }
`

export const GET_USER_COMPANY_MEMBERS = gql`
  query getUserCompany {
    company: getUserCompany {
      id
      members {
        ...companyMember
      }
    }
  }
  ${COMPANY_MEMBER_FRAGMENT}
`

export const GET_USER_COMPANY_DRAFT = gql`
  query getUserCompany {
    company: getUserCompany {
      id
      draft
    }
  }
`

export const GET_USER_COMPANY_MAIN = gql`
  query getUserCompany {
    company: getUserCompany {
      ...userCompanyMain
    }
  }
  ${USER_COMPANY_MAIN_FRAGMENT}
`

export const GET_USER_COMPANY = gql`
  query getUserCompany {
    company: getUserCompany {
      ...userCompanyMain
      videoTopics {
        ...videoTopic
      }
      concatenatedVideo {
        ...concatenatedVideo
      }
      publishedAt
      createdBy {
        email
        firstName
        lastName
      }
      startupUrl
    }
  }
  ${USER_COMPANY_MAIN_FRAGMENT}
  ${VIDEO_TOPIC_FRAGMENT}
  ${CONCATENATED_VIDEO_FRAGMENT}
`

export const GET_COMPANY_INTERACTION = gql`
  query getCompany($id: String) {
    company: getCompany(id: $id) {
      id
      interaction
    }
  }
`

export const GET_COMPANY = gql`
  query getCompany($id: String) {
    company: getCompany(id: $id) {
      ...company
    }
  }
  ${COMPANY_FRAGMENT}
`

export const GET_ME = gql`
  query me {
    me {
      id
      firstName
      lastName
      email
      accountType
      emailConfirmed
      completedTourGuide
      skippedPracticeVideo
      isSuperuser
      completedOnboardingProfile
      completedVideoBuilderTour
      subscription {
        plan
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        cancelAt
        canceledAt
        isPaidSubscription
      }
    }
  }
`

export const GET_USER_INVESTOR_PROFILE = gql`
  query getUserInvestorProfile {
    getUserInvestorProfile {
      ...userInvestorProfile
    }
  }
  ${USER_INVESTOR_PROFILE_FRAGMENT}
`

export const GET_CONNECTED_COMPANIES = gql`
  query getConnectedCompanies(
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    companies: getConnectedCompanies(
      first: $first
      last: $last
      before: $before
      after: $after
    ) @connection(key: "connectedCompanies") {
      totalUnreadMessages
      edges {
        node {
          ...connectedCompany
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CONNECTED_COMPANY_FRAGMENT}
`

export const GET_CONNECTED_COMPANY = gql`
  query getConnectedCompany($id: Int) {
    getConnectedCompany(id: $id) {
      ...connectedCompany
    }
  }
  ${CONNECTED_COMPANY_FRAGMENT}
`

export const GET_SAVED_COMPANIES = gql`
  query getSavedCompanies(
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    companies: getSavedCompanies(
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          id
          company {
            id
            name
            description
          }
          dateSaved
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const GET_SAVED_COMPANY = gql`
  query getSavedCompany($id: Int) {
    getSavedCompany(id: $id) {
      ...savedCompany
    }
  }
  ${SAVED_COMPANY_FRAGMENT}
`

export const GET_CONNECTED_INVESTORS = gql`
  query getConnectedInvestors(
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    investors: getConnectedInvestors(
      first: $first
      last: $last
      before: $before
      after: $after
    ) @connection(key: "investors") {
      totalUnseen
      totalUnreadMessages
      edges {
        node {
          ...connectedInvestor
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${CONNECTED_INVESTOR_FRAGMENT}
`

export const GET_CONNECTED_INVESTOR = gql`
  query getConnectedInvestor($id: Int) {
    getConnectedInvestor(id: $id) {
      ...connectedInvestor
    }
  }
  ${CONNECTED_INVESTOR_FRAGMENT}
`

export const GET_FEED = gql`
  query getFeed($first: Int, $last: Int, $before: String, $after: String) {
    getFeed(first: $first, last: $last, before: $before, after: $after) {
      edges {
        node {
          id
          members {
            ...companyMember
          }
          name
          yearFounded
          locations {
            ...location
          }
          website
          description
          givenOverallRating
          customerCategory {
            id
            name
          }
          industries {
            id
            name
          }
          stage {
            id
            name
          }
          regions {
            id
            name
          }
          isPaidSubscriptionActive
          # revenue
          # activeUsers
          # previousCapital
          # fundingSources {
          #   id
          #   name
          # }
          # problem
          # solution
          # timing
          # marketPotential
          # businessModel
          # competitiveAdvantage
          slidesFile
          highlights
          videoTopics {
            ...videoTopic
          }
          concatenatedVideo {
            ...concatenatedVideo
          }
        }
      }
    }
  }
  ${COMPANY_MEMBER_FRAGMENT}
  ${LOCATION_FRAGMENT}
  ${VIDEO_TOPIC_FRAGMENT}
  ${CONCATENATED_VIDEO_FRAGMENT}
`

export const GET_COMPANIES = gql`
  query getCompanies(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $offset: Int
  ) {
    getCompanies(
      first: $first
      last: $last
      before: $before
      after: $after
      offset: $offset
    ) {
      edges {
        node {
          id
          name
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      total
    }
  }
`

// Template Topics
export const SELECT_TEMPLATE_TOPICS = gql`
  mutation SelectTemplateTopicMutation($ids: [Int!]) {
    selectTemplateTopic(input: { ids: $ids }) {
      ids
    }
  }
`
export const GET_TEMPLATE_TOPICS = gql`
  query {
    getTemplateTopics {
      id
      question
      subject
      inUse
    }
  }
`

export const CREATE_OR_UPDATE_COMPANY_VIDEO_TOPIC = gql`
  mutation createOrUpdateCompanyVideoTopic(
    $input: CreateOrUpdateCompanyVideoTopicMutationInput!
  ) {
    createOrUpdateCompanyVideoTopic(input: $input) {
      obj {
        ...videoTopic
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${VIDEO_TOPIC_FRAGMENT}
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

export const DELETE_COMPANY_VIDEO_TOPIC = gql`
  mutation deleteCompanyVideoTopic(
    $input: CompanyVideoTopicDeleteMutationInput!
  ) {
    deleteCompanyVideoTopic(input: $input) {
      id
    }
  }
`

export const MOVE_COMPANY_VIDEOTOPIC = gql`
  mutation moveCompanyVideoTopic($id: Int!, $position: Int!) {
    moveCompanyVideoTopic(input: { id: $id, position: $position }) {
      order
    }
  }
`

export const PUBLISH_COMPANY = gql`
  mutation publishCompany {
    publishCompany(input: {}) {
      errors {
        ...errorsForFields
      }
    }
  }
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

export const UNPUBLISH_COMPANY = gql`
  mutation unpublishCompany {
    unpublishCompany(input: {}) {
      errors {
        ...errorsForFields
      }
    }
  }
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

export const SAVE_COMPANY_TELEPROMPT = gql`
  mutation UpdateCompanyVideoTopicTeleprompt(
    $input: CreateOrUpdateCompanyVideoTopicMutationInput!
  ) {
    createOrUpdateCompanyVideoTopic(input: $input) {
      obj {
        ...videoTopic
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${COMPANY_TELEPROMPT_FRAGMENT}
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

export const UPDATE_COMPANY = gql`
  mutation updateCompany($input: CompanyUpdateMutationInput!) {
    updateCompany(input: $input) {
      obj {
        ...userCompanyMain
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${ERRORS_FOR_FIELDS_FRAGMENT}
  ${USER_COMPANY_MAIN_FRAGMENT}
`
