import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { graphql } from 'react-apollo'

import Dropdown from './Dropdown'

const GET_INVESTOR_TYPES = gql`
  query getInvestorTypes {
    types: getInvestorTypes {
      id
      name
    }
  }
`

const InvestorTypeDropdown = ({ data, ...dropdownProps }) => {
  const options = useMemo(() => {
    return data.types && data.types.map((x) => ({ value: x, label: x.name }))
  }, [data.types])

  return (
    <Dropdown
      options={options}
      valueIdKey="id"
      renderLabel={(value) => value.name}
      {...dropdownProps}
    />
  )
}

InvestorTypeDropdown.propTypes = {
  data: PropTypes.object,
}

export default graphql(GET_INVESTOR_TYPES)(InvestorTypeDropdown)
