import css from './CircleChart.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

const CircleChart = ({ percentages }) => {
  const [p1, p2, p3] = percentages

  return (
    <svg
      className={css.chart}
      viewBox="0 0 200 200"
      width="110"
      height="110"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        stroke="#00000007"
        strokeWidth="10"
        fill="none"
        cx="100"
        cy="100"
        r="95"
      />
      <circle
        className={css.circle}
        strokeDasharray={`${(p1 * 595) / 100},1000`}
        stroke="#31CC9B"
        strokeWidth="10"
        strokeLinecap="round"
        fill="none"
        cx="100"
        cy="100"
        r="95"
      />

      <circle
        stroke="#00000007"
        strokeWidth="10"
        fill="none"
        cx="100"
        cy="100"
        r="75"
      />
      <circle
        className={css.circle}
        strokeDasharray={`${(p2 * 470) / 100},1000`}
        stroke="#0174db"
        strokeWidth="10"
        strokeLinecap="round"
        fill="none"
        cx="100"
        cy="100"
        r="75"
      />

      <circle
        stroke="#00000007"
        strokeWidth="10"
        fill="none"
        cx="100"
        cy="100"
        r="55"
      />
      <circle
        className={css.circle}
        strokeDasharray={`${(p3 * 343) / 100},1000`}
        stroke="#DB8700"
        strokeWidth="10"
        strokeLinecap="round"
        fill="none"
        cx="100"
        cy="100"
        r="55"
      />
    </svg>
  )
}

CircleChart.propTypes = {
  percentages: PropTypes.array,
}

export default CircleChart
