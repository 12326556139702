import css from './SignUpScreen.module.sass'

import React, { useContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { useMutation } from 'react-apollo'

import Alert from '../components/Alert'
import { FormErrors } from '../components/Form'
import Image from '../components/Image'
import Layout from '../components/Layout'
import Link from '../components/Link'
import List from '../components/List'
import Text from '../components/Text'
import SignUpForm from '../components/SignUpForm'

import CurrentUserContext from '../components/CurrentUserContext'

import { PITCHTAPE_PATH, SIGNIN_PATH } from '../constants/routes'
import { ERRORS_FOR_FIELDS_FRAGMENT } from '../constants/fragments'
import { GTM_USER_REGISTERED_EVENT } from '../constants/gtm'
import { gtmTrack, gtmIdentify } from '../helpers/tracking'
import { formatGraphQLError } from '../helpers/errors'
import InvestorSignupModal from '../components/Signup/InvestorSignupModal'
import Button from '../components/Button'
import RedirectToInvestorsMarketSite from '../components/Signup/RedirectToInvestorsMarketSite'
import Icon from '../components/Icon'

const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $accountType: String!
    $password: String!
  ) {
    createUser(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        accountType: $accountType
        password: $password
      }
    ) {
      obj {
        id
        firstName
        lastName
        email
        accountType
      }
      token
      errors {
        ...errorsForFields
      }
    }
  }
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

const SignUpScreen = ({ history, match }) => {
  const { onTokenChange } = useContext(CurrentUserContext)

  const [createUser, { data, loading, error }] = useMutation(CREATE_USER)

  const isInvestor = match.params.accountType === 'investor'

  const [openInvestorSignupModal, setInvestorSignupModal] = useState(false)
  const [goToInvestorsPage, setGoToInvestorsPage] = useState(false)

  const handleUserCreate = useCallback(
    (variables) => {
      createUser({ variables }).then(({ data }) => {
        if (data && data.createUser.token) {
          gtmIdentify(data.createUser.obj.id)

          gtmTrack(GTM_USER_REGISTERED_EVENT, {
            user: { accountType: data.createUser.obj.accountType },
          })
        }
        if (isInvestor) {
          setInvestorSignupModal(true)
        } else {
          onTokenChange(data.createUser.token)
          history.push({
            pathname: PITCHTAPE_PATH,
            state: { signedUp: true },
          })
        }
      })
    },
    [createUser, history, onTokenChange, isInvestor]
  )

  const handleGotItClick = () => {
    setGoToInvestorsPage(true)
  }

  const formErrors = data && data.createUser.errors
  const listIconSize = '16px'

  return (
    <Layout noFooter>
      <div className={css.container}>
        <div className={css.main}>
          <div className={css.mainInner}>
            <div className={css.form}>
              <div className={css.intro}>
                <Text tag="h2" variant="h3" offset="half-bottom">
                  {isInvestor
                    ? 'Investors Sign Up'
                    : 'Founders - Get started for free'}
                </Text>

                <Text tag="p">
                  Already have an account?{' '}
                  <Link to={SIGNIN_PATH}>Log in here</Link>
                </Text>
              </div>

              {formErrors && <FormErrors errors={formErrors} />}
              {error && (
                <Alert variant="error">{formatGraphQLError(error)}</Alert>
              )}

              <SignUpForm
                accountType={isInvestor ? 'investor' : 'founder'}
                loading={loading}
                onUserCreate={handleUserCreate}
              />
              {openInvestorSignupModal && (
                <InvestorSignupModal
                  buttons={
                    <Button
                      padding={'double'}
                      variant="primary"
                      onClick={handleGotItClick}
                    >
                      Got it
                    </Button>
                  }
                  visible={openInvestorSignupModal}
                  loading={loading}
                />
              )}
              {goToInvestorsPage && <RedirectToInvestorsMarketSite />}
            </div>
          </div>
        </div>

        <div className={css.hero}>
          {isInvestor ? (
            <div className={css.heroTextInvestor}>
              <Text tag="h2" variant="h2">
                Identify Great Founders
              </Text>

              <Text tag="p" variant="large" offset="double-bottom">
                Evaluate the people behind the project as if you were viewing a
                live pitch.
              </Text>

              <Text tag="h2" variant="h2">
                Filter Out the Noise
              </Text>
              <Text tag="p" variant="large" offset="double-bottom">
                Access a personalized online investor portal curated based on
                selected investment criteria.
              </Text>

              <Text tag="h2" variant="h2">
                Make the First Move
              </Text>
              <Text tag="p" variant="large">
                View startups anonymously and elect to pass, save, or connect
                simply by clicking a button.
              </Text>
            </div>
          ) : (
            <div className={css.heroTextFounder}>
              <Text tag="h2" variant="h2" offset="single-bottom">
                Easily create, share, and track a pitch video with Pitchtape
              </Text>

              <Text tag="h3" variant="h3" offset="single-bottom">
                Key features:
              </Text>

              <List variant="withIcon" gap="20">
                <List.Item
                  icon={
                    <Icon
                      name="pitchtapePlay"
                      style={{ height: listIconSize, width: listIconSize }}
                    />
                  }
                >
                  <Text variant="large">
                    Video topic templates for your startup pitch
                  </Text>
                </List.Item>

                <List.Item
                  icon={
                    <Icon
                      name="pitchtapePlay"
                      style={{ height: listIconSize, width: listIconSize }}
                    />
                  }
                >
                  <Text variant="large">
                    Record and update your pitch one topic at a time
                  </Text>
                </List.Item>

                <List.Item
                  icon={
                    <Icon
                      name="pitchtapePlay"
                      style={{ height: listIconSize, width: listIconSize }}
                    />
                  }
                >
                  <Text variant="large">No video editing required</Text>
                </List.Item>

                <List.Item
                  icon={
                    <Icon
                      name="pitchtapePlay"
                      style={{ height: listIconSize, width: listIconSize }}
                    />
                  }
                >
                  <Text variant="large">
                    Unique URL to share your pitch video online
                  </Text>
                </List.Item>

                <List.Item
                  icon={
                    <Icon
                      name="pitchtapePlay"
                      style={{ height: listIconSize, width: listIconSize }}
                    />
                  }
                >
                  <Text variant="large">
                    List your pitch video in our secure Investor Portal
                  </Text>
                </List.Item>
                <List.Item
                  icon={
                    <Icon
                      name="pitchtapePlay"
                      style={{ height: listIconSize, width: listIconSize }}
                    />
                  }
                >
                  <Text variant="large">
                    Customizable video topics and teleprompter (upgrade
                    required)
                  </Text>
                </List.Item>
              </List>
            </div>
          )}

          <div className={css.accelerators}>
            <Text tag="p" color="white" uppercase>
              Founders on pitchtape have attended top accelerator programs
            </Text>

            <div className={css.acceleratorsLogos}>
              <Image
                fileName="signup/accelerators/startupbootcamp.png"
                width="103"
              />
              <Image fileName="signup/accelerators/techstars.png" width="55" />
              <Image
                fileName="signup/accelerators/quakecapital.png"
                width="67"
              />
              <Image
                fileName="signup/accelerators/ycombinator.png"
                width="106"
              />
              <Image
                fileName="signup/accelerators/startupchile.png"
                width="112"
              />
              <Image fileName="signup/accelerators/parallel18.png" width="72" />
              <Image
                fileName="signup/accelerators/founderinstitute.png"
                width="59"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

SignUpScreen.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
}

export default SignUpScreen
