import css from './CongratsModal.module.sass'

import React from 'react'

import Button from './Button'
import Modal from './Modal'
import Text from './Text'

import { CREATE_PATH } from '../constants/routes'

const CongratsModal = () => {
  return (
    <Modal
      variant="welcome"
      title={
        <Text tag="h1" variant="h2" color="white">
          Congrats! Your video has been recorded!
        </Text>
      }
      buttons={
        <>
          <Button
            variant="primary"
            to={{ pathname: CREATE_PATH, state: { noScrollToTop: true } }}
          >
            Got it
          </Button>
        </>
      }
    >
      <div className={css.content}>
        <Text tag="p" variant="large" offset="single-bottom" className={css.p}>
          Your video segments are now being stitched into one seamless pitch.
        </Text>

        <Text tag="p" variant="large" className={css.p}>
          Look for an email notification letting you know that your video pitch
          is ready for review, then refresh your screen.
        </Text>
      </div>
    </Modal>
  )
}

export default CongratsModal
