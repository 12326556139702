import css from './AnalyticsCTAScreen.module.sass'

import React from 'react'

import Button from '../components/Button'
import FounderAnalytics from '../components/FounderAnalytics'
import Layout from '../components/Layout'
import Link from '../components/Link'
import List from '../components/List'
import Text from '../components/Text'

import { BILLING_PATH } from '../constants/routes'

const ICON_PROPS = { name: 'tickBlue' }

const DATA = {
  interactionsLastWeek: {
    passed: 1,
    saved: 5,
    connected: 10,
  },
  interactionsAllTime: {
    passed: 15,
    saved: 64,
    connected: 183,
  },
  totalViews: 323,
  totalViewsLastWeek: 35,
  avgViewTime: 48,
  avgViewTimeLastWeek: 54,
  avgRating: {
    overall: 5.0,
    pitchVideo: 4.5,
    pitchDeck: 4,
    highlightsSection: 4.5,
    teamSection: 5,
    overallRatingCount: 423,
    pitchVideoRatingCount: 400,
    pitchDeckRatingCount: 392,
    highlightsSectionRatingCount: 404,
    teamSectionRatingCount: 402,
  },
  reviews: [
    {
      id: 0,
      review: 'A breathtaking pitch for an incredible startup!',
    },
  ],
  // videos: [
  //   {
  //      id: 0,
  //      duration: "0:60:00.000000",
  //      subject: "what_we_are_doing",
  //      totalViews: 287,
  //      avgViewTime: 55
  //   },
  //   {
  //      id: 1,
  //      duration: "0:60:00.000000",
  //      subject: "who_we_are",
  //      totalViews: 256,
  //      avgViewTime: 51
  //   },
  //   {
  //      id: 2,
  //      duration: "0:60:00.000000",
  //      subject: "why_we_are_doing_it",
  //      totalViews: 243,
  //      avgViewTime: 49
  //   }
  // ]
}

const AnalyticsCTAScreen = () => {
  return (
    <Layout variant="dashboardWithBlocks">
      <div className={css.container}>
        <div className={css.analytics}>
          <FounderAnalytics
            interactionsLastWeek={DATA.interactionsLastWeek}
            interactionsAllTime={DATA.interactionsAllTime}
            totalViews={DATA.totalViews}
            totalViewsLastWeek={DATA.totalViewsLastWeek}
            avgViewTime={DATA.avgViewTime}
            avgViewTimeLastWeek={DATA.avgViewTimeLastWeek}
            avgRating={DATA.avgRating}
            reviews={DATA.reviews}
            // videos={DATA.videos}
          />
        </div>

        <article className={css.cta}>
          <div className={css.ctaInner}>
            <Text tag="h1" variant="h2" offset="half-bottom">
              Pitch Analytics
            </Text>

            <Text tag="p" variant="large">
              Gain valuable insights about how investors are interacting with
              your pitch with your own personalized analytics dashboard by
              upgrading to the Grow plan.
            </Text>

            <div className={css.list}>
              <Text tag="p" weight="500" offset="single-bottom">
                Metrics include:
              </Text>

              <List variant="bulletedTicks" iconProps={ICON_PROPS}>
                <List.Item>Investor Decision</List.Item>
                <List.Item>Total Pitch Views</List.Item>
                <List.Item>Average Time Per Pitch View</List.Item>
                {/* <List.Item>Most Viewed Video</List.Item>
                <List.Item>Average Time Per Video</List.Item> */}
              </List>
            </div>

            <Text tag="p">
              Click on the button below to upgrade your plan now!
            </Text>

            <div className={css.button}>
              <Button to={BILLING_PATH} variant="primary">
                Upgrade
              </Button>
            </div>

            <Text tag="p" variant="small" offset="single-bottom">
              Learn more about the Grow plan and compare plans{' '}
              <Link
                external
                href="https://www.pitchtape.com/founders/#founders-pricing"
              >
                here
              </Link>
              .
            </Text>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default AnalyticsCTAScreen
