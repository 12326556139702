import css from './AccountSettingsForms.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { gql } from 'graphql-tag'
import pick from 'lodash.pick'

import { FormSection } from './Form'
import Link from './Link'
import { Consumer as CurrentUserConsumer } from './CurrentUserContext'
import Loader from './Loader'
import Alert from './Alert'
import ChangeUserDetailsForm from './ChangeUserDetailsForm'
import ChangePasswordForm from './ChangePasswordForm'

import { formatGraphQLError } from '../helpers/errors'
import { SIGNOUT_PATH } from '../constants/routes'
import { GET_ME } from '../constants/queries'

const UPDATE_USER = gql`
  mutation updateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    updateUser(
      input: { email: $email, firstName: $firstName, lastName: $lastName }
    ) {
      obj {
        id
        email
        firstName
        lastName
      }
      errors {
        field
        messages
      }
    }
  }
`

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $password: String!
    $newPassword: String!
    $reNewPassword: String!
  ) {
    password: changePassword(
      password: $password
      newPassword: $newPassword
      reNewPassword: $reNewPassword
    ) {
      code
      message
    }
  }
`

const LOGOUT_ALL = gql`
  mutation logoutAll {
    logoutAll {
      code
      message
    }
  }
`

const AccountSettingsForms = ({ history }) => {
  return (
    <CurrentUserConsumer>
      {({ currentUser }) => {
        if (!currentUser.id) {
          return <Loader />
        }

        return (
          <>
            <Mutation
              mutation={UPDATE_USER}
              update={(
                cache,
                {
                  data: {
                    updateUser: { obj },
                  },
                }
              ) => {
                if (obj) {
                  const { me } = cache.readQuery({ query: GET_ME })

                  cache.writeQuery({
                    query: GET_ME,
                    data: {
                      me: {
                        ...me,
                        ...obj,
                      },
                    },
                  })
                }
              }}
            >
              {(updateUser, { data, error, loading }) => (
                <FormSection heading="Account Details">
                  <div className={css.form}>
                    <ChangeUserDetailsForm
                      defaultValues={pick(currentUser, [
                        'firstName',
                        'lastName',
                        'email',
                      ])}
                      formErrors={data && data.updateUser.errors}
                      loading={loading}
                      error={error}
                      message={
                        data && !data.updateUser.errors
                          ? 'Account details have been updated.'
                          : null
                      }
                      onSubmit={(variables) => {
                        updateUser({ variables }).then(({ data }) => {
                          if (
                            !data.updateUser.errors &&
                            currentUser.email !== variables.email
                          ) {
                            history.push(SIGNOUT_PATH)
                          }
                        })
                      }}
                    />
                  </div>
                </FormSection>
              )}
            </Mutation>

            <Mutation mutation={CHANGE_PASSWORD}>
              {(changePassword, { data, loading, error }) => (
                <FormSection heading="Change Password" separate>
                  <div className={css.form}>
                    <ChangePasswordForm
                      loading={loading}
                      error={error}
                      message={data && data.password && data.password.message}
                      onSubmit={({ password, newPassword }) => {
                        changePassword({
                          variables: {
                            password,
                            newPassword,
                            reNewPassword: newPassword,
                          },
                        })
                      }}
                    />
                  </div>
                </FormSection>
              )}
            </Mutation>

            <FormSection separate>
              <div className={css.form}>
                <Mutation
                  mutation={LOGOUT_ALL}
                  onCompleted={({ error }) => {
                    if (!error) {
                      history.push(SIGNOUT_PATH)
                    }
                  }}
                >
                  {(
                    logoutAll,
                    { loading: loggingOut, error: loggingOutError }
                  ) => (
                    <>
                      {loggingOutError && (
                        <Alert variant="error" offset="double">
                          {formatGraphQLError(loggingOutError)}
                        </Alert>
                      )}

                      <Link disabled={loggingOut} onClick={() => logoutAll()}>
                        Sign out on all devices
                      </Link>
                    </>
                  )}
                </Mutation>
              </div>
            </FormSection>
          </>
        )
      }}
    </CurrentUserConsumer>
  )
}

AccountSettingsForms.propTypes = {
  history: PropTypes.object,
}

export default withRouter(AccountSettingsForms)
