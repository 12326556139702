import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { graphql } from 'react-apollo'

import Dropdown from './Dropdown'

const GET_REGIONS = gql`
  query getStages {
    regions: getRegions {
      id
      name
    }
  }
`

const RegionDropdown = ({ data, ...dropdownProps }) => {
  const options = useMemo(() => {
    return (
      data.regions && data.regions.map((x) => ({ value: x, label: x.name }))
    )
  }, [data.regions])

  return (
    <Dropdown
      options={options}
      valueIdKey="id"
      renderLabel={(value) => value.name}
      {...dropdownProps}
    />
  )
}

RegionDropdown.propTypes = {
  data: PropTypes.object,
}

export default graphql(GET_REGIONS)(RegionDropdown)
