import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Form, FormFooter } from '../Form'
import Button from '../Button'
import Alert from '../Alert'
import InvestorInfo from './InvestorInfo'
import InvestorCriteria from './InvestorCriteria'

import { formatGraphQLError } from '../../helpers/errors'
import { GTM_FORM_INVESTOR_PROFILE } from '../../constants/gtm'

const InvestorProfileForm = React.forwardRef(
  (
    {
      success,
      error,
      formData,
      saving,
      submitButtonText,
      onSubmit,
      onUpdate,
      onInvalid,
    },
    ref
  ) => {
    const [valid, setValid] = useState(false)

    return (
      <Form
        gtm={GTM_FORM_INVESTOR_PROFILE}
        innerRef={ref}
        onValid={() => setValid(true)}
        onInvalid={() => setValid(false)}
        onSubmit={(event) => {
          event.preventDefault()

          if (valid && !saving) {
            onSubmit()
          }
        }}
      >
        {error && (
          <Alert variant="error" offset="double">
            {formatGraphQLError(error)}
          </Alert>
        )}
        {success && (
          <Alert variant="success" offset="double">
            Investor profile has been updated.
          </Alert>
        )}

        <InvestorInfo
          title={formData.title}
          profileUrl={formData.profileUrl}
          name={formData.name}
          organizationSize={formData.organizationSize}
          locations={formData.locations}
          website={formData.website}
          investorTypes={formData.investorTypes}
          accreditedInvestor={formData.accreditedInvestor}
          numberOfInvestments={formData.numberOfInvestments}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />

        <InvestorCriteria
          customerCategory={formData.customerCategory}
          industries={formData.industries}
          stages={formData.stages}
          regions={formData.regions}
          // revenue={formData.revenue}
          // activeUsers={formData.activeUsers}
          // previousCapital={formData.previousCapital}
          // fundingSources={formData.fundingSources}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />

        <FormFooter>
          <Button type="submit" variant="primary" disabled={saving}>
            {submitButtonText || 'Save'}
          </Button>
        </FormFooter>
      </Form>
    )
  }
)

InvestorProfileForm.displayName = 'InvestorProfileForm'

InvestorProfileForm.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.object,
  formData: PropTypes.object,
  saving: PropTypes.bool,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func,
  onInvalid: PropTypes.func,
}

export default InvestorProfileForm
