import css from './AnimatedEllipsis.module.sass'

import React from 'react'

const AnimatedEllipsis = () => {
  return (
    <span className={css.ellipsis}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  )
}

export default AnimatedEllipsis
