import * as Sentry from '@sentry/browser'

import { isStageOrProdEnv } from './env'

export let sentryIdentify = () => {}
export let sentryUnidentify = () => {}

if (isStageOrProdEnv()) {
  let identifiedUserId

  sentryIdentify = ({
    id,
    firstName,
    lastName,
    email,
    accountType,
    subscription,
  }) => {
    if (identifiedUserId !== id) {
      identifiedUserId = id

      Sentry.configureScope((scope) => {
        scope.setUser({
          id,
          name: `${firstName} ${lastName}`,
          email,
          type: accountType,
          ...(subscription && { plan: subscription.plan }),
        })
      })
    }
  }

  sentryUnidentify = () => {
    Sentry.configureScope((scope) => {
      scope.setUser(null)
    })
  }
}
