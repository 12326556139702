import css from './index.module.sass'

import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'

import AsyncScript from '../AsyncScript'
import Loader from '../Loader'
import Button from '../Button'
import Ionicon from '../Ionicon'

import Controls from './Controls'
import Slide from './Slide'

import { isMobile } from '../../helpers/devices'
import { durationToSeconds } from '../../helpers/videos'
import {
  VIDEO_TITLES,
  PLAYING_STATE,
  PAUSED_STATE,
  STOPPED_STATE,
} from '../../constants/videos'
import { HLS_JS_URL } from '../../constants/urls'
import { formatMilliSecondsAsTime } from '../../helpers/dates'
import { detect } from 'detect-browser'
import omit from 'lodash/omit'

const MINI_PLAYER_WIDTH = 300
const SEGMENTS_VISIBLE_WIDTH = 900

let playingVideo

const VideoPlayer = ({
  loading,
  variant = 'base',
  header,
  binary,
  hlsReady,
  hlsUrl,
  deckPage,
  subject,
  topics,
  autoPlay,
  nativeControls,
  onPause,
  onPlay,
  onEnded,
  onLoadedVideoMetadata,
}) => {
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0)
  const [time, setTime] = useState(0)
  const [nonPlayable, setNonPlayable] = useState()
  const [duration, setDuration] = useState()
  const [videoState, setVideoState] = useState(STOPPED_STATE)
  const [muted, setMuted] = useState(false)
  const [stretchedVideo, setStretchedVideo] = useState()
  const [playerType, setPlayerType] = useState('micro')
  const [varyingSpeed, setVaryingSpeed] = useState(false)

  const videoRef = useRef()
  const mainRef = useRef()
  const segmentTimeoutRef = useRef()

  const isPiPEnabled = !!document.pictureInPictureEnabled
  const speedVideo = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]

  useEffect(() => {
    return () => {
      setDuration(void 0)
      setVideoState(STOPPED_STATE)
    }
  }, [binary])

  useLayoutEffect(() => {
    const handleResize = () => {
      const main = mainRef.current
      const ratio = main.clientWidth / main.clientHeight

      if (ratio > 1.4 && ratio < 2.1) {
        !stretchedVideo && setStretchedVideo(true)
      } else if (stretchedVideo) {
        setStretchedVideo(false)
      }

      if (main.clientWidth < MINI_PLAYER_WIDTH) {
        if (playerType !== 'micro') {
          setPlayerType('micro')
        }
      } else if (main.clientWidth < SEGMENTS_VISIBLE_WIDTH) {
        if (playerType !== 'mini') {
          setPlayerType('mini')
        }
      } else if (playerType !== 'full') {
        setPlayerType('full')
      }
    }

    let timeout

    if (stretchedVideo === void 0) {
      timeout = setTimeout(() => {
        handleResize()
      }, 0)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      clearTimeout(timeout)

      window.removeEventListener('resize', handleResize)
    }
  }, [playerType, stretchedVideo])

  useEffect(() => {
    if (autoPlay && duration) {
      videoRef.current.play()
    }
  }, [duration, autoPlay])

  useEffect(() => {
    const video = videoRef.current

    video.onloadedmetadata = () => {
      if (video.duration === Infinity) {
        const handleTimeupdate = () => {
          video.removeEventListener('timeupdate', handleTimeupdate)
          video.currentTime = 0
          setTimeout(function () {
            setDuration(video.duration)
          }, 0)
        }
        video.currentTime = 1000000
        video.addEventListener('timeupdate', handleTimeupdate)
      } else {
        video.currentTime = 0
        setDuration(video.duration)
      }
    }

    return () => {
      video.onloadedmetadata = null
    }
  }, [binary])

  useEffect(() => {
    const video = videoRef.current

    if (duration) {
      video.onplay = () => {
        if (playingVideo && playingVideo !== video) {
          playingVideo.pause()
        }

        playingVideo = video

        setVideoState(PLAYING_STATE)

        onPlay && onPlay()
      }

      video.onpause = () => {
        if (playingVideo === video) {
          playingVideo = void 0
        }

        setVideoState(PAUSED_STATE)

        onPause && onPause()
      }

      video.onstop = () => [setVideoState(STOPPED_STATE)]

      video.onended = () => {
        video.currentTime = 0

        onEnded && onEnded()
      }

      video.ontimeupdate = () => {
        setTime(video.currentTime)
      }

      video.onvolumechange = () => {
        setMuted(video.muted)
      }
      onLoadedVideoMetadata &&
        onLoadedVideoMetadata({
          duration: formatMilliSecondsAsTime(duration),
          metadata: {
            videoHeight: video.videoHeight,
            videoWidth: video.videoWidth,
            duration: video.duration,
            browser: {
              ...omit(detect(), ['type']),
              clientWidth: window.screen.width,
              clientHeight: window.screen.height,
            },
          },
        })
    }

    return () => {
      video.onplay = null
      video.onpause = null
      video.onstop = null
      video.onended = null
      video.ontimeupdate = null
    }
  }, [onPause, onPlay, onEnded, duration, onLoadedVideoMetadata])

  useEffect(() => {
    const video = videoRef.current

    return () => {
      if (playingVideo === video) {
        playingVideo = void 0
      }
    }
  }, [])

  useEffect(() => {
    if (loading || !hlsReady) {
      return void 0
    }

    const video = videoRef.current
    let hls

    if (window.Hls.isSupported()) {
      hls = new window.Hls()
      hls.loadSource(hlsUrl)
      hls.attachMedia(video)
      hls.startLevel = 2
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = hlsUrl + '#t=0.1'

      video.onerror = () => {
        if (video.error.code === 3) {
          video.src = hlsUrl
          video.load()
        }
      }
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [loading, hlsReady, hlsUrl])

  useEffect(() => {
    setNonPlayable(
      !hlsReady &&
        !videoRef.current.canPlayType(`video/webm; codecs="vp8, vorbis"`) &&
        (/\.webm$/i.test(binary) || typeof binary === 'object')
    )
  }, [binary, hlsReady])

  const handlePlay = useCallback(() => {
    videoRef.current.play()
    setVideoState(PLAYING_STATE)
  }, [])

  const handlePause = useCallback(() => {
    videoRef.current.pause()
  }, [])

  const handleFullscreen = useCallback(() => {
    const video = videoRef.current
    const requestFullscreen =
      video.requestFullscreen ||
      video.webkitRequestFullscreen ||
      video.mozRequestFullScreen ||
      video.msRequestFullscreen
    const exitFullscreen =
      document.exitFullscreen ||
      document.webkitExitFullscreen ||
      document.mozExitFullScreen ||
      document.msExitFullscreen
    const fullscreenElement =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement

    if (fullscreenElement) {
      exitFullscreen.call(document)
    } else {
      if (requestFullscreen) {
        requestFullscreen.call(mainRef.current)
      } else if (video.webkitSetPresentationMode) {
        video.webkitSetPresentationMode('fullscreen')
      }
    }
  }, [])

  const handleSeek = useCallback((time) => {
    videoRef.current.currentTime = time
  }, [])

  const handleMute = useCallback(() => {
    videoRef.current.muted = !videoRef.current.muted
  }, [])

  const src = useMemo(() => {
    if (typeof binary === 'object') {
      return URL.createObjectURL(binary)
    }

    if (!hlsReady) {
      return binary && binary + (isMobile() ? '#t=0.1' : '')
    }
  }, [binary, hlsReady])

  const handlePictureInPicture = () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture()
    } else {
      if (document.pictureInPictureEnabled) {
        videoRef.current.requestPictureInPicture()
      }
    }
  }

  const handleSpeed = () => {
    setVaryingSpeed(!varyingSpeed)
  }

  const handleChangeSpeed = (speed) => {
    videoRef.current.playbackRate = speed
    setVaryingSpeed(!varyingSpeed)
  }

  const segments = useMemo(() => {
    if (!topics) {
      return null
    }

    const _segments = topics
      .filter((topic) => topic.video)
      .map((topic) => ({
        id: topic.id,
        title: topic.subject,
        duration: durationToSeconds(topic.video.duration),
        deckPage: topic.video.pitchdeckPage,
      }))

    if (_segments.some((s) => !s.duration)) {
      return null
    }

    return _segments
  }, [topics])

  useEffect(() => {
    if (segments) {
      let durationSoFarInMs = 0

      segments.some((segment, index) => {
        const durationInMs = segment.duration * 1000
        const timeInMs = time * 1000

        durationSoFarInMs += durationInMs

        const timeUntilNextSegment = durationSoFarInMs - timeInMs

        if (timeUntilNextSegment >= 0 && timeUntilNextSegment < 1000) {
          if (segments.length > index + 1) {
            setCurrentSegmentIndex(index)

            segmentTimeoutRef.current = setTimeout(() => {
              setCurrentSegmentIndex(index + 1)
            }, timeUntilNextSegment)
          }

          return true
        } else if (
          timeUntilNextSegment > 0 &&
          timeUntilNextSegment <= durationInMs
        ) {
          setCurrentSegmentIndex(index)

          return true
        }

        return false
      })

      return () => {
        clearTimeout(segmentTimeoutRef.current)
      }
    }
  }, [segments, time])

  useEffect(() => {
    clearTimeout(segmentTimeoutRef.current)
  }, [videoState])

  const currentDeckPage =
    deckPage || (segments && segments[currentSegmentIndex]?.deckPage)

  return (
    <div className={css[variant]}>
      <div className={css.main} ref={mainRef}>
        <div className={css.videoContainer}>
          {header && (
            <div
              className={
                videoState === PLAYING_STATE ? css.headerHideable : css.header
              }
              onClick={handlePause}
            >
              {header}
            </div>
          )}

          {currentDeckPage && (
            <div className={css.slide}>
              <Slide deckPage={currentDeckPage} />
            </div>
          )}

          <video
            className={stretchedVideo ? css.videoStretched : css.video}
            src={src}
            controls={nativeControls}
            controlsList="nodownload"
            autoPlay={autoPlay}
            onClick={handlePause}
            ref={videoRef}
          />

          {nonPlayable && (
            <div className={css.nonPlayable}>
              This video is being processed. Please check back soon!
            </div>
          )}

          {!nativeControls &&
            !nonPlayable &&
            videoState !== PLAYING_STATE &&
            playerType !== 'micro' && (
              <div className={css.btnPlay}>
                <Button variant="play" onClick={handlePlay}>
                  <Ionicon name="play" color="white" size="48" />
                </Button>
              </div>
            )}
        </div>

        {!nativeControls && (
          <>
            <div
              className={
                videoState === PLAYING_STATE ? css.controlsHideable : void 0
              }
            >
              <Controls
                time={time}
                type={playerType}
                duration={duration}
                videoState={videoState}
                muted={muted}
                segments={playerType !== 'full' ? null : segments}
                currentSegmentIndex={currentSegmentIndex}
                onMute={handleMute}
                onPlay={handlePlay}
                onPause={handlePause}
                onFullscreen={handleFullscreen}
                onSeek={handleSeek}
                onPictureInPicture={handlePictureInPicture}
                isPiPEnabled={isPiPEnabled}
                onChangeSpeed={handleSpeed}
              />
            </div>

            {varyingSpeed && (
              <div className={css.speed}>
                {speedVideo.map((item, index) => {
                  return (
                    <Button key={index} onClick={() => handleChangeSpeed(item)}>
                      <span>{item}x</span>
                    </Button>
                  )
                })}
              </div>
            )}
          </>
        )}

        {loading && <Loader variant="blank" />}
      </div>

      {subject && <div className={css.title}>{VIDEO_TITLES[subject]}</div>}
    </div>
  )
}

VideoPlayer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,

  variant: PropTypes.string,
  header: PropTypes.node,
  binary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hlsReady: PropTypes.bool,
  hlsUrl: PropTypes.string,
  deckPage: PropTypes.number,
  subject: PropTypes.string,
  autoPlay: PropTypes.bool,
  topics: PropTypes.array,
  nativeControls: PropTypes.bool,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onEnded: PropTypes.func,
  onLoadedVideoMetadata: PropTypes.func,
}

export default function VideoPlayerAsyncScript(props) {
  return (
    <AsyncScript src={HLS_JS_URL}>
      {({ loading, error }) => (
        <VideoPlayer {...props} loading={loading} error={error} />
      )}
    </AsyncScript>
  )
}
