import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import LandingScreen from './LandingScreen'
import SignInScreen from './SignInScreen'
import SignUpScreen from './SignUpScreen'
import SignUpInvestorProfileScreen from './SignUpInvestorProfileScreen'
import ResetPasswordScreen from './ResetPasswordScreen'
import ForgotPasswordScreen from './ForgotPasswordScreen'
import PitchtapeScreen from './PitchtapeScreen'
import CreateScreen from './CreateScreen'
import CreateVideosScreen from './CreateVideosScreen'
import PracticeScreen from './PracticeScreen'
import SettingsScreen from './SettingsScreen'
import BillingScreen from './BillingScreen'
import ProfileScreen from './ProfileScreen'
import SignOutScreen from './SignOutScreen'
import ConfirmScreen from './ConfirmScreen'
import FeedScreen from './FeedScreen'
import ActivityScreen from './ActivityScreen'
import ConnectionsScreen from './ConnectionsScreen'
import StartupScreen from './StartupScreen'
import SubscriptionUpgradedScreen from './SubscriptionUpgradedScreen'
import SubscriptionCanceledScreen from './SubscriptionCanceledScreen'
import FounderAnalyticsScreen from './FounderAnalyticsScreen'
import CompanyListScreen from './CompanyListScreen'
import AnalyticsCTAScreen from './AnalyticsCTAScreen'
import NotFoundScreen from './NotFoundScreen'

import ProtectedRoute from '../components/ProtectedRoute'

import CurrentUserContext from '../components/CurrentUserContext'

import * as routes from '../constants/routes'
import { isActivePaidPlan } from '../helpers/subscription'

const RootScreen = ({ location, history }) => {
  const {
    currentUser: { subscription, isSuperuser },
  } = useContext(CurrentUserContext)

  useEffect(() => {
    const handleDocumentRedirect = (event) => {
      history.push(event.detail)
    }

    document.addEventListener('redirect', handleDocumentRedirect)

    return () => {
      document.removeEventListener('redirect', handleDocumentRedirect)
    }
  }, [history])

  useEffect(() => {
    if (!location.state?.noScrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [location])

  return (
    <Switch>
      <Route path={routes.STARTUP_PATH} component={StartupScreen} />

      <ProtectedRoute path={routes.SIGNIN_PATH} component={SignInScreen} />
      <ProtectedRoute
        forSignedOut
        exact
        path={routes.SIGNUP_PATH}
        component={SignUpScreen}
      />
      <ProtectedRoute
        forSignedOut
        exact
        path={routes.PASSWORD_PATH}
        render={(props) => {
          const query = new URLSearchParams(props.location.search)
          const token = query.get('token')
          if (token) {
            return <ResetPasswordScreen {...props} token={token} />
          } else {
            return <ForgotPasswordScreen {...props} />
          }
        }}
      />

      <ProtectedRoute
        forInvestor
        path={routes.FEED_PATH}
        component={FeedScreen}
      />
      <ProtectedRoute
        forInvestor
        path={routes.SIGNUP_INVESTOR_PROFILE_PATH}
        component={SignUpInvestorProfileScreen}
      />
      <ProtectedRoute
        forInvestor
        path={routes.PROFILE_PATH}
        component={ProfileScreen}
      />
      <ProtectedRoute
        forInvestor
        path={routes.ACTIVITY_PATH}
        component={ActivityScreen}
      />

      {isSuperuser ? (
        <ProtectedRoute
          forFounder
          path={routes.ANALYTICS_PATH}
          render={(routeProps) => {
            if (routeProps.match.params.companyId) {
              return <FounderAnalyticsScreen {...routeProps} />
            }

            return <CompanyListScreen {...routeProps} />
          }}
        />
      ) : (
        <ProtectedRoute
          forFounder
          path={routes.ANALYTICS_PATH}
          component={
            isActivePaidPlan(subscription)
              ? FounderAnalyticsScreen
              : AnalyticsCTAScreen
          }
        />
      )}

      <ProtectedRoute
        forSignedOut
        exact
        path={routes.HOME_PATH}
        component={LandingScreen}
      />
      <ProtectedRoute
        forFounder
        path={routes.PITCHTAPE_PATH}
        component={PitchtapeScreen}
      />
      <ProtectedRoute
        forFounder
        exact
        path={routes.CREATE_PATH}
        component={CreateScreen}
      />
      <ProtectedRoute
        forFounder
        path={routes.CREATE_VIDEOS_PATH}
        component={CreateVideosScreen}
      />
      <ProtectedRoute
        forFounder
        exact
        path={routes.PRACTICE_PATH}
        component={PracticeScreen}
      />
      <ProtectedRoute
        forFounder
        path={routes.CONNECTIONS_PATH}
        component={ConnectionsScreen}
      />
      <ProtectedRoute
        forFounder
        path={routes.BILLING_PATH}
        component={BillingScreen}
      />
      <ProtectedRoute
        forFounder
        path={routes.SUBSCRIPTION_UPGRADED_PATH}
        component={SubscriptionUpgradedScreen}
      />
      <ProtectedRoute
        forFounder
        path={routes.SUBSCRIPTION_CANCELED_PATH}
        component={SubscriptionCanceledScreen}
      />

      <ProtectedRoute
        forSignedIn
        path={routes.SETTINGS_PATH}
        component={SettingsScreen}
      />
      <ProtectedRoute
        forSignedIn
        path={routes.SIGNOUT_PATH}
        component={SignOutScreen}
      />

      <Route path={routes.CONFIRM_PATH} component={ConfirmScreen} />

      <Route component={NotFoundScreen} />
    </Switch>
  )
}

RootScreen.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default RootScreen
