import css from './TemplateTopic.module.sass'
import React, { useMemo } from 'react'
import Button from '../../Button'
import Text from '../../Text'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const TemplateTopic = ({ instance, handleClick, selectedTemplateTopics }) => {
  const isSelected = useMemo(() => {
    return selectedTemplateTopics.includes(instance.id)
  }, [selectedTemplateTopics, instance.id])
  const handleSelect = () => {
    if (isSelected) {
      handleClick(instance.id, 'remove')
    } else {
      handleClick(instance.id, 'add')
    }
  }
  return (
    <div className={css.TemplateTopic}>
      <div className={css.subject}>
        <Button
          variant={'templateTopic'}
          className={classNames(isSelected ? css.selectedTopic : '')}
          disabled={instance.inUse}
          onClick={handleSelect}
        >
          +&nbsp;{instance.subject}
        </Button>
      </div>
      <div className={css.question}>
        <Text variant="small">{instance.question}</Text>
      </div>
    </div>
  )
}

TemplateTopic.propTypes = {
  instance: PropTypes.object,
  handleClick: PropTypes.func,
  selectedTemplateTopics: PropTypes.array,
}

export default TemplateTopic
