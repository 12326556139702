import css from './TotalViews.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

const TotalViews = ({ totalViews, viewsLastWeek }) => {
  if (!totalViews) {
    return 'Your pitchtape has no views yet.'
  }

  return (
    <div className={css.container}>
      <div>
        <Text color="blue-bg" variant="sectionTitleSmall">
          {viewsLastWeek}
        </Text>
        <Text offset="quarter-top" block>
          Last 7 Days
        </Text>
      </div>

      <div>
        <Text color="yellow-bg" variant="sectionTitleSmall">
          {totalViews}
        </Text>
        <Text offset="quarter-top" block>
          All Time
        </Text>
      </div>
    </div>
  )
}

TotalViews.propTypes = {
  totalViews: PropTypes.number,
  viewsLastWeek: PropTypes.number,
}

export default TotalViews
