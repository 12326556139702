import React, { PureComponent } from 'react'
import { gql } from 'graphql-tag'
import { Mutation } from 'react-apollo'

import Layout from '../components/Layout'
import ForgotPasswordForm from '../components/ForgotPasswordForm'
import Link from '../components/Link'
import WhiteForm from '../components/WhiteForm'
import Text from '../components/Text'

import * as routes from '../constants/routes'

const RESET_PASSWORD_EMAIL = gql`
  mutation resetPasswordEmail($email: String!) {
    response: resetPasswordEmail(email: $email) {
      code
      message
    }
  }
`

class ForgotPasswordScreen extends PureComponent {
  state = {
    email: null,
  }

  renderForm() {
    return (
      <WhiteForm
        h2="Forgot Your Password?"
        intro="Enter your email address below and we'll help you reset your password."
        form={
          <Mutation mutation={RESET_PASSWORD_EMAIL}>
            {(resetPasswordEmail, { loading, error }) => (
              <ForgotPasswordForm
                loading={loading}
                error={error}
                onSent={(email) => {
                  resetPasswordEmail({
                    variables: {
                      email,
                    },
                  }).then(({ error }) => {
                    if (!error) {
                      this.setState({ email })
                    }
                  })
                }}
              />
            )}
          </Mutation>
        }
        footer={<Link to={routes.SIGNIN_PATH}>Back to Log In</Link>}
      />
    )
  }

  renderSuccess() {
    return (
      <WhiteForm
        h2="Forgot Your Password?"
        intro={
          <>
            <Text tag="p">
              A password reset link was sent to {this.state.email}.
            </Text>
            <Text tag="p" offset="single-top">
              Please check your email for instructions on how to reset your
              password.
            </Text>
          </>
        }
        footer={<Link to={routes.SIGNIN_PATH}>Back to Log In</Link>}
      />
    )
  }

  render() {
    const { email } = this.state

    return (
      <Layout variant="centered">
        {email ? this.renderSuccess() : this.renderForm()}
      </Layout>
    )
  }
}

export default ForgotPasswordScreen
