import css from './Highlights.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { Parser, DomUtils } from 'htmlparser2'
import DomHandler from 'domhandler'

import SectionHeading from './SectionHeading'

const Highlights = ({ company }) => {
  // Function to parse and modify the HTML content
  const processHTML = (html) => {
    let dom

    // Parse the HTML string into a DOM structure
    const handler = new DomHandler((error, domNodes) => {
      if (error) {
        console.error('Error parsing HTML:', error)
      } else {
        dom = domNodes
      }
    })

    const parser = new Parser(handler)
    parser.write(html)
    parser.end()

    // Recursively modify the DOM tree
    const modifyLinks = (nodes) => {
      nodes.forEach((node) => {
        if (node.type === 'tag' && node.name === 'a') {
          // Add target and rel attributes to <a> tags
          node.attribs = {
            ...node.attribs,
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        }
        // If the node has children, recursively modify them
        if (node.children && node.children.length > 0) {
          modifyLinks(node.children)
        }
      })
    }

    modifyLinks(dom)

    // Serialize the modified DOM back to an HTML string
    const serializedHTML = DomUtils.getOuterHTML(dom)

    return serializedHTML
  }

  // Process the HTML content (without sanitization)
  const modifiedContent = processHTML(company.highlights || '')

  return (
    <div className={css.container}>
      <section className={css.content}>
        <SectionHeading icon="highlights_black">Highlights</SectionHeading>

        <article dangerouslySetInnerHTML={{ __html: modifiedContent }} />
      </section>
    </div>
  )
}

Highlights.propTypes = {
  company: PropTypes.object,
}

export default Highlights
