import css from './SubmittedModal.module.sass'
import Modal from '../Modal'
import Text from '../Text'
import React from 'react'
import PropTypes from 'prop-types'

const VIDEO_REVIEWER_PLURAL = 'Investors'

const SubmittedModal = (props) => {
  return (
    <Modal buttons={props.buttons} visible={props.visible}>
      <div className={css.congratsModalContainer}>
        <Text tag="p" variant="h3">
          Congratulations!
        </Text>
        <Text tag="p" variant="h3" className={css.middle}>
          Your pitch video has been submitted to our investors feed.
        </Text>
        <Text tag="div" variant="standardLarger" className={css.bottom}>
          Your pitch video is now visible to {VIDEO_REVIEWER_PLURAL}.
        </Text>
      </div>
    </Modal>
  )
}

SubmittedModal.propTypes = {
  buttons: PropTypes.node,
  visible: PropTypes.bool,
}

export default SubmittedModal
