import css from './NoCompanies.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import Icon from '../Icon'
import Button from '../Button'
import List from '../List'

import { FEED_PATH, PROFILE_PATH } from '../../constants/routes'

const NoCompanies = ({ type }) => {
  return (
    <div className={css.container}>
      <div className={css.main}>
        {type === 'connected' ? (
          <>
            <Icon name="chat" style={{ marginBottom: 10 }} />
            <Text tag="h1" variant="h3" offset="half-bottom">
              Your messages will appear here
            </Text>
            <Text tag="p" offset="double-bottom">
              Connect with a startup and start a conversation.
            </Text>
          </>
        ) : (
          <>
            <Icon name="files" style={{ marginBottom: 10 }} />
            <Text tag="h1" variant="h3" offset="half-bottom">
              Review saved startups
            </Text>
            <Text tag="p" offset="double-bottom">
              Startups that you clicked to save are listed to the left. To view
              more startups, click the button below.
            </Text>
          </>
        )}
        <Button to={FEED_PATH} variant="primary">
          View startups
        </Button>
      </div>

      <List variant="centeredActions">
        <List.Item style={{ maxWidth: 160 }}>
          <Button
            variant="iconOnTop"
            external
            href="http://pitchtape.com/blog"
            icon="blogBlue"
            iconMaxHeight={24}
          >
            Read our blog for tips on evaluating startups
          </Button>
        </List.Item>

        <List.Item style={{ maxWidth: 115 }}>
          <Button
            variant="iconOnTop"
            to={FEED_PATH}
            icon="feedBlue"
            iconMaxHeight={24}
          >
            View startups in your feed
          </Button>
        </List.Item>

        <List.Item style={{ maxWidth: 110 }}>
          <Button
            variant="iconOnTop"
            to={PROFILE_PATH}
            icon="pencilBlue"
            iconMaxHeight={24}
          >
            Update your investor profile
          </Button>
        </List.Item>
      </List>
    </div>
  )
}

NoCompanies.propTypes = {
  type: PropTypes.string,
}

export default NoCompanies
