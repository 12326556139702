import React from 'react'
import PropTypes from 'prop-types'

import StyleButton from './StyleButton'

export const BLOCK_TYPES = [
  { icon: 'title', style: 'header-three', title: 'Title' },
  {
    icon: 'unorderedList',
    style: 'unordered-list-item',
    title: 'Unordered List',
  },
  { icon: 'orderedList', style: 'ordered-list-item', title: 'Ordered List' },
]

const BlockStyleControls = ({ editorState, onToggle }) => {
  const selection = editorState.getSelection()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()

  return (
    <>
      {BLOCK_TYPES.map((item) => (
        <StyleButton
          key={item.icon}
          active={item.style === blockType}
          {...item}
          onToggle={onToggle}
        />
      ))}
    </>
  )
}

BlockStyleControls.propTypes = {
  editorState: PropTypes.object,
  onToggle: PropTypes.func,
}

export default BlockStyleControls
