import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Modal from './Modal'
import List from './List'
import Button from './Button'
import Text from './Text'

import { formatGraphQLError } from '../helpers/errors'

const ErrorModal = ({ error, critical, onRetry }) => {
  const [closed, setClosed] = useState()

  if (closed) {
    return null
  }

  return (
    <Modal
      variant="error"
      buttons={
        <List variant="horizontal" gap="20">
          {!critical && onRetry && (
            <List.Item>
              <Button variant="primary" onClick={onRetry}>
                Retry
              </Button>
            </List.Item>
          )}

          {critical && (
            <List.Item>
              <Button
                variant="primary"
                onClick={() => {
                  window.location.reload()
                }}
              >
                Reload Page
              </Button>
            </List.Item>
          )}

          {!critical && (
            <List.Item>
              <Button
                variant="primary"
                onClick={() => {
                  setClosed(true)
                }}
              >
                OK
              </Button>
            </List.Item>
          )}
        </List>
      }
    >
      <Text tag="h1" variant="h3" centered offset="half-bottom" color="red">
        Oops!
      </Text>

      {formatGraphQLError(error)}
    </Modal>
  )
}

ErrorModal.propTypes = {
  error: PropTypes.object,
  critical: PropTypes.bool,
  onRetry: PropTypes.func,
}

export default ErrorModal
