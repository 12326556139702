import React from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { Query, Mutation } from 'react-apollo'

import FounderConnections from './FounderConnections'

import {
  GET_CONNECTED_INVESTORS,
  GET_CONNECTED_INVESTOR,
} from '../../constants/queries'

const SEE_CONNECTIONS = gql`
  mutation seeConnections($connections: [Int]) {
    seeConnections(connections: $connections) {
      code
      message
    }
  }
`

const FounderConnectionsLinker = (props) => {
  return (
    <Mutation mutation={SEE_CONNECTIONS}>
      {(seeConnections) => (
        <Query
          query={GET_CONNECTED_INVESTORS}
          variables={{ first: 10 }}
          fetchPolicy="network-only"
        >
          {({
            data: investorsData,
            loading: loadingInvestors,
            error: investorsError,
            fetchMore: fetchMoreInvestors,
            networkStatus: investorsNetworkStatus,
          }) => (
            <Query
              query={GET_CONNECTED_INVESTOR}
              variables={{ id: props.selectedConnectionId }}
              skip={!props.selectedConnectionId}
            >
              {({
                data: investorData,
                loading: loadingInvestor,
                error: investorError,
              }) => (
                <FounderConnections
                  {...props}
                  investorsData={investorsData}
                  loadingInvestors={loadingInvestors}
                  loadingMoreInvestors={investorsNetworkStatus === 3}
                  investorsError={investorsError}
                  investorData={investorData}
                  loadingInvestor={loadingInvestor}
                  investorError={investorError}
                  onFetchMoreInvestors={fetchMoreInvestors}
                  onConnectionSeen={seeConnections}
                />
              )}
            </Query>
          )}
        </Query>
      )}
    </Mutation>
  )
}

FounderConnectionsLinker.propTypes = {
  selectedConnectionId: PropTypes.string,
}

export default FounderConnectionsLinker
