import css from './ClearButton.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../Button'

const ClearButton = ({ onClick }) => (
  <div className={css.btnClear}>
    <div className={css.btnClearInner}>
      <Button variant="icon" icon="xBlack" iconOpacity="50" onClick={onClick} />
    </div>
  </div>
)

ClearButton.propTypes = {
  onClick: PropTypes.func,
}

export default ClearButton
