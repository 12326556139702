import css from './Connection.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from '../Link'
import Text from '../Text'
import CountBadge from '../CountBadge'
import { Consumer as DevicesConsumer } from '../DevicesContext'

import { toWithParams } from '../../helpers/routes'
import { STARTUP_PATH } from '../../constants/routes'

const Connection = ({
  companyId,
  chatUrl,
  selected,
  unseen,
  label,
  sublabel,
  count: _count,
}) => {
  const count = _count || 0

  const hasActions = Boolean(chatUrl || companyId)

  const renderContent = () => {
    return (
      <div className={hasActions ? css.contentWithActions : css.content}>
        {Boolean(unseen || count) && (
          <span className={css.count}>
            <CountBadge value={count + (unseen ? 1 : 0)} />
          </span>
        )}

        <div className={css.labels}>
          <Text tag="p" variant="button" weight="500" color="black">
            {label}
          </Text>

          {sublabel && (
            <Text tag="p" color="gray">
              {sublabel}
            </Text>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className={css.container}>
      {chatUrl ? (
        <Link
          variant={null}
          className={classNames(css.link, { [css.selected]: selected })}
          to={chatUrl}
        >
          {renderContent()}
        </Link>
      ) : (
        renderContent()
      )}

      {hasActions && (
        <DevicesConsumer>
          {({ mobile }) => (
            <>
              {chatUrl && (
                <Link to={chatUrl} className={css.messageLink}>
                  <Text variant={mobile ? 'standard' : 'small'} uppercase>
                    Send Message
                  </Text>
                </Link>
              )}

              {companyId && (
                <Link
                  to={toWithParams(STARTUP_PATH, { companyId })}
                  className={css.pitchtapeLink}
                >
                  <Text variant={mobile ? 'standard' : 'small'} uppercase>
                    View Pitch
                  </Text>
                </Link>
              )}
            </>
          )}
        </DevicesConsumer>
      )}
    </div>
  )
}

Connection.propTypes = {
  companyId: PropTypes.string,
  chatUrl: PropTypes.string,
  selected: PropTypes.bool,
  unseen: PropTypes.bool,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  count: PropTypes.number,
}

export default Connection
