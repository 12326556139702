import css from './Icon.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Icon = ({ name, opacity, offsetLeft, block, style, ...rest }) => (
  <img
    alt={name}
    {...rest}
    className={classNames(css.base, {
      [css.block]: block,
    })}
    style={{
      ...(opacity && { opacity: opacity / 100 }),
      ...(offsetLeft && { marginLeft: offsetLeft }),
      ...style,
    }}
    src={require('../images/icons/' + name + '.svg')}
  />
)

Icon.propTypes = {
  name: PropTypes.string,
  opacity: PropTypes.string,
  offsetLeft: PropTypes.number,
  block: PropTypes.bool,
  style: PropTypes.object,
}

export default Icon
