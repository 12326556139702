import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import uaParser from 'ua-parser-js'

const ua = new uaParser()
const mobileUa = ua.getDevice().type === 'mobile'

const DevicesContext = React.createContext()

export const Consumer = DevicesContext.Consumer

export const Provider = ({ children }) => {
  const [mobile, setMobile] = useState(window.innerWidth < 768)
  const [xsMobile, setXsMobile] = useState(window.innerWidth < 320)

  const updateProviderVars = (_xsMobile, _mobile) => {
    const windowWidth = window.innerWidth

    if (windowWidth <= 320) {
      !_xsMobile && setXsMobile(true)
    } else if (_xsMobile !== false) {
      setXsMobile(false)
    }

    if (windowWidth < 768) {
      !_mobile && setMobile(true)
    } else if (_mobile !== false) {
      setMobile(false)
    }
  }

  const handleResize = useCallback(() => {
    updateProviderVars(xsMobile, mobile)
  }, [xsMobile, mobile])

  useEffect(() => {
    updateProviderVars()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <DevicesContext.Provider
      value={{
        mobile,
        xsMobile,
        mobileUa,
      }}
    >
      {mobile !== null ? children : null}
    </DevicesContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node,
}

export default DevicesContext
