import css from './index.module.sass'

import React, { createRef } from 'react'
import PropTypes from 'prop-types'

import FieldLabel from '../FieldLabel'
import FieldError from '../FieldError'
import Link from '../../Link'
import Star from './Star'

import { FormContextConsumer } from '../FormContext'

const SCALE = [1, 2, 3, 4, 5]

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  label: PropTypes.string,
  sublabel: PropTypes.string,
  info: PropTypes.node,
  variant: PropTypes.oneOf(['base', 'large']),
  required: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isErrorVisible: PropTypes.bool,
  value: PropTypes.number,
  id: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  focused: PropTypes.bool,
  clearable: PropTypes.bool,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,

  setValidity: PropTypes.func,
  unsetValidity: PropTypes.func,
}

class RatingField extends React.PureComponent {
  static propTypes = PROP_TYPES

  state = {
    error: void 0,
    isErrorVisible: false,
  }

  containerRef = createRef()

  componentDidMount() {
    this.props.setValidity(
      this.props.name,
      !this.props.error,
      this.containerRef,
      this.showError
    )
    this.validate()

    if (this.props.focused) {
      const index = this.props.value > 0 ? this.props.value - 1 : 0
      this.containerRef.current.querySelectorAll('input')[index].focus()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.props.setValidity(
        this.props.name,
        !(this.props.error || this.state.error)
      )
    }

    if (prevProps.value !== this.props.value) {
      this.validate()
    }
  }

  componentWillUnmount() {
    this.props.unsetValidity && this.props.unsetValidity(this.props.name)
  }

  validate() {
    const { value, name, onValid, onInvalid } = this.props
    let error

    if (this.props.required && !value) {
      error = 'This is a required field'
    }

    this.setState({ error })

    this.props.setValidity(this.props.name, !(this.props.error || error))

    if (!error) {
      onValid && onValid(name)
    } else {
      onInvalid && onInvalid(name)
    }
  }

  showError = () => {
    this.setState({
      isErrorVisible: true,
    })
  }

  handleChange = (event) => {
    this.props.onUpdate &&
      this.props.onUpdate({
        [this.props.name]: event.target.value,
      })
    this.props.onChange && this.props.onChange(event)
  }

  handleClearClick = () => {
    this.props.onUpdate &&
      this.props.onUpdate({
        [this.props.name]: null,
      })
  }

  render() {
    const {
      variant = 'base',
      label,
      sublabel,
      info,
      name,
      value,
      color,
      clearable,
      onUpdate,
    } = this.props
    const isErrorVisible =
      this.props.isErrorVisible || this.state.isErrorVisible
    const error = this.props.error || this.state.error
    const id = this.props.id || this.props.name

    return (
      <div className={css.container} ref={this.containerRef}>
        <FieldLabel label={label} sublabel={sublabel} name={id} info={info} />

        <div className={css.stars}>
          {SCALE.map((scaleItem) => (
            <Star
              key={scaleItem}
              variant={variant}
              id={id + scaleItem}
              name={name}
              active={value >= scaleItem}
              checked={value === scaleItem}
              value={scaleItem}
              color={color}
              onSelect={onUpdate}
            />
          ))}

          {clearable && value > 0 && (
            <Link className={css.clear} onClick={this.handleClearClick}>
              Clear
            </Link>
          )}
        </div>

        <FieldError error={error} isErrorVisible={isErrorVisible} />
      </div>
    )
  }
}

export default function RatingFieldContainer(props) {
  return (
    <FormContextConsumer>
      {({ setValidity, unsetValidity }) => (
        <RatingField
          {...props}
          setValidity={setValidity}
          unsetValidity={unsetValidity}
        />
      )}
    </FormContextConsumer>
  )
}
