import css from './NoChat.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import Text from '../Text'

const NoChat = ({ message }) => {
  return (
    <div className={css.container}>
      <Icon
        name="chat"
        style={{
          width: 64,
          height: 64,
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      />

      <Text tag="h1" variant="h3" offset="half-bottom">
        Send a message!
      </Text>

      <div className={css.paragraph}>
        <Text tag="p">{message}</Text>
      </div>
    </div>
  )
}

NoChat.propTypes = {
  message: PropTypes.string,
}

export default NoChat
