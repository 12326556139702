import css from './WelcomeModal.module.sass'

import React, { useContext, useState } from 'react'

import Button from './Button'
import Modal from './Modal'
import Text from './Text'

import CurrentUserContext from './CurrentUserContext'
import OnBoardFlow from './FounderOnBoarding/OnBoardFlow'
import PropTypes from 'prop-types'

const WelcomeModal = ({ company, loading, refetch }) => {
  const {
    currentUser: { firstName },
  } = useContext(CurrentUserContext)
  const [visible, setVisible] = useState(true)

  const hideModal = () => {
    setVisible(false)
  }
  return (
    <>
      {visible && (
        <Modal
          visible={visible}
          variant="welcome"
          title={
            <Text tag="h1" variant="h2" color="white">
              Welcome to Pitchtape, {firstName}!
            </Text>
          }
          buttons={
            <Button
              onClick={() => {
                hideModal()
              }}
              variant="primary"
            >
              Get Started
            </Button>
          }
        >
          <div className={css.content}>
            <Text tag="p" variant="standardLarger" offset="single-bottom">
              Thank you for signing up. Next, we’ll guide you through the
              process of creating a pitch video, and help you get it in front of
              investors.
            </Text>

            <Text tag="p" variant="standardLarger" className={css.p}>
              It only takes a minute to set up your pitch and then you can start
              recording. Let’s go!
            </Text>
          </div>
        </Modal>
      )}
      {!visible && (
        <OnBoardFlow company={company} loading={loading} refetch={refetch} />
      )}
    </>
  )
}

WelcomeModal.propTypes = {
  company: PropTypes.object,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
}

export default WelcomeModal
