export const isMobile = () => {
  return /iPhone|iPad|iPod|Android|IEMobile|WPDesktop/i.test(
    navigator.userAgent
  )
}

export const isChrome = () => {
  return /chrome/i.test(navigator.userAgent)
}

export const isFirefox = () => {
  return /firefox/i.test(navigator.userAgent)
}
