import css from './Form.module.sass'

import React, {
  useRef,
  useImperativeHandle,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import CompanyDetails from './CompanyDetails'
import VideoPreview from './VideoPreview'
import DeckDetails from './DeckDetails'
import HighlightsField from './HighlightsField'
import TeamManager from './TeamManager'

import Alert from '../Alert'
import { Form } from '../Form'
import Text from '../Text'
import Well from '../Well'

import { formatGraphQLError } from '../../helpers/errors'
import { getBounds } from '../../helpers/ui'
import { HEADER_CLASSNAME } from '../../constants/classnames'
import { GTM_FORM_PITCHTAPE } from '../../constants/gtm'
import {
  DETAILS_SECTION,
  VIDEO_SECTION,
  VIDEO_PREVIEW_SECTION,
  DECK_SECTION,
  HIGHLIGHTS_SECTION,
  TEAM_SECTION,
} from '../../constants/pitchform'

const SCROLL_OFFSET = 25

let PitchForm = ({
  error,
  formData,
  saving,
  company,
  location,
  formRef,
  onUpdate,
  onInvalid,
  onTeamReorder,
}) => {
  const detailsRef = useRef()
  const videoRef = useRef()
  const videoPreviewRef = useRef()
  const deckRef = useRef()
  const highlightsRef = useRef()
  const teamRef = useRef()

  const headerHeight = useMemo(() => {
    const header = document.querySelector(`.${HEADER_CLASSNAME}`)

    return header ? header.clientHeight : 0
  }, [])

  const scrollToSection = useCallback(
    (section) => {
      const sectionMap = {
        [VIDEO_PREVIEW_SECTION]: videoPreviewRef,
        [DETAILS_SECTION]: detailsRef,
        [VIDEO_SECTION]: videoRef,
        [DECK_SECTION]: deckRef,
        [HIGHLIGHTS_SECTION]: highlightsRef,
        [TEAM_SECTION]: teamRef,
      }

      const sectionRef = sectionMap[section]
      const { top } = getBounds(sectionRef.current)

      window.scrollTo({
        left: 0,
        top: top - headerHeight - SCROLL_OFFSET,
        behavior: 'smooth',
      })
    },
    [headerHeight]
  )

  useImperativeHandle(formRef, () => ({
    scrollToSection,
  }))

  useEffect(() => {
    if (location.state?.initiatedStitch) {
      scrollToSection(VIDEO_PREVIEW_SECTION)
    }
  }, [scrollToSection, location])

  return (
    <Form gtm={GTM_FORM_PITCHTAPE}>
      <div className={saving ? css.savingActive : css.saving}>
        <Text variant="small">saving...</Text>
      </div>

      {error && <Alert variant="error">{formatGraphQLError(error)}</Alert>}

      <Well padding="0" ref={videoPreviewRef}>
        <VideoPreview company={company} />
      </Well>
      <Well ref={detailsRef}>
        <CompanyDetails
          name={formData.name}
          description={formData.description}
          locations={formData.locations}
          industries={formData.industries}
          customerCategory={formData.customerCategory}
          stage={formData.stage}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </Well>

      <Well ref={deckRef}>
        <DeckDetails />
      </Well>

      <Well ref={teamRef}>
        <TeamManager members={company.members} onReorder={onTeamReorder} />
      </Well>

      <Well ref={highlightsRef}>
        <HighlightsField
          {...formData.highlights}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </Well>
    </Form>
  )
}

PitchForm.propTypes = {
  error: PropTypes.object,
  formData: PropTypes.object,
  company: PropTypes.object,
  saving: PropTypes.bool,
  location: PropTypes.object,
  formRef: PropTypes.object,
  onTeamReorder: PropTypes.func,
  onUpdate: PropTypes.func,
  onInvalid: PropTypes.func,
}

export default withRouter(PitchForm)
