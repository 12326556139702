import css from './Toggle.module.sass'

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import pick from 'lodash.pick'

import ToggleWrap from './ToggleWrap'
import ClearButton from './ClearButton'
import Button from '../../Button'
import Text from '../../Text'
import Icon from '../../Icon'

const Toggle = forwardRef(
  (
    {
      options,
      multiple,
      value,
      valueIdKey,
      emptyIsAll,
      placeholder,
      clearable,
      selectAllEnabled,
      renderLabel,
      onClear,
      onRemove,
      ...rest
    },
    ref
  ) => {
    if (!renderLabel) {
      renderLabel = (option) => {
        return option.label
      }
    }

    const renderMultiple = () => {
      let selectedValues = value

      if (!valueIdKey) {
        selectedValues = value.map((v) =>
          options.find((o) => {
            if (valueIdKey) {
              return o.value[valueIdKey] === v[valueIdKey]
            }

            return o.value === v
          })
        )
      }

      if (selectAllEnabled) {
        if (value.length === options.length || (emptyIsAll && !value.length)) {
          return 'All'
        }
      }

      if (!selectedValues.length) {
        return placeholder
      }

      const visibleValues =
        selectedValues.length > 5 ? selectedValues.slice(0, 1) : selectedValues

      return (
        <>
          {visibleValues.map((visibleValue) => (
            <div
              key={valueIdKey ? visibleValue[valueIdKey] : visibleValue}
              className={css.pill}
            >
              <Text variant="standardLarger" color="blue">
                {renderLabel(visibleValue)}
              </Text>

              <div className={css.pillX}>
                <Button
                  variant="transparent"
                  onClick={() => onRemove({ value: visibleValue })}
                >
                  <Icon name="xSmallBlue" />
                </Button>
              </div>
            </div>
          ))}

          {visibleValues !== selectedValues && (
            <Text variant="standardLarger" nowrap>
              + {selectedValues.length - visibleValues.length} more
            </Text>
          )}
        </>
      )
    }

    const renderSingle = () => {
      let selectedValue = value

      if (!valueIdKey) {
        selectedValue = options.find((o) => o.value === value)
      }

      if (!selectedValue) {
        return placeholder
      }

      return (
        <>
          {renderLabel(selectedValue)}

          {clearable && <ClearButton onClick={onClear} />}
        </>
      )
    }

    return (
      <ToggleWrap
        {...pick(rest, ['hasError', 'clearable', 'focused', 'icon', 'onClick'])}
        ref={ref}
      >
        {!value ? placeholder : multiple ? renderMultiple() : renderSingle()}
      </ToggleWrap>
    )
  }
)

Toggle.displayName = 'Toggle'

Toggle.propTypes = {
  options: PropTypes.array,
  multiple: PropTypes.bool,
  value: PropTypes.any,
  emptyIsAll: PropTypes.bool,
  placeholder: PropTypes.string,
  selectAllEnabled: PropTypes.bool,
  clearable: PropTypes.bool,
  valueIdKey: PropTypes.string,
  renderLabel: PropTypes.func,
  onClear: PropTypes.func,
  onRemove: PropTypes.func,
}

export default Toggle
