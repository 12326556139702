import css from './InfoTooltip.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Tooltip from './Tooltip'
import Icon from './Icon'

const InfoTooltip = ({ noLeftOverlay, style, ...tooltipProps }) => (
  <div
    className={classNames(css.container, {
      [css.noLeftOverlay]: noLeftOverlay,
    })}
    style={style}
  >
    <Tooltip {...tooltipProps} hoverable offset="0">
      <div className={css.icon}>
        <Icon height="16" name="info" block />
      </div>
    </Tooltip>
  </div>
)

InfoTooltip.propTypes = {
  noLeftOverlay: PropTypes.bool,
  style: PropTypes.object,
}

export default InfoTooltip
