import React from 'react'

import Tour from './Tour'

import {
  TOUR_INVESTOR_FEED_CLASS,
  TOUR_INVESTOR_ACTIVITY_CLASS,
  TOUR_INVESTOR_PROFILE_CLASS,
  TOUR_INVESTOR_PASS_CLASS,
  TOUR_INVESTOR_SAVE_CLASS,
  TOUR_INVESTOR_CONNECT_CLASS,
} from '../constants/tour.js'

const InvestorTour = () => (
  <Tour
    steps={[
      {
        title: 'Investor Feed',
        text: 'Click on the feed tab to access your investor feed, where you will be presented with one startup at a time curated based on selected investment criteria. You can view each startup anonymously and elect to pass, save, or connect with the startup.',
        selector: '.' + TOUR_INVESTOR_FEED_CLASS,
      },
      {
        title: 'Activity Dashboard',
        text: 'Click on the activity tab to view your activity on the platform. Consider this your private dashboard where you can view and send messages to the startups that you clicked to save or connect.',
        selector: '.' + TOUR_INVESTOR_ACTIVITY_CLASS,
      },
      {
        title: 'Profile Settings',
        text: 'Click on the profile tab to access your account settings and to update your investor profile and investment criteria.',
        selector: '.' + TOUR_INVESTOR_PROFILE_CLASS,
      },
      {
        title: 'Pass on Startup',
        text: 'Click on the pass button to remove the startup from your feed. The startup will not be notified if you click to pass.',
        selector: '.' + TOUR_INVESTOR_PASS_CLASS,
      },
      {
        title: 'Save Startup',
        text: 'Click on the save button to add the startup to your list of saved startups in the activity tab. The startup will not be notified if you click to save.',
        selector: '.' + TOUR_INVESTOR_SAVE_CLASS,
      },
      {
        title: 'Connect with Startup',
        text: 'Click on the connect button to add the startup to your list of connected startups in the activity tab. You will also be added to the startup’s list of connections in their private dashboard, at which point we will allow the founder to reach out to you.',
        selector: '.' + TOUR_INVESTOR_CONNECT_CLASS,
      },
    ]}
  />
)

export default InvestorTour
