export const TOUR_INVESTOR_FEED_CLASS = 'tour_investor_feed'
export const TOUR_INVESTOR_ACTIVITY_CLASS = 'tour_investor_activity'
export const TOUR_INVESTOR_PROFILE_CLASS = 'tour_investor_profile'
export const TOUR_INVESTOR_PASS_CLASS = 'tour_investor_pass'
export const TOUR_INVESTOR_SAVE_CLASS = 'tour_investor_save'
export const TOUR_INVESTOR_CONNECT_CLASS = 'tour_investor_connect'
export const TOUR_VIDEO_BUILDER_TOPICS = 'tour_video_builder_topics'
export const TOUR_VIDEO_BUILDER_RECORD = 'tour_video_builder_record'
export const TOUR_VIDEO_BUILDER_TAPES = 'tour_video_builder_tapes'
export const TOUR_VIDEO_BUILDER_STITCH_VIDEO = 'tour_video_builder_stitch'
