import { GET_USER_COMPANY } from '../../constants/queries'

export const updateCompanyCachePublishDate = (cache, publishedAt) => {
  const { company } = cache.readQuery({ query: GET_USER_COMPANY })

  cache.writeQuery({
    query: GET_USER_COMPANY,
    data: {
      company: {
        ...company,
        publishedAt,
      },
    },
  })
}
