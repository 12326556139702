import css from './FormRow.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const FormRow = ({ variant = 'base', offset, valign, children }) => {
  return (
    <div
      className={classNames(
        css[variant],
        offset && css[`offset-${offset}`],
        valign && css[`valign-${valign}`]
      )}
    >
      {children}
    </div>
  )
}

FormRow.propTypes = {
  variant: PropTypes.string,
  offset: PropTypes.string,
  valign: PropTypes.string,
  children: PropTypes.node,
}

export default FormRow
