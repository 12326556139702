import css from './Header.module.sass'

import React, {
  forwardRef,
  useState,
  useLayoutEffect,
  useRef,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from '../Button'
import CompanyDetails from './CompanyDetails'
import CompanyUrlCopier from '../CompanyUrlCopier'
import Ionicon from '../Ionicon'
import Text from '../Text'

import InvestorActions from './InvestorActions'
import Rating from './Rating'

import DevicesContext from '../DevicesContext'
import CurrentUserContext from '../CurrentUserContext'

import { CREATE_PATH } from '../../constants/routes'
import { isActivePaidPlan } from '../../helpers/subscription'
import { useDownloadVideoUrl } from '../../helpers/hooks'

const Header = forwardRef(
  (
    {
      variant = 'base',
      company,
      editable,
      shareable,
      submitButton,
      sticky,
      forInvestor,
      pageMaxWidth,
      onInvestorAction,
      onRate,
    },
    ref
  ) => {
    const [floatingWidth, setFloatingWidth] = useState()

    const { mobile, mobileUa } = useContext(DevicesContext)
    const {
      currentUser: { subscription },
    } = useContext(CurrentUserContext)

    const containerRef = useRef()

    useLayoutEffect(() => {
      if (!sticky) {
        setFloatingWidth(null)
        return
      }

      const handleResize = () => {
        setFloatingWidth(containerRef.current.clientWidth)
      }

      window.addEventListener('resize', handleResize)
      handleResize()

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [sticky, pageMaxWidth])

    const downloadVideoUrl = useDownloadVideoUrl(company)

    return (
      <div className={css[variant]} ref={containerRef}>
        <div
          className={sticky ? css.headerFloating : css.header}
          style={sticky ? { width: floatingWidth } : void 0}
          ref={ref}
        >
          <header
            className={classNames(css.headerInner, {
              [css.headerInnerCenter]: mobileUa,
            })}
          >
            {!mobile && (
              <div className={css.details}>
                <CompanyDetails company={company} />
              </div>
            )}

            <div className={css.ratingAndButtons}>
              {forInvestor && !mobile && !mobileUa && (
                <div className={css.rating}>
                  <Text offset="half-right">
                    {!company.givenOverallRating
                      ? 'Rate pitch:'
                      : 'You rated this pitch'}
                  </Text>

                  <Rating company={company} onRate={onRate} />
                </div>
              )}

              <div className={css.buttons}>
                {isActivePaidPlan(subscription) && downloadVideoUrl && (
                  <Button
                    variant="feedAction"
                    icon={<Ionicon name="download" color="white" size="24" />}
                    href={downloadVideoUrl}
                    external
                  >
                    Download
                  </Button>
                )}

                {editable && (
                  <Button
                    variant="feedAction"
                    icon={<Ionicon name="create" color="white" size="24" />}
                    to={CREATE_PATH}
                  >
                    Edit
                  </Button>
                )}

                {shareable && (
                  <CompanyUrlCopier>
                    <Button
                      variant="feedActionPrimary"
                      icon={
                        <Ionicon
                          name="share"
                          color="white"
                          size="24"
                          style={{ left: -2 }}
                        />
                      }
                    >
                      Share
                    </Button>
                  </CompanyUrlCopier>
                )}

                {submitButton && submitButton}

                {forInvestor && (
                  <InvestorActions
                    company={company}
                    onAction={onInvestorAction}
                  />
                )}
              </div>
            </div>
          </header>
        </div>
      </div>
    )
  }
)

Header.displayName = 'Header'

Header.propTypes = {
  variant: PropTypes.string,
  company: PropTypes.object,
  editable: PropTypes.bool,
  shareable: PropTypes.bool,
  submitButton: PropTypes.node,
  sticky: PropTypes.bool,
  forInvestor: PropTypes.bool,
  pageMaxWidth: PropTypes.number,
  onInvestorAction: PropTypes.func,
  onRate: PropTypes.func,
}

export default Header
