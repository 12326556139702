import css from './EditTopicModal.module.sass'
import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../../Modal'
import Button from '../../Button'
import List from '../../List'
import Text from '../../Text'
import Link from '../../Link'
import Ionicon from '../../Ionicon'
import AnimatedEllipsis from '../../AnimatedEllipsis'

const EditTopicModal = (props) => {
  const handleClickSave = (event) => {
    event.preventDefault()
    if (props.onConfirm) props.onConfirm()
  }
  const handleClickCancel = (event) => {
    event.preventDefault()
    if (props.onCancel) props.onCancel()
  }

  return (
    <Modal
      containerRef={props.containerRef}
      onCenter={true}
      visible={props.visible}
      variant={'editTopic'}
      loading={props.loading}
      noButtons
      header={
        <>
          <div className={css.Title}>
            <Text variant={'standardLarger'}>
              <Ionicon name="create" size="16" color={'white'} />
              &nbsp;
              {props.creating ? 'Create' : 'Edit'}
              &nbsp;
              {props.isCustomTopic ? 'custom' : 'template'}
              &nbsp;topic
            </Text>
            <List variant="horizontal" gap="20">
              <List.Item></List.Item>
              <List.Item>
                <Link variant="color-green" onClick={handleClickCancel}>
                  Close
                </Link>
              </List.Item>

              <List.Item>
                <Button
                  disabled={props.saved || props.saving}
                  variant={
                    props.variant === 'delete' ? 'red' : 'primarySmaller'
                  }
                  onClick={handleClickSave}
                >
                  {props.saving ? (
                    <>
                      {props.creating ? 'Adding' : 'Saving'} topic
                      <AnimatedEllipsis />
                    </>
                  ) : props.saved ? (
                    'Topic saved'
                  ) : props.creating ? (
                    'Add topic'
                  ) : (
                    'Save topic'
                  )}
                </Button>
              </List.Item>
            </List>
          </div>
        </>
      }
    >
      <div className={css.Content}>{props.content}</div>
    </Modal>
  )
}

EditTopicModal.propTypes = {
  variant: PropTypes.string,
  loading: PropTypes.bool,
  saved: PropTypes.bool,
  saving: PropTypes.bool,
  creating: PropTypes.bool,
  content: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
  templateTopicsSelected: PropTypes.array,
  containerRef: PropTypes.object,
  children: PropTypes.func,
  isPaidUserSubscription: PropTypes.bool,
  isCustomTopic: PropTypes.bool,
}

export default EditTopicModal
