import css from './Chat.module.sass'

import React, { useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import ConnectionDetails from './ConnectionDetails'
import Messages from './Messages'
import MessageField from './MessageField'
import EmptyChat from './EmptyChat'
import NoChat from './NoChat'

const Chat = ({
  connectionId,
  noChatMessage,
  tallHeader,
  connection,
  loadingConnection,
  connectionError,
  channel,
  authKey,
  emptyMessage,
  connecting,
  onConnect,
}) => {
  const messagesRef = useRef()
  const messageFieldRef = useRef()
  const connectionDetailsRef = useRef()

  useEffect(() => {
    if (window.Chatra) {
      window.Chatra('hide')
      window.Chatra('setZIndex', -1)

      return () => {
        window.Chatra('show')
        window.Chatra('setZIndex', 9999)
      }
    }
  }, [])

  useEffect(() => {
    if (connectionId != null) {
      onConnect({
        variables: {
          connection: connectionId,
        },
      })
    }
  }, [connectionId, onConnect])

  const handleMessageFieldChange = useCallback(() => {
    messagesRef.current.requestScrollToBottom()
  }, [])

  const handleConnectionDetailsToggle = useCallback(() => {
    messagesRef.current.requestScrollToBottom()
  }, [])

  if (connectionId == null) {
    return <NoChat message={noChatMessage} />
  }

  return (
    <div className={css.container}>
      <div className={css.connectionDetails} ref={connectionDetailsRef}>
        <ConnectionDetails
          connection={connection}
          loading={loadingConnection}
          error={connectionError}
          tall={tallHeader}
          onExpandToggle={handleConnectionDetailsToggle}
        />
      </div>

      <div className={css.messages}>
        <Messages
          connecting={connecting}
          channel={channel}
          authKey={authKey}
          emptyMessage={<EmptyChat message={emptyMessage} />}
          ref={messagesRef}
        />
      </div>

      <div className={css.messageField} ref={messageFieldRef}>
        <MessageField
          connecting={connecting}
          channel={channel}
          authKey={authKey}
          connection={connection}
          onChange={handleMessageFieldChange}
        />
      </div>
    </div>
  )
}

Chat.propTypes = {
  connectionId: PropTypes.string,
  noChatMessage: PropTypes.node,
  tallHeader: PropTypes.bool,
  connection: PropTypes.object,
  loadingConnection: PropTypes.bool,
  connectionError: PropTypes.object,
  channel: PropTypes.string,
  authKey: PropTypes.string,
  emptyMessage: PropTypes.node,
  connecting: PropTypes.bool,
  onConnect: PropTypes.func,
}

export default Chat
