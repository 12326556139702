import css from './index.module.sass'

import React, { useEffect, useCallback, useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import AsyncScript from '../AsyncScript'
import Button from '../Button'
import Ionicon from '../Ionicon'
import Text from '../Text'
import Doc from './Doc'

import { PDF_JS_URL } from '../../constants/urls'
import { RIGHT_KEY_CODE, LEFT_KEY_CODE } from '../../constants/codes'
import { makeCancelable } from '../../helpers/promises'
import { isChrome } from '../../helpers/devices'

const DocViewer = ({
  loading,
  error,
  variant = 'base',
  src,
  height,
  noFullscreen,
  noControls,
  defaultPage,
  selectedPage,
  onResize,
  onPageChange,
}) => {
  const [pageNumber, setPageNumber] = useState(defaultPage || 1)
  const [pdf, setPdf] = useState()
  const [maxWidth, setMaxWidth] = useState()
  const [maxHeight, setMaxHeight] = useState()
  const [fullscreenAvailable] = useState(!noFullscreen && isChrome())
  const [fullscreenEnabled, setFullscreenEnabled] = useState()

  const containerRef = useRef()

  useEffect(() => {
    onPageChange && onPageChange(pageNumber)
  }, [onPageChange, pageNumber])

  useEffect(() => {
    let promise

    if (!loading && !error && src) {
      const pdfjs = window['pdfjs-dist/build/pdf']
      const loader = pdfjs.getDocument(src)

      promise = makeCancelable(
        loader.promise.then((pdf) => {
          setPdf(pdf)
        })
      )

      return () => {
        promise && promise.cancel()
        setPdf(null)
        setPageNumber(1)
      }
    }
  }, [src, loading, error])

  useEffect(() => {
    const handleResize = () => {
      ReactDOM.unstable_batchedUpdates(() => {
        setMaxWidth(containerRef.current.clientWidth)
        setMaxHeight(containerRef.current.clientHeight)
      })
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [variant, height])

  const handlePrevClick = useCallback(() => {
    setPageNumber((index) => index - 1)
  }, [])

  const handleNextClick = useCallback(() => {
    setPageNumber((index) => index + 1)
  }, [])

  const handleFullscreen = useCallback(() => {
    const container = containerRef.current
    const requestFullscreen =
      container.requestFullscreen ||
      container.webkitRequestFullscreen ||
      container.mozRequestFullScreen ||
      container.msRequestFullscreen
    const exitFullscreen =
      document.exitFullscreen ||
      document.webkitExitFullscreen ||
      document.mozExitFullScreen ||
      document.msExitFullscreen
    const fullscreenElement =
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement

    if (fullscreenElement) {
      exitFullscreen.call(document)
    } else {
      if (requestFullscreen) {
        requestFullscreen.call(container)
      }
    }
  }, [])

  useEffect(() => {
    const handleFullscreenChange = () => {
      setFullscreenEnabled(Boolean(document.fullscreenElement))
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  useEffect(() => {
    if (fullscreenEnabled) {
      const handleKeydown = (event) => {
        if (event.keyCode === RIGHT_KEY_CODE) {
          if (pageNumber < pdf.numPages) {
            setPageNumber(pageNumber + 1)
          }
        } else if (event.keyCode === LEFT_KEY_CODE) {
          if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
          }
        }
      }

      document.addEventListener('keydown', handleKeydown)

      return () => {
        document.removeEventListener('keydown', handleKeydown)
      }
    }
  }, [fullscreenEnabled, pdf, pageNumber])

  variant = fullscreenEnabled ? 'fillHeight' : variant

  return (
    <div className={css[variant]} ref={containerRef}>
      <div className={noControls ? css.content : css.contentWithControls}>
        {pdf && Boolean(maxWidth) && (
          <Doc
            pdf={pdf}
            pageNumber={pageNumber}
            selectedPageNumber={selectedPage}
            maxWidth={maxWidth}
            maxHeight={variant === 'fillHeight' ? maxHeight : void 0}
            onResize={onResize}
          />
        )}

        {!noControls && pdf && (
          <>
            {pageNumber > 1 && (
              <div className={css.btnPrev}>
                <Button variant="icon" onClick={handlePrevClick}>
                  <Ionicon name="arrowBack" color="black" size="32" />
                </Button>
              </div>
            )}

            {pdf && pageNumber < pdf.numPages && (
              <div className={css.btnNext}>
                <Button variant="icon" onClick={handleNextClick}>
                  <Ionicon name="arrowForward" color="black" size="32" />
                </Button>
              </div>
            )}

            <div className={css.footer}>
              <Text weight="500">
                {pageNumber} / {pdf.numPages}
              </Text>

              {fullscreenAvailable && (
                <div className={css.btnFullscreen}>
                  <Button
                    variant="icon"
                    icon={<Ionicon name="expand" color="black" size="24" />}
                    onClick={handleFullscreen}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

DocViewer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,

  variant: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.number,
  defaultPage: PropTypes.number,
  selectedPage: PropTypes.number,
  noFullscreen: PropTypes.bool,
  noControls: PropTypes.bool,

  onResize: PropTypes.func,
  onPageChange: PropTypes.func,
}

export default function DocViewerAsyncScript(props) {
  return (
    <AsyncScript src={PDF_JS_URL}>
      {({ loading, error }) => (
        <DocViewer {...props} loading={loading} error={error} />
      )}
    </AsyncScript>
  )
}
