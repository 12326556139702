import React from 'react'
import css from './VideoPlayerEmpty.module.sass'
import controls from './VideoPlayer/Controls.module.sass'
import player from './VideoPlayer/index.module.sass'
import header from './PitchtapeReview/Header.module.sass'
import headerPreview from './PitchForm/VideoPreviewHeader.module.sass'

import Text from './Text'

import Ionicon from './Ionicon'
import Button from './Button'
import { CREATE_VIDEOS_PATH } from '../constants/routes'

const VideoPlayerEmpty = () => {
  return (
    <div>
      <div className={player.main}>
        <div className={css.header}>
          <div className={header.base}>
            <header
              className={header.headerInner}
              style={{ marginTop: '0', background: 'none' }}
            >
              <div className={headerPreview.buttons}>
                <Button
                  variant="feedActionPreview"
                  icon={<Ionicon name="download" color="white" size="24" />}
                  disabled
                  style={{ marginLeft: '1em' }}
                >
                  {' '}
                  Download{' '}
                </Button>
                <Button
                  variant="feedActionPreview"
                  icon={<Ionicon name="create" color="white" size="24" />}
                  disabled
                >
                  Edit Video
                </Button>
                <Button
                  variant="feedActionPreview"
                  icon={<Ionicon name="today" color="white" size="24" />}
                  disabled
                >
                  View Pitch
                </Button>
              </div>
            </header>
          </div>
        </div>
        <div className={css.btnPlay} style={{ zIndex: 2 }}>
          <Button variant="playDisabled" disabled>
            <Ionicon name="play" color="lightGray" size="48" />
          </Button>
          <div className={css.buildButton}>
            You don’t have a pitch video to preview yet.&nbsp;
            <Button variant="primary" to={CREATE_VIDEOS_PATH}>
              Build my pitch
            </Button>
          </div>
        </div>
        {/*<div className={css.overwrite}>*/}

        {/*</div>*/}
        <div className={css.full}>
          <div className={controls.main}>
            <Button
              variant="icon"
              icon={<Ionicon name="skipBackward" color="white" size="24" />}
              disabled
            />
            <Button
              variant="icon"
              icon={<Ionicon name="play" color="white" size="24" />}
              disabled
            />
            <Button
              variant="icon"
              icon={<Ionicon name="skipForward" color="white" size="24" />}
              disabled
            />
          </div>
          <div className={controls.segmentAndProgressbar}>
            <Text
              tag="p"
              variant="h3"
              color="gray"
              weight="500"
              offset="quarter-bottom"
            >
              Topic title
            </Text>
            <div className={controls.progressbar}>
              <div className={controls.progress} style={{ width: 0 + '%' }} />
            </div>
          </div>
          <div className={controls.time}>
            <Text color="white">
              <Text color="gray">00:00</Text>
              <>
                {' '}
                <Text color="gray">/</Text> 00:00
              </>
            </Text>
          </div>
          <div className={controls.side}>
            <Button
              variant="icon"
              icon={<Ionicon name="volumeOff" color="white" size="24" />}
              disabled
            />
            <Button
              variant="icon"
              icon={<Ionicon name="expand" color="white" size="24" />}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  )
}

VideoPlayerEmpty.propTypes = {}

export default VideoPlayerEmpty
