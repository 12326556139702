import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Dropdown from './Dropdown'

const today = new Date()

const YearDropdown = ({
  min = 2000,
  max = today.getFullYear(),
  ...dropdownProps
}) => {
  const options = useMemo(() => {
    const result = []

    for (let i = max; i >= min; i--) {
      result.push({ value: i, label: i })
    }

    return result
  }, [min, max])

  return <Dropdown options={options} {...dropdownProps} />
}

YearDropdown.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
}

export default YearDropdown
