import css from './InvestorSignupModal.module.sass'
import React from 'react'

import Modal from '../Modal'
import Text from '../Text'
import PropTypes from 'prop-types'
import { SIGNIN_PATH } from '../../constants/routes'
import Link from '../Link'

const InvestorSignupModal = (props) => {
  return (
    <Modal
      title={
        <Text tag="p" variant="h3" color={'white'}>
          Thanks for signing up for Pitchtape!
        </Text>
      }
      buttons={props.buttons}
      visible={props.visible}
      loading={props.loading}
      variant={'welcome'}
    >
      <div className={css.InvestorSignupModal}>
        <Text tag="p" variant="standardLarger" centered={true}>
          Thank you for your interest in Pitchtape. Look for an email
          notification shortly for confirmation and next steps.
        </Text>
        <Text
          tag="p"
          variant="standardLarger"
          centered={true}
          offset={'double-top'}
        >
          When your Pitchtape investor account has been verified you will be
          able to <br />
          <Link to={SIGNIN_PATH}>login here</Link>.
        </Text>
      </div>
    </Modal>
  )
}

InvestorSignupModal.propTypes = {
  buttons: PropTypes.node,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
}

export default InvestorSignupModal
