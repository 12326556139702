import css from './MenuDesktop.module.sass'

import React, { useContext } from 'react'
import classNames from 'classnames'

import ConnectionsBadge from '../../ConnectionsBadge'
import Icon from '../../Icon'
import Ionicon from '../../Ionicon'
import Link from '../../Link'

import CurrentUserContext from '../../CurrentUserContext'

import {
  PITCHTAPE_PATH,
  CONNECTIONS_PATH,
  SETTINGS_PATH,
  FEED_PATH,
  SIGNOUT_PATH,
  ACTIVITY_PATH,
  PROFILE_PATH,
  CREATE_PATH,
  ANALYTICS_PATH,
  BILLING_PATH,
  CREATE_VIDEOS_PATH,
} from '../../../constants/routes'
import {
  TOUR_INVESTOR_FEED_CLASS,
  TOUR_INVESTOR_ACTIVITY_CLASS,
  TOUR_INVESTOR_PROFILE_CLASS,
} from '../../../constants/tour'
import { matchPath, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Button from '../../Button'
import PreviewPitchLoader from './PreviewPitchLoader'

const MenuDesktop = ({ location }) => {
  const {
    currentUser: { firstName, accountType, incomplete, subscription },
  } = useContext(CurrentUserContext)
  let isPaidSubscription = false
  if (subscription && subscription.isPaidSubscription) isPaidSubscription = true
  const inVideoBuilder = !(
    matchPath(location.pathname, { path: CREATE_VIDEOS_PATH }) === null
  )
  const inAnalytics = !(
    matchPath(location.pathname, { path: ANALYTICS_PATH }) === null
  )
  const inConnections = !(
    matchPath(location.pathname, { path: ACTIVITY_PATH }) === null
  )
  const inSettings = !(
    matchPath(location.pathname, { path: SETTINGS_PATH }) === null
  )
  const inBilling = !(
    matchPath(location.pathname, { path: BILLING_PATH }) === null
  )
  const inPitchProfile = !(
    matchPath(location.pathname, {
      path: CREATE_PATH,
      exact: true,
      strict: true,
    }) === null
  )
  const inPitchView = !(
    matchPath(location.pathname, { path: PITCHTAPE_PATH }) === null
  )
  const inFeed = !(matchPath(location.pathname, { path: FEED_PATH }) === null)
  const inActivity = !(
    matchPath(location.pathname, { path: ACTIVITY_PATH }) === null
  )
  const inInvestorProfile = !(
    matchPath(location.pathname, { path: PROFILE_PATH }) === null
  )

  const renderInitialsDropdown = (accountPath, settingsPath) => {
    return (
      <li className={css.menuItemWithDropdown}>
        <Link variant="mainnav" nav to={accountPath}>
          <span className={css.initials}>{firstName.slice(0, 1)}</span>
          <Ionicon name="arrowDropdown" size="24" color="white" />
        </Link>

        <ul className={css.menuDropdownRight}>
          <li
            className={classNames(css.menuDropdownItem, {
              [css.SubNavActive]: inSettings,
            })}
          >
            <Link
              variant="mainnavSub"
              active={inSettings}
              to={settingsPath}
              icon={<Ionicon name="settings" color="white" />}
            >
              Settings
            </Link>
          </li>

          {accountType === 'founder' && (
            <li
              className={classNames(css.menuDropdownItem, {
                [css.SubNavActive]: inBilling,
              })}
            >
              <Link
                variant="mainnavSub"
                active={inBilling}
                to={BILLING_PATH}
                icon={<Ionicon name="card" color="white" />}
              >
                Plans & Billing
              </Link>
            </li>
          )}

          <li className={css.menuDropdownItem}>
            <Link
              variant="mainnavSub"
              to={SIGNOUT_PATH}
              icon={<Ionicon name="power" color="white" />}
            >
              Log Out
            </Link>
          </li>
        </ul>
      </li>
    )
  }

  return (
    <>
      {accountType === 'founder' && (
        <ul className={css.menu}>
          <li className={css.menuItemAnalytics}>
            <Link
              variant="mainnav"
              nav
              to={CREATE_PATH}
              active={inPitchProfile}
              activeClassName={inPitchProfile ? css.NavActive : ''}
            >
              Pitch Profile
            </Link>
          </li>
          <li className={css.menuItemAnalytics}>
            <Link
              variant="mainnav"
              nav
              to={CREATE_VIDEOS_PATH}
              active={inVideoBuilder}
              activeClassName={inVideoBuilder ? css.NavActive : ''}
              icon={
                <Ionicon
                  name="videocam"
                  size="24"
                  offset="top-minus-1"
                  color="green"
                />
              }
            >
              Video Builder
            </Link>
          </li>
          <PreviewPitchLoader>
            <li className={css.menuItemAnalytics}>
              <Link
                variant="mainnav"
                nav
                to={PITCHTAPE_PATH}
                active={inPitchView}
                activeClassName={inPitchView ? css.NavActive : ''}
              >
                View Pitch
              </Link>
            </li>
          </PreviewPitchLoader>
          <li className={css.menuItemAnalytics}>
            <Link
              variant="mainnav"
              nav
              to={ANALYTICS_PATH}
              active={inAnalytics}
              activeClassName={inAnalytics ? css.NavActive : ''}
            >
              Pitch Analytics
            </Link>
          </li>

          {!isPaidSubscription && !inBilling && (
            <li className={css.upgradeButton}>
              <Button to={BILLING_PATH} variant="green">
                Upgrade
              </Button>
            </li>
          )}

          <li className={css.menuItemConnections}>
            <Link
              variant="mainnav"
              nav
              to={CONNECTIONS_PATH}
              active={inConnections}
              activeClassName={inConnections ? css.NavActive : ''}
              icon={<Icon name="icon_email" fill="green" />}
            >
              Connections{' '}
              <span className={css.connectionsBadge}>
                <ConnectionsBadge type={accountType} />
              </span>
            </Link>
          </li>

          {renderInitialsDropdown(SETTINGS_PATH, SETTINGS_PATH)}
        </ul>
      )}

      {accountType === 'investor' && !incomplete && (
        <ul className={css.menu}>
          <li className={`${css.menuItem} ${TOUR_INVESTOR_FEED_CLASS}`}>
            <Link
              variant="mainnav"
              nav
              exact
              to={FEED_PATH}
              active={inFeed}
              activeClassName={inFeed ? css.NavActive : ''}
            >
              Feed
            </Link>
          </li>
          <li className={`${css.menuItem} ${TOUR_INVESTOR_ACTIVITY_CLASS}`}>
            <Link
              variant="mainnav"
              nav
              to={ACTIVITY_PATH}
              active={inActivity}
              activeClassName={inActivity ? css.NavActive : ''}
            >
              Activity Dashboard{' '}
              <span className={css.connectionsBadge}>
                <ConnectionsBadge type={accountType} />
              </span>
            </Link>
          </li>
          <li
            className={`${css.menuItemProfile} ${TOUR_INVESTOR_PROFILE_CLASS}`}
          >
            <Link
              variant="mainnav"
              nav
              to={PROFILE_PATH}
              active={inInvestorProfile}
              activeClassName={inInvestorProfile ? css.NavActive : ''}
            >
              My Profile
            </Link>
          </li>

          {renderInitialsDropdown(PROFILE_PATH, SETTINGS_PATH)}
        </ul>
      )}
    </>
  )
}

MenuDesktop.propTypes = {
  location: PropTypes.object,
}

export default withRouter(MenuDesktop)
