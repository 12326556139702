export const formatPrice = (
  amount,
  { currency = 'usd', optionalCents } = {}
) => {
  if (currency !== 'usd') {
    throw new Error('Unsupported currency')
  }

  const dollars = Math.floor(amount / 100)
  const cents = amount % 100

  return (
    '$' +
    dollars +
    (cents || !optionalCents ? `.${String(cents).padStart(2, '0')}` : '')
  )
}
