import css from './SharingBlock.module.sass'

import React from 'react'

import Button from '../Button'
import CompanyUrlCopier from '../CompanyUrlCopier'
import Ionicon from '../Ionicon'

import Well from '../Well'

const SharingBlock = () => {
  return (
    <Well title="Share Pitch">
      <div className={css.buttons}>
        <CompanyUrlCopier>
          <Button
            variant="primary"
            icon={<Ionicon name="share" color="white" size="24" />}
          >
            Copy my pitch URL
          </Button>
        </CompanyUrlCopier>
      </div>
    </Well>
  )
}

export default SharingBlock
