import React from 'react'
import PropTypes from 'prop-types'

import Dropdown from './Dropdown'

const DEFAULT_OPTIONS = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
]

const DEFAULT_OPTIONS_WITH_UNSURE = [
  ...DEFAULT_OPTIONS,
  { value: 'null', label: 'Unsure' },
]

const stringToBoolean = (str) => {
  switch (str) {
    case 'true':
      return true
    case 'false':
      return false
    case 'null':
      return null
    default:
      return void 0
  }
}

const YesNoDropdown = ({
  name,
  value,
  onUpdate,
  orUnsure,
  ...dropdownProps
}) => (
  <Dropdown
    options={orUnsure ? DEFAULT_OPTIONS_WITH_UNSURE : DEFAULT_OPTIONS}
    value={String(value)}
    name={name}
    onUpdate={(obj) => onUpdate({ [name]: stringToBoolean(obj[name]) })}
    {...dropdownProps}
  />
)

YesNoDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  orUnsure: PropTypes.bool,
  onUpdate: PropTypes.func,
}

export default YesNoDropdown
