import React, { useCallback, useRef } from 'react'
import OnBoarding from './index'
import { useMutation } from 'react-apollo'
import { GET_USER_COMPANY, UPDATE_COMPANY } from '../../constants/queries'
import { formDataToServerData } from '../../helpers/forms'
import CompanyDataFormLinker from './CompanyDataFormLinker'
import Loader from '../Loader'
import PropTypes from 'prop-types'
import Text from '../Text'

const OnBoardFlow = ({ company, loading, refetch }) => {
  const formRef = useRef()

  const [
    updateCompany,
    { data: updatingData, loading: updating, error: updatingError },
  ] = useMutation(UPDATE_COMPANY, {
    update: (cache, { data: { updateCompany } }) => {
      const { company } = cache.readQuery({ query: GET_USER_COMPANY })

      cache.writeQuery({
        query: GET_USER_COMPANY,
        data: {
          company: {
            ...company,
            ...updateCompany.obj,
          },
        },
      })
    },
  })

  const handleUpdate = useCallback(
    (data) => {
      return updateCompany({
        variables: {
          input: {
            ...formDataToServerData(data, { urlKeys: ['website'] }),
            validate: false,
          },
        },
      })
    },
    [updateCompany]
  )
  return (
    <OnBoarding
      steps={[
        {
          title: "What is your company's name and tagline?",
          text: (
            <>
              {loading && <Loader variant="centered" />}
              <Text tag="p" variant="standard" offset="double-bottom">
                This info will be used to catch the viewer’s attention. If you
                don’t have your tagline now, that’s ok, you can add it later in
                your pitch profile.
              </Text>

              {!loading && (
                <CompanyDataFormLinker
                  error={updatingError}
                  formErrors={updatingData && updatingData.updateCompany.errors}
                  company={company}
                  saving={updating}
                  formRef={formRef}
                  onUpdate={handleUpdate}
                  nameField
                  descriptionField
                />
              )}
            </>
          ),
        },
        {
          title: 'Add search filters to your pitch',
          text: (
            <>
              <Text tag="p" variant="standardLarger">
                Investors use filters to find and view pitches on Pitchtape.
              </Text>
              <Text tag="p" variant="standardLarger">
                Filters are optional for sharing your pitch.
              </Text>

              {loading && <Loader variant="centered" />}
              {!loading && (
                <CompanyDataFormLinker
                  error={updatingError}
                  formErrors={updatingData && updatingData.updateCompany.errors}
                  company={company}
                  saving={updating}
                  formRef={formRef}
                  onUpdate={handleUpdate}
                  industryField
                  stageField
                  customerCategoryField
                  locationsField
                />
              )}
            </>
          ),
        },
        {
          title: 'Prepare your pitch outline and start recording!',
          text:
            'Start by using pitch topic templates and the video builder to organize your pitch, then ' +
            'record one topic at a time when you’re ready. ',
        },
      ]}
      refetch={refetch}
    />
  )
}
OnBoardFlow.propTypes = {
  company: PropTypes.object,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
}

export default OnBoardFlow
