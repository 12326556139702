import css from './Message.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import Link from '../Link'
import Text from '../Text'

import { timetokenToDate, formatDateTime } from '../../helpers/dates'
import { urlToIconName, urlToFileName, isImage } from '../../helpers/files'

const Message = ({ timetoken, message, attachments, mine, onImageLoad }) => {
  const renderType = (url) => {
    return <Icon name={urlToIconName(url)} />
  }

  return (
    <article
      className={mine ? css.mine : css.yours}
      title={formatDateTime(timetokenToDate(timetoken))}
    >
      {message &&
        message.split('\n').map((chunk, index) => (
          <div className={css.text} key={index}>
            {chunk}
            <br />
          </div>
        ))}

      {attachments && (
        <>
          {attachments.map(({ id, url, name }) => (
            <div className={css.attachment} key={id}>
              {isImage(url) ? (
                <img
                  className={css.image}
                  src={url}
                  alt={urlToFileName(url)}
                  onLoad={onImageLoad}
                />
              ) : (
                <Link
                  href={url}
                  className={css.file}
                  download={urlToFileName(url)}
                  external
                >
                  <span className={css.fileIcon}>{renderType(url)}</span>
                  <Text tag="p" truncated className={css.fileName}>
                    {name || 'File'}
                  </Text>
                  <Icon name="download" />
                </Link>
              )}
            </div>
          ))}
        </>
      )}
    </article>
  )
}

Message.propTypes = {
  timetoken: PropTypes.string,
  message: PropTypes.string,
  attachments: PropTypes.array,
  mine: PropTypes.bool,
  onImageLoad: PropTypes.func,
}

export default Message
