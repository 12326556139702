import css from './index.module.sass'

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Item from './Item'

const ListContext = React.createContext()
export const ListContextConsumer = ListContext.Consumer

const List = forwardRef(
  (
    {
      variant = 'base',
      gap,
      offset,
      alignRight,
      iconProps,
      children,
      customClass,
    },
    ref
  ) => {
    return (
      <ListContext.Provider value={{ variant, gap, offset, iconProps }}>
        <ul
          className={classNames(
            css[variant],
            css[`gap-${gap}`],
            {
              [css.alignRight]: alignRight,
            },
            customClass ? customClass : ''
          )}
          ref={ref}
        >
          {children}
        </ul>
      </ListContext.Provider>
    )
  }
)

List.displayName = 'List'

List.propTypes = {
  variant: PropTypes.string,
  gap: PropTypes.string,
  offset: PropTypes.string,
  alignRight: PropTypes.bool,
  iconProps: PropTypes.object,
  children: PropTypes.node,
  customClass: PropTypes.string,
}

List.Item = Item
export default List
