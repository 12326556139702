import './default.css'
import css from './index.module.sass'

import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import {
  EditorState,
  ContentState,
  RichUtils,
  getDefaultKeyBinding,
} from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createLinkDetectionPlugin from 'draft-js-link-detection-plugin'

import FieldLabel from '../FieldLabel'
import FieldError from '../FieldError'
import { FormContextConsumer } from '../FormContext'
import InlineStyleControls from './InlineStyleControls'
import BlockStyleControls from './BlockStyleControls'

const linkDetectionPlugin = createLinkDetectionPlugin()
const plugins = [linkDetectionPlugin]

class EditorField extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    sublabel: PropTypes.string,
    info: PropTypes.string,
    isErrorVisible: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onValid: PropTypes.func,
    onInvalid: PropTypes.func,
    onUpdate: PropTypes.func,
    readOnly: PropTypes.bool,
    setValidity: PropTypes.func,
    unsetValidity: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      editorState: this.valueToEditorState(props.value),
      isErrorVisible: false,
    }
  }

  containerRef = createRef()
  editorRef = createRef()

  componentDidMount() {
    this.props.setValidity(
      this.props.name,
      !this.props.error,
      this.containerRef,
      this.showError
    )
    this.validate()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.error !== this.props.error ||
      prevState.error !== this.state.error
    ) {
      this.props.setValidity(
        this.props.name,
        !(this.props.error || this.state.error)
      )
    }
  }

  componentWillUnmount() {
    this.props.unsetValidity && this.props.unsetValidity(this.props.name)
  }

  valueToEditorState(_value) {
    const value = _value || ''
    const isHtml = /^</.test(value)
    const content = isHtml
      ? stateFromHTML(value)
      : ContentState.createFromText(value)

    return EditorState.createWithContent(content)
  }

  validate() {
    const { required, onValid, onInvalid } = this.props

    const value = this.state.editorState.getCurrentContent().getPlainText()
    let error

    if (required && !value) {
      error = 'This is a required field'
    }

    this.setState({ error })

    if (!error) {
      onValid && onValid()
    } else {
      onInvalid && onInvalid()
    }
  }

  handleBlur = (...args) => {
    this.setState({
      isErrorVisible: true,
    })

    this.props.onBlur && this.props.onBlur(...args)
  }

  handleFocus = (...args) => {
    this.setState({
      isErrorVisible: false,
    })

    this.props.onFocus && this.props.onFocus(...args)
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      this.handleChange(newState)
      return true
    }

    return false
  }

  handleChange = (editorState) => {
    const { onUpdate, name } = this.props

    this.setState({ editorState })

    const value = stateToHTML(editorState.getCurrentContent())

    if (value !== this.props.value) {
      onUpdate({ [name]: value })
    }
  }

  keyBindingFn = (event) => {
    if (event.keyCode === 9) {
      // tab
      const newEditorState = RichUtils.onTab(event, this.state.editorState, 4)

      if (newEditorState !== this.state.editorState) {
        this.handleChange(newEditorState)
      }

      return void 0
    }

    return getDefaultKeyBinding(event)
  }

  handleBlockTypeToggle = (blockType) => {
    this.handleChange(
      RichUtils.toggleBlockType(this.state.editorState, blockType)
    )
  }

  handleInlineStyleToggle = (inlineStyle) => {
    this.handleChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    )
  }

  render() {
    const { placeholder, label, sublabel, info, name } = this.props
    const { editorState } = this.state

    const error = this.props.error || this.state.error
    const isErrorVisible =
      this.state.isErrorVisible || this.props.isErrorVisible

    return (
      <div className={css.container} ref={this.containerRef}>
        <FieldLabel label={label} sublabel={sublabel} name={name} info={info} />

        <div className={css.controls}>
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.handleInlineStyleToggle}
          />

          <BlockStyleControls
            editorState={editorState}
            onToggle={this.handleBlockTypeToggle}
          />
        </div>

        <div
          className={classNames(css.editor, {
            [css.readOnly]: this.props.readOnly,
          })}
        >
          <Editor
            readOnly={this.props.readOnly}
            editorState={editorState}
            placeholder={placeholder}
            keyBindingFn={this.keyBindingFn}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            plugins={plugins}
            ref={this.editorRef}
          />
        </div>

        <FieldError error={error} isErrorVisible={isErrorVisible} />
      </div>
    )
  }
}

export default function EditorFieldContainer(props) {
  return (
    <FormContextConsumer>
      {({ setValidity, unsetValidity }) => (
        <EditorField
          {...props}
          setValidity={setValidity}
          unsetValidity={unsetValidity}
        />
      )}
    </FormContextConsumer>
  )
}
