import css from './SimpleFooter.module.sass'

import React from 'react'

const BASE_URL = 'https://www.pitchtape.com'

const SimpleFooter = () => {
  return (
    <footer className={css.container}>
      <a href={`${BASE_URL}`}>
        <div>Powered by</div>
        <div>
          <img
            src="https://sandbox.pitchtape.com/images/logo.svg"
            alt="Pitchtape"
            width="127"
          />
        </div>
      </a>
    </footer>
  )
}

export default SimpleFooter
