import css from './VideoNotReady.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import AnimatedEllipsis from './AnimatedEllipsis'
import Image from './Image'
import Text from './Text'
import VideoPlayerEmpty from './VideoPlayerEmpty'

const VideoNotReady = ({ company }) => {
  const { concatenatedVideo } = company

  return concatenatedVideo ? (
    <div className={css.processing}>
      <div className={css.processingInner}>
        <div className={css.stichingImage}>
          <Image fileName="stitching.svg" />
        </div>

        <Text variant="h2" tag="h2" color="white" offset="half-bottom">
          Your video is processing
          <AnimatedEllipsis />
        </Text>

        <Text
          variant="standardLarger"
          tag="p"
          color="white"
          style={{ lineHeight: '24px' }}
        >
          Video-stitching and encoding can take about 15 minutes to complete.
          Look for an email notification letting you know that your video pitch
          is ready!
        </Text>
      </div>
    </div>
  ) : (
    <VideoPlayerEmpty />
  )
}

VideoNotReady.propTypes = {
  company: PropTypes.object,
}

export default VideoNotReady
