import css from './AttachLink.module.sass'

import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { Mutation } from 'react-apollo'
import uuid from 'uuid/v1'

import Link from '../Link'
import Modal from '../Modal'

const UPLOAD_CHAT_FILE = gql`
  mutation ($binary: Upload!, $chat: String!) {
    uploadChatFile(input: { binary: $binary, chat: $chat }) {
      obj {
        id
        binary
      }
      errors {
        field
        messages
      }
    }
  }
`

const ACCEPTED_FILE_TYPES = [
  '.doc',
  '.docx',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.xls',
  '.xlsx',
  'vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.pdf',
  'application/pdf',
  '.png',
  'image/png',
  '.jpg',
  '.jpeg',
  'image/jpeg',
  '.mp4',
].join(',')

const BYTES_IN_MEGABYTE = 1024 * 1024

const AttachLink = ({ chatId, onUploadStart, onUploadEnd }) => {
  const [error, setError] = useState()
  const [localId, setLocalId] = useState(uuid())

  const inputRef = useRef()

  return (
    <Mutation mutation={UPLOAD_CHAT_FILE} onError={(error) => setError(error)}>
      {(uploadChatFile, { loading }) => (
        <div className={css.container}>
          {error && (
            <Modal
              variant="error"
              onClosed={() => {
                setError(null)
              }}
            >
              {error}
            </Modal>
          )}

          <Link
            variant="iconOnLeft"
            icon="attach"
            disabled={loading}
            onClick={() => inputRef.current.click()}
          >
            Attach file
          </Link>

          <input
            key={localId}
            className={css.input}
            type="file"
            accept={ACCEPTED_FILE_TYPES}
            ref={inputRef}
            onChange={({
              target: {
                files: [file],
              },
            }) => {
              if (!file) {
                return
              }

              if (file.size > 10 * BYTES_IN_MEGABYTE) {
                return setError('File exceeds 10MB limit')
              }

              const attachment = { file, localId }
              setLocalId(uuid())

              onUploadStart(attachment)

              uploadChatFile({
                variables: { binary: file, chat: chatId },
              }).then(
                ({
                  data: {
                    uploadChatFile: { obj, errors },
                  },
                }) => {
                  if (errors) {
                    return setError(errors[0].messages.join('\n'))
                  }
                  onUploadEnd({ ...attachment, url: obj.binary, id: obj.id })
                }
              )
            }}
          />
        </div>
      )}
    </Mutation>
  )
}

AttachLink.propTypes = {
  chatId: PropTypes.string,
  onUploadStart: PropTypes.func,
  onUploadEnd: PropTypes.func,
}

export default AttachLink
