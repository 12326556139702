import React, { useState, useRef } from 'react'
import Popup from '../../Popup'
import css from './index.module.sass'
import Text from '../../Text'
import Loader from '../../Loader'
import Alert from '../../Alert'
import { formatGraphQLError } from '../../../helpers/errors'
import Link from '../../Link'
import Button from '../../Button'
import Icon from '../../Icon'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { useMutation } from 'react-apollo'
import { GET_ME } from '../../../constants/queries'

const USER_COMPLETED_VIDEO_BUILDER_TOUR = gql`
  mutation {
    userCompletedVideoBuilderTour {
      code
      message
    }
  }
`

const VideoTour = ({ steps }) => {
  const [stepIndex, setStepIndex] = useState(0)
  const [visible, setVisible] = useState(true)
  const maxStepIndexRef = useRef(0)
  if (stepIndex > maxStepIndexRef.current) {
    maxStepIndexRef.current = stepIndex
  }
  const mobile = false

  const isFirstStep = stepIndex === 0
  const isLastStep = stepIndex === steps.length - 1

  const exitDialog = () => {
    setVisible(false)
  }

  const [setCompletedVideoBuilderTour, { loading, error }] = useMutation(
    USER_COMPLETED_VIDEO_BUILDER_TOUR,
    {
      update: (cache) => {
        const { me } = cache.readQuery({ query: GET_ME })

        cache.writeQuery({
          query: GET_ME,
          data: {
            me: {
              ...me,
              completedVideoBuilderTour: true,
            },
          },
        })
      },
      onCompleted: () => {
        exitDialog()
      },
      onError: () => {
        console.log(error)
        exitDialog()
      },
    }
  )

  const renderStep = (step) => {
    return (
      <Popup
        visible={visible}
        variant="videoBuilderTour"
        overlay
        maxWidth={850}
        anchorSelector={step.selector}
        header={
          <div className={css.header}>
            <Text tag="h1" variant="h3" color="deep-blue">
              {step.title}
            </Text>
            <Text color="gray">
              {stepIndex + 1}/{steps.length}
            </Text>
          </div>
        }
        content={
          loading ? (
            <Loader variant="centered" />
          ) : error ? (
            <Alert variant="error">{formatGraphQLError(error)}</Alert>
          ) : (
            step.text
          )
        }
        footer={
          <div className={css.footer}>
            <div className={css.skip}>
              {!isLastStep && (
                <Link onClick={setCompletedVideoBuilderTour}>Skip Tour</Link>
              )}
            </div>

            {!isFirstStep &&
              (mobile ? (
                <Button
                  variant={mobile ? 'iconOutline' : 'outline'}
                  offset={mobile ? void 0 : 'single-left'}
                  onClick={() => setStepIndex(stepIndex - 1)}
                >
                  {mobile ? <Icon name="arrowLeftBlue" /> : 'Previous'}
                </Button>
              ) : (
                <Link onClick={() => setStepIndex(stepIndex - 1)}>
                  Previous
                </Link>
              ))}

            <Button
              variant={
                mobile
                  ? isLastStep
                    ? 'primary'
                    : 'iconPrimary'
                  : 'primaryNarrow'
              }
              offset={mobile ? 'half-left' : 'single-left'}
              onClick={() => {
                if (isLastStep) {
                  setCompletedVideoBuilderTour()
                } else {
                  setStepIndex(stepIndex + 1)
                }
              }}
            >
              {isLastStep ? (
                `Done`
              ) : mobile ? (
                <Icon name="arrowRightWhite" />
              ) : (
                'Next'
              )}
            </Button>
          </div>
        }
      />
    )
  }

  return renderStep(steps[stepIndex])
}

VideoTour.propTypes = {
  steps: PropTypes.array,
}

export default VideoTour
