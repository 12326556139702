import css from './FormCol.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const FormCol = ({ variant = 'base', offset, size, children }) => {
  return (
    <div
      className={classNames(
        css[variant],
        css[`offset-${offset}`],
        css[`size-${size}`]
      )}
    >
      {children}
    </div>
  )
}

FormCol.propTypes = {
  variant: PropTypes.string,
  offset: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
}

export default FormCol
