import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import pick from 'lodash.pick'

import {
  Form,
  FormRow,
  FormCol,
  TextField,
  WebsiteField,
  TextArea,
} from '../../Form'
import Button from '../../Button'
import List from '../../List'

const TeamMemberForm = ({ member, loading, onUpdate, onCancel, onCreate }) => {
  const [formData, setFormData] = useState({
    id: void 0,
    firstName: '',
    lastName: '',
    title: '',
    websiteUrl: '',
    bio: '',
  })
  const [valid, setValid] = useState(false)

  useEffect(() => {
    if (member) {
      setFormData(
        pick(member, [
          'id',
          'firstName',
          'lastName',
          'title',
          'websiteUrl',
          'bio',
        ])
      )
    }
  }, [member])

  const handleFieldUpdate = (patch) => {
    setFormData({
      ...formData,
      ...patch,
    })
  }

  const handleSubmit = () => {
    if (valid) {
      member ? onUpdate(formData) : onCreate(formData)
    }
  }

  return (
    <Form
      fake
      onSubmit={handleSubmit}
      onValid={() => setValid(true)}
      onInvalid={() => setValid(false)}
    >
      <FormRow>
        <FormCol>
          <TextField
            label="First Name *"
            name="firstName"
            value={formData.firstName}
            required
            focused
            onUpdate={handleFieldUpdate}
          />
        </FormCol>

        <FormCol>
          <TextField
            label="Last Name *"
            name="lastName"
            value={formData.lastName}
            required
            onUpdate={handleFieldUpdate}
          />
        </FormCol>
      </FormRow>

      <FormRow offset="25">
        <FormCol>
          <TextField
            label="Title/Role *"
            name="title"
            value={formData.title}
            required
            onUpdate={handleFieldUpdate}
          />
        </FormCol>

        <FormCol>
          <WebsiteField
            label="Profile URL (LinkedIn, AngelList, etc.) *"
            name="websiteUrl"
            value={formData.websiteUrl}
            required
            onUpdate={handleFieldUpdate}
          />
        </FormCol>
      </FormRow>

      <FormRow offset="25">
        <FormCol>
          <TextArea
            label="Bio"
            name="bio"
            value={formData.bio}
            rows="5"
            counter
            maxlength={500}
            onUpdate={handleFieldUpdate}
          />
        </FormCol>
      </FormRow>

      <FormRow offset="35">
        <List variant="horizontalOnDesktop" gap="20">
          <List.Item>
            <Button
              onClick={handleSubmit}
              variant="primary"
              disabled={!valid || loading}
              block
            >
              {member ? 'Update team member' : 'Save Team Member'}
            </Button>
          </List.Item>

          <List.Item>
            <Button
              onClick={() => onCancel()}
              variant="outline"
              disabled={loading}
              block
            >
              Cancel
            </Button>
          </List.Item>
        </List>
      </FormRow>
    </Form>
  )
}

TeamMemberForm.propTypes = {
  member: PropTypes.object,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
}

export default TeamMemberForm
