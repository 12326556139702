import css from './Link.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink, NavLink, Switch, Route } from 'react-router-dom'
import classNames from 'classnames'

import Icon from './Icon'

import { toWithParams } from '../helpers/routes'

const Link = ({
  children,
  variant = 'base',
  nav,
  params,
  to,
  hash,
  disabled,
  external,
  className: _className,
  color,
  inlineBlock,
  icon,
  activeClassName,
  active,
  offset,
  ...linkProps
}) => {
  const className = classNames(
    css[variant],
    _className,
    css[`color-${color}`],
    css[`offset-${offset}`],
    {
      [css.disabled]: disabled,
      [css.inlineBlock]: inlineBlock,
    }
  )

  const renderChildren = () => {
    return (
      <>
        {icon && (
          <span className={css.icon}>
            {typeof icon === 'string' ? <Icon name={icon} /> : icon}
          </span>
        )}
        {typeof children === 'function' ? (
          <>
            <Switch>
              <Route
                exact
                path={toWithParams(to, params, hash)}
                render={() => children({ active: true })}
              />
              <Route render={() => children({ active: false })} />
            </Switch>
          </>
        ) : (
          children
        )}
      </>
    )
  }

  if (disabled) {
    return <span className={className}>{renderChildren()}</span>
  }

  if (to) {
    if (nav) {
      return (
        <NavLink
          {...linkProps}
          className={className}
          activeClassName={activeClassName || css.active}
          to={toWithParams(to, params, hash)}
        >
          {renderChildren()}
        </NavLink>
      )
    }

    return (
      <RouterLink
        {...linkProps}
        className={className}
        to={toWithParams(to, params, hash)}
        target={external && '_blank'}
      >
        {renderChildren()}
      </RouterLink>
    )
  }

  return (
    <a
      {...linkProps}
      className={classNames(className, { [css.active]: active })}
      target={external && '_blank'}
    >
      {renderChildren()}
    </a>
  )
}

Link.propTypes = {
  variant: PropTypes.string,
  nav: PropTypes.bool,
  params: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hash: PropTypes.string,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  className: PropTypes.any,
  color: PropTypes.string,
  inlineBlock: PropTypes.bool,
  icon: PropTypes.node,
  activeClassName: PropTypes.string,
  active: PropTypes.bool,
  offset: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export default Link
