import css from './FormSection.module.sass'

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Asterisk from '../Asterisk'
import Text from '../Text'

const FormSection = forwardRef(
  ({ heading, description, separate, children }, ref) => {
    const headingHasAsterisk = heading && heading.includes('*')

    return (
      <section
        className={classNames(css.container, {
          [css.separate]: separate,
        })}
        ref={ref}
      >
        {heading && (
          <div className={css.h1}>
            <Text tag="h1" variant="sectionTitle">
              {heading.replace(' *', '')} {headingHasAsterisk && <Asterisk />}
            </Text>
          </div>
        )}

        {description && (
          <div className={css.p}>
            <Text tag="p">{description}</Text>
          </div>
        )}

        {children}
      </section>
    )
  }
)

FormSection.displayName = 'FormSection'

FormSection.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  separate: PropTypes.bool,
  children: PropTypes.node,
}

export default FormSection
