import css from './MenuMobile.module.sass'

import React, { useState, useCallback, useContext } from 'react'
import { CSSTransition } from 'react-transition-group'

import Button from '../../Button'
import ConnectionsBadge from '../../ConnectionsBadge'
import Link from '../../Link'

import CurrentUserContext from '../../CurrentUserContext'

import {
  PITCHTAPE_PATH,
  ANALYTICS_PATH,
  CONNECTIONS_PATH,
  SETTINGS_PATH,
  SIGNOUT_PATH,
  BILLING_PATH,
  FEED_PATH,
  ACTIVITY_PATH,
  PROFILE_PATH,
  CREATE_PATH,
  CREATE_VIDEOS_PATH,
} from '../../../constants/routes'

const MenuMobile = () => {
  const [opened, setOpened] = useState()

  const {
    currentUser: { accountType },
  } = useContext(CurrentUserContext)

  const handleHamburgerClick = useCallback(() => {
    setOpened((x) => !x)
  }, [])

  const handleLinkClick = useCallback(() => {
    setOpened(false)
  }, [])

  return (
    <div className={css.container}>
      <Button
        className={opened ? css.btnHamburgerOpened : css.btnHamburger}
        variant="icon"
        onClick={handleHamburgerClick}
      >
        <i className={css.line1} />
        <i className={css.line2} />
        <i className={css.line3} />

        <span className={css.hamburgerBadge}>
          <ConnectionsBadge />
        </span>
      </Button>

      <div className={css.menuContainer}>
        <CSSTransition
          in={opened}
          timeout={200}
          unmountOnExit
          classNames={{
            appear: css[`menu-appear`],
            appearActive: css[`menu-appear-active`],
            appearDone: css[`menu-done-appear`],
            enter: css[`menu-enter`],
            enterActive: css[`menu-enter-active`],
            enterDone: css[`menu-done-enter`],
            exit: css[`menu-exit`],
            exitActive: css[`menu-exit-active`],
            exitDone: css[`menu-done-exit`],
          }}
        >
          <ul className={css.menu}>
            {accountType === 'founder' && (
              <>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={CREATE_PATH}
                    onClick={handleLinkClick}
                  >
                    Pitch Profile
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={CREATE_VIDEOS_PATH}
                    onClick={handleLinkClick}
                  >
                    Video Builder
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={PITCHTAPE_PATH}
                    onClick={handleLinkClick}
                  >
                    View Pitch
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={ANALYTICS_PATH}
                    onClick={handleLinkClick}
                  >
                    Analytics Dashboard
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={CONNECTIONS_PATH}
                    onClick={handleLinkClick}
                  >
                    Connections
                    <span className={css.menuBadge}>
                      <ConnectionsBadge />
                    </span>
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={SETTINGS_PATH}
                    onClick={handleLinkClick}
                  >
                    Settings
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={BILLING_PATH}
                    onClick={handleLinkClick}
                  >
                    Plans & Billing
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={SIGNOUT_PATH}
                    onClick={handleLinkClick}
                  >
                    Log Out
                  </Link>
                </li>
              </>
            )}

            {accountType === 'investor' && (
              <>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={FEED_PATH}
                    onClick={handleLinkClick}
                  >
                    Feed
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={ACTIVITY_PATH}
                    onClick={handleLinkClick}
                  >
                    Activity Dashboard
                    <span className={css.menuBadge}>
                      <ConnectionsBadge type={accountType} />
                    </span>
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={PROFILE_PATH}
                    onClick={handleLinkClick}
                  >
                    My Profile
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={SETTINGS_PATH}
                    onClick={handleLinkClick}
                  >
                    Settings
                  </Link>
                </li>
                <li className={css.menuItem}>
                  <Link
                    className={css.menuLink}
                    to={SIGNOUT_PATH}
                    onClick={handleLinkClick}
                  >
                    Log Out
                  </Link>
                </li>
              </>
            )}
          </ul>
        </CSSTransition>
      </div>
    </div>
  )
}

export default MenuMobile
