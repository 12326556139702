import css from './TextField.module.sass'

import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'
import classNames from 'classnames'

import FieldLabel from './FieldLabel'
import FieldError from './FieldError'
import FieldCounter from './FieldCounter'

import { FormContextConsumer } from './FormContext'
import {
  MINLENGTH_ERROR,
  MISSING_ERROR,
  MAX_ERROR,
} from '../../constants/errors'

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  label: PropTypes.string,
  sublabel: PropTypes.node,
  labelClassName: PropTypes.string,
  info: PropTypes.node,
  variant: PropTypes.oneOf([
    'base',
    'activeDropdown',
    'transparent',
    'autocomplete',
  ]),
  type: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
  minlength: PropTypes.number,
  maxlength: PropTypes.number,
  password: PropTypes.bool,
  focused: PropTypes.bool,
  autosize: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  errorsOverride: PropTypes.object,
  isErrorVisible: PropTypes.bool,
  isValidating: PropTypes.bool,
  max: PropTypes.number,
  appendToInputContainer: PropTypes.node,
  afterInput: PropTypes.node,
  counter: PropTypes.bool,
  innerRef: PropTypes.func,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
  subTitle: PropTypes.node,
  setValidity: PropTypes.func,
  unsetValidity: PropTypes.func,
}

class TextField extends React.PureComponent {
  static propTypes = PROP_TYPES

  state = {
    error: void 0,
    isErrorVisible: false,
  }

  minlengthError = [MINLENGTH_ERROR, this.props.minlength]
  maxError = [MAX_ERROR, this.props.max]

  containerRef = createRef()

  componentDidMount() {
    this.props.setValidity(
      this.props.name,
      !this.props.error,
      this.containerRef,
      this.showError
    )
    this.validate()

    if (this.props.focused) {
      this.input.focus()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isValidating && this.props.isValidating) {
      this.props.setValidity(this.props.name, false)
    } else if (
      (prevProps.isValidating && !this.props.isValidating) ||
      prevProps.error !== this.props.error ||
      prevState.error !== this.state.error
    ) {
      this.props.setValidity(
        this.props.name,
        !(this.props.error || this.state.error)
      )
    }

    if (prevProps.value !== this.props.value) {
      this.validate()
    }

    if (prevProps.autosize && !this.props.autosize) {
      this.input.style.width = ''
    }
  }

  componentWillUnmount() {
    this.props.unsetValidity && this.props.unsetValidity(this.props.name)
  }

  validate() {
    const { type, max, minlength, required, name, onValid, onInvalid } =
      this.props
    const value = (this.props.value || '').trim()

    let error

    if (required) {
      if (minlength && value.length < minlength) {
        error = this.minlengthError
      } else if (!value) {
        error = MISSING_ERROR
      }
    }

    if (!error && type === 'number' && max && Number(value) > max) {
      error = this.maxError
    }

    this.setState({ error })

    if (!error) {
      onValid && onValid(name)
    } else {
      onInvalid && onInvalid(name)
    }
  }

  showError = () => {
    this.setState({
      isErrorVisible: true,
    })
  }

  handleBlur = (...args) => {
    this.setState({
      isErrorVisible: true,
    })

    this.props.onBlur && this.props.onBlur(...args)
  }

  handleFocus = (...args) => {
    this.setState({
      isErrorVisible: false,
    })

    this.props.onFocus && this.props.onFocus(...args)
  }

  handleChange = (event) => {
    if (this.props.type === 'number') {
      if (!event.target.validity.valid) {
        return
      }
    }

    if (this.props.autosize) {
      this.inputCopy.innerHTML = event.target.value
      // extra pixel fixes accidental clipping in browsers
      this.input.style.width = this.inputCopy.clientWidth + 1 + 'px'
    }

    this.props.onUpdate &&
      this.props.onUpdate({
        [this.props.name]: event.target.value,
      })
    this.props.onChange && this.props.onChange(event)
  }

  render() {
    const {
      variant = 'base',
      label,
      sublabel,
      password,
      errorsOverride,
      counter,
      maxlength,
      type,
      info,
      innerRef,
      autosize,
      placeholder,
      name,
      appendToInputContainer,
      afterInput,
      labelClassName,
      subTitle,
    } = this.props
    const inputProps = omit(this.props, Object.keys(PROP_TYPES))
    const value = this.props.value || ''
    const error = this.props.error || this.state.error
    const isErrorVisible =
      this.state.isErrorVisible || this.props.isErrorVisible

    return (
      <div
        className={classNames(css.container, {
          [css.containerAutosize]: autosize,
        })}
        ref={this.containerRef}
      >
        <FieldLabel
          label={label}
          sublabel={sublabel}
          name={name}
          info={info}
          className={labelClassName}
        />
        {subTitle && subTitle}
        <div className={css.input}>
          <input
            className={classNames(css[variant], {
              [css.isPassword]: password,
              [css.autosize]: autosize,
              [css.error]: error && isErrorVisible,
              [css.withCounter]: counter,
            })}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={this.handleChange}
            value={value}
            name={name}
            id={name}
            type={type}
            maxLength={maxlength}
            placeholder={autosize ? '' : placeholder}
            ref={(el) => {
              this.input = el
              innerRef && innerRef(el)
            }}
            autoComplete="off"
            {...inputProps}
          />

          {autosize && (
            <div
              className={classNames(css[variant], css.inputCopy)}
              ref={(el) => {
                this.inputCopy = el
              }}
            />
          )}

          {appendToInputContainer}

          {counter && (
            <FieldCounter
              variant="inside"
              maxlength={maxlength}
              value={value}
            />
          )}
        </div>

        {afterInput}

        <FieldError
          error={error}
          isErrorVisible={isErrorVisible}
          overrides={errorsOverride}
        />
      </div>
    )
  }
}

export default function TextFieldContainer(props) {
  return (
    <FormContextConsumer>
      {({ setValidity, unsetValidity }) => (
        <TextField
          {...props}
          setValidity={setValidity}
          unsetValidity={unsetValidity}
        />
      )}
    </FormContextConsumer>
  )
}
