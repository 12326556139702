import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'

import Alert from '../Alert'
import RefetchCurrentOnSubscriptionExpire from './RefetchCurrentOnSubscriptionExpire'

import { GET_ME, GET_USER_INVESTOR_PROFILE } from '../../constants/queries'
import { formatGraphQLError } from '../../helpers/errors'
import {
  fsIdentify,
  fsUnidentify,
  gtmIdentify,
  gtmUnidentify,
} from '../../helpers/tracking'
import { sentryIdentify, sentryUnidentify } from '../../helpers/logging'
import { chatraIdentify, chatraUnidentify } from '../../helpers/support'

const CurrentUserContext = React.createContext()

export const Provider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'))

  return (
    <Query
      query={GET_ME}
      skip={!token}
      notifyOnNetworkStatusChange
      onCompleted={(data) => {
        if (!data) return

        const { me } = data
        gtmIdentify(me.id)
        fsIdentify(me)
        sentryIdentify(me)
        chatraIdentify(me)
      }}
    >
      {({
        data: meData,
        loading: meLoading,
        error: meError,
        networkStatus: meNetworkStatus,
        refetch,
        client,
      }) => {
        if (meError) {
          console.log(meError)
        }

        const currentUser = !meLoading && meData ? meData.me : null
        const subscription = currentUser && currentUser.subscription
        const isInvestor = currentUser && currentUser.accountType === 'investor'

        return (
          <Query
            query={GET_USER_INVESTOR_PROFILE}
            skip={!isInvestor}
            notifyOnNetworkStatusChange // to rerender when loading from cache
          >
            {({
              data: investorProfileData,
              loading: investorProfileLoading,
              error: investorProfileError,
            }) => {
              if (investorProfileError) {
                return (
                  <Alert variant="error">
                    {formatGraphQLError(investorProfileError)}
                  </Alert>
                )
              }

              const incomplete =
                investorProfileData &&
                investorProfileData.getUserInvestorProfile &&
                !investorProfileData.getUserInvestorProfile.locations.length

              return (
                <>
                  {meError && (
                    <Alert variant="error">{formatGraphQLError(meError)}</Alert>
                  )}
                  <CurrentUserContext.Provider
                    value={{
                      currentUser: { ...currentUser, incomplete },
                      loading: meLoading || investorProfileLoading,
                      refetching: meNetworkStatus === 4,
                      token,
                      onRefetch: () => {
                        refetch()
                      },
                      onTokenChange: (token) => {
                        if (token) {
                          localStorage.setItem('token', token)
                        } else {
                          gtmUnidentify()
                          fsUnidentify()
                          sentryUnidentify()
                          chatraUnidentify()
                          localStorage.removeItem('token')
                          client.resetStore()
                        }
                        setToken(token)
                      },
                    }}
                  >
                    <RefetchCurrentOnSubscriptionExpire
                      subscription={subscription}
                      onRefetch={() => {
                        refetch()
                      }}
                    />

                    {children}
                  </CurrentUserContext.Provider>
                </>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

Provider.propTypes = {
  children: PropTypes.node,
}

export const Consumer = CurrentUserContext.Consumer

export default CurrentUserContext
