import React, { useEffect, useState, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-apollo'
import get from 'lodash.get'

import Layout from '../components/Layout'

import Alert from '../components/Alert'
import Button from '../components/Button'
import InvestorTour from '../components/InvestorTour'
import List from '../components/List'
import Loader from '../components/Loader'
import Modal from '../components/Modal'
import PitchtapeReview from '../components/PitchtapeReview'
import Text from '../components/Text'

import CurrentUserContext from '../components/CurrentUserContext'
import DevicesContext from '../components/DevicesContext'

import { formatGraphQLError } from '../helpers/errors'
import { usePitchtapeTracking } from '../helpers/hooks'
import { FEED_PATH, PROFILE_PATH } from '../constants/routes'
import { GET_FEED } from '../constants/queries'

const FeedScreen = ({ location }) => {
  const [actionResult, setActionResult] = useState()

  const {
    currentUser: { completedTourGuide, firstName },
  } = useContext(CurrentUserContext)
  const { mobile } = useContext(DevicesContext)

  const { data, loading, error, refetch, networkStatus, client } = useQuery(
    GET_FEED,
    {
      variables: { first: 1 },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  )

  const edges = get(data, 'getFeed.edges')
  const company = edges && edges.length && edges[0].node

  usePitchtapeTracking(company)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [company])

  const handleRate = useCallback(
    (rating) => {
      client.writeQuery({
        query: GET_FEED,
        variables: { first: 1 },
        data: {
          getFeed: {
            ...data.getFeed,
            edges: [
              {
                ...data.getFeed.edges[0],
                node: {
                  ...data.getFeed.edges[0].node,
                  givenOverallRating: rating,
                },
              },
            ],
          },
        },
      })
    },
    [data, client]
  )

  const renderContent = () => {
    if (loading || networkStatus === 4) {
      return (
        <Layout noFooter variant="fullHeight">
          <Loader variant="centered" />
        </Layout>
      )
    }

    if (error) {
      return (
        <Layout variant="dashboard">
          <Alert variant="error">{formatGraphQLError(error)}</Alert>
        </Layout>
      )
    }

    if (!company) {
      return (
        <Layout variant="dashboard">
          <Text variant="h1" tag="h1" offset="half-bottom">
            More startups are coming soon.
          </Text>

          <Text variant="large" tag="p" offset="double-bottom">
            Expand the investment criteria in your profile to see more startups
          </Text>

          <List variant="centeredActions">
            <List.Item style={{ maxWidth: 160 }}>
              <Button
                variant="iconOnTop"
                external
                href="http://pitchtape.com/blog"
                icon="blogBlue"
                iconMaxHeight={24}
              >
                Read our blog for tips on evaluating startups
              </Button>
            </List.Item>

            <List.Item style={{ maxWidth: 110 }}>
              <Button
                variant="iconOnTop"
                to={PROFILE_PATH}
                icon="pencilBlue"
                iconMaxHeight={24}
              >
                Update your investor profile
              </Button>
            </List.Item>
          </List>
        </Layout>
      )
    }

    return (
      <Layout background="white" noFooter>
        <PitchtapeReview
          key={company.id}
          company={company}
          forInvestor
          onRate={handleRate}
          onInvestorAction={(status) => {
            setActionResult({ company, status })
            refetch()
          }}
        />

        {!completedTourGuide && !mobile && <InvestorTour />}

        {get(location, 'state.investorProfileCreated') && (
          <Modal
            variant="welcome"
            title={
              <Text tag="h1" variant="h2" color="white">
                Welcome, {firstName}!
              </Text>
            }
            buttons={
              <Button to={FEED_PATH} variant="secondary">
                See Startups
              </Button>
            }
          >
            <div style={{ textAlign: 'center' }}>
              <Text tag="p" variant="large" weight="500" offset="single-bottom">
                Your investor profile is complete.
              </Text>

              <Text tag="p" variant="standardLarger">
                Based on your investment criteria, we have curated a selection
                of startups for you to check out in your feed.
              </Text>
            </div>
          </Modal>
        )}
      </Layout>
    )
  }

  return (
    <>
      {actionResult && (
        <Alert
          key={actionResult.company.id}
          variant="success"
          ttl={2000}
          noClose
          sticky
          centered
          onClose={() => setActionResult(null)}
        >
          You have{' '}
          {actionResult.status === 'saved'
            ? 'saved'
            : actionResult.status === 'connected'
            ? 'connected to'
            : 'passed on'}{' '}
          {actionResult.company.name}.
        </Alert>
      )}

      {renderContent()}
    </>
  )
}

FeedScreen.propTypes = {
  location: PropTypes.object,
}

export default FeedScreen
