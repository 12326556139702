import css from './DialogAnimated.module.sass'

import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import Dialog from './Dialog'

const DialogAnimated = forwardRef(
  ({ visible, animated, ...dialogProps }, ref) => {
    return (
      <CSSTransition
        in={visible}
        timeout={200}
        unmountOnExit
        classNames={{
          appear: css[`${animated}-appear`],
          appearActive: css[`${animated}-appear-active`],
          appearDone: css[`${animated}-done-appear`],
          enter: css[`${animated}-enter`],
          enterActive: css[`${animated}-enter-active`],
          enterDone: css[`${animated}-done-enter`],
          exit: css[`${animated}-exit`],
          exitActive: css[`${animated}-exit-active`],
          exitDone: css[`${animated}-done-exit`],
        }}
      >
        <Dialog ref={ref} {...dialogProps} />
      </CSSTransition>
    )
  }
)

DialogAnimated.displayName = 'DialogAnimated'

DialogAnimated.propTypes = {
  visible: PropTypes.bool,
  animated: PropTypes.string,
}

export default DialogAnimated
