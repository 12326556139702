import css from './Member.module.sass'

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { Mutation } from 'react-apollo'
import classNames from 'classnames'

import Button from '../../Button'
import Link from '../../Link'
import Icon from '../../Icon'
import Text from '../../Text'
import Alert from '../../Alert'
import ConfirmationModal from '../../ConfirmationModal'
import TeamMemberFormLinked from './TeamMemberFormLinked'
import MemberDragCell from './MemberDragCell'

import { removeProtocol } from '../../../helpers/strings'
import { formatGraphQLError } from '../../../helpers/errors'
import { GET_USER_COMPANY_MEMBERS } from '../../../constants/queries'

const DELETE_MEMBER = gql`
  mutation deleteCompanyMember($id: Int!) {
    deleteCompanyMember(input: { id: $id }) {
      id
    }
  }
`

const Member = ({ member, dragging, onDragStart }) => {
  const [editing, setEditing] = useState(false)

  if (editing) {
    return (
      <tr className={css.trForm}>
        <td className={css.tdForm} colSpan="4">
          <TeamMemberFormLinked
            member={member}
            onUpdateSuccess={() => setEditing(false)}
            onCancel={() => setEditing(false)}
          />
        </td>
      </tr>
    )
  }

  return (
    <Mutation
      mutation={DELETE_MEMBER}
      update={(
        cache,
        {
          data: {
            deleteCompanyMember: { id, errors },
          },
        }
      ) => {
        if (!errors) {
          const { company } = cache.readQuery({
            query: GET_USER_COMPANY_MEMBERS,
          })

          cache.writeQuery({
            query: GET_USER_COMPANY_MEMBERS,
            data: {
              company: {
                ...company,
                members: company.members.filter((m) => m.id !== id),
              },
            },
          })
        }
      }}
    >
      {(deleteCompanyMember, { error: deletingError, loading: deleting }) => {
        return (
          <>
            <tr
              className={classNames(css.tr, {
                [css.deleting]: deleting,
                [css.dragging]: dragging,
              })}
            >
              <MemberDragCell onDragStart={onDragStart} />

              <td className={css.tdName}>
                <Text weight="500" truncated>
                  {member.firstName} {member.lastName}, {member.title}
                </Text>
              </td>
              <td className={css.tdLink}>
                <Link variant="inherit" external href={member.websiteUrl}>
                  <Text truncated>{removeProtocol(member.websiteUrl)}</Text>
                </Link>
              </td>
              <td className={css.tdEdit}>
                <Link variant="expander" onClick={() => setEditing(true)}>
                  edit
                </Link>
              </td>
              <td className={css.tdX}>
                <ConfirmationModal
                  variant="delete"
                  content={`Remove ${member.firstName} ${member.lastName} from the team?`}
                  onConfirm={() => {
                    deleteCompanyMember({
                      variables: { id: member.id },
                    })
                  }}
                >
                  {({ showModal }) => (
                    <Button
                      variant="icon"
                      disabled={deleting}
                      onClick={() => showModal()}
                    >
                      <Icon name="xBlack" opacity="50" />
                    </Button>
                  )}
                </ConfirmationModal>
              </td>
            </tr>

            {deletingError && (
              <tr className={css.tr}>
                <td className={css.td} colSpan="4">
                  <Alert variant="error">
                    {formatGraphQLError(deletingError)}
                  </Alert>
                </td>
              </tr>
            )}
          </>
        )
      }}
    </Mutation>
  )
}

Member.propTypes = {
  member: PropTypes.object,
  dragging: PropTypes.bool,
  onDragStart: PropTypes.func,
}

export default Member
