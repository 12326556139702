import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import FounderConnections from '../components/FounderConnections'
import { Consumer as DevicesConsumer } from '../components/DevicesContext'

const ConnectionsScreen = ({ match }) => {
  const selectedConnectionId = match.params.connectionId

  return (
    <DevicesConsumer>
      {({ mobile }) => (
        <Layout
          noHeader={mobile && Boolean(selectedConnectionId)}
          variant="fullHeight"
          noFooter
          background="white"
        >
          <FounderConnections selectedConnectionId={selectedConnectionId} />
        </Layout>
      )}
    </DevicesConsumer>
  )
}

ConnectionsScreen.propTypes = {
  match: PropTypes.object,
}

export default ConnectionsScreen
