import css from './AverageViewTime.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'

import { formatSecondsAsTime } from '../../helpers/dates'

const AverageViewTime = ({ lastWeek, allTime }) => {
  if (!allTime) {
    return 'Your pitchtape has no views yet.'
  }

  const percentage = (lastWeek / (lastWeek + allTime)) * 100

  return (
    <div className={css.container}>
      <div className={css.bar}>
        <div className={css.progress} style={{ width: `${percentage}%` }} />
      </div>

      <div className={css.values}>
        <div>
          <Text color="blue-bg" variant="sectionTitleSmall">
            {formatSecondsAsTime(lastWeek)}
          </Text>
          <Text offset="quarter-top" block>
            Last 7 Days
          </Text>
        </div>

        <div>
          <Text color="yellow-bg" variant="sectionTitleSmall">
            {formatSecondsAsTime(allTime)}
          </Text>
          <Text offset="quarter-top" block>
            All Time
          </Text>
        </div>
      </div>
    </div>
  )
}

AverageViewTime.propTypes = {
  lastWeek: PropTypes.number,
  allTime: PropTypes.number,
}

export default AverageViewTime
