import css from './Dialog.module.sass'

import React, {
  forwardRef,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Loader from '../Loader'

import { getBounds, getCss } from '../../helpers/ui'

const MINIMUM_EDGE_OFFSET = 10

const Dialog = forwardRef(
  (
    {
      variant = 'base',
      containerRef,
      overlay,
      anchorSelector,
      offset,
      header,
      content,
      footer,
      loading,
      sizeByFooter,
      maxWidth,
      direction,
      onOverlayClick,
      onCenter = true,
    },
    _ref
  ) => {
    const [style, setStyle] = useState()
    const [arrowStyle, setArrowStyle] = useState()

    const contentRef = useRef()
    const footerRef = useRef()
    const arrowRef = useRef()
    const dialogRef = useRef()
    const ref = _ref || dialogRef

    const centered = !containerRef && !anchorSelector

    const getPopupPaddings = useCallback(() => {
      const [paddingLeft, paddingRight] = getCss(ref.current, [
        'padding-left',
        'padding-right',
      ])
      return parseInt(paddingLeft, 10) + parseInt(paddingRight, 10)
    }, [ref])

    const reposition = useCallback(() => {
      if (!contentRef.current) {
        return
      }

      const { width: contentWidth } = getBounds(contentRef.current)
      let popupOuterWidth =
        (!sizeByFooter ? contentWidth : footerRef.current.clientWidth) +
        getPopupPaddings()

      if (centered) {
        setStyle({
          width: Math.ceil(popupOuterWidth),
        })
      } else {
        const container = containerRef
          ? containerRef.current
          : document.querySelector(anchorSelector)

        const {
          top: triggerTop,
          left: triggerLeft,
          height: triggerHeight,
          width: triggerWidth,
        } = getBounds(container)

        const triggerCenter = triggerLeft + triggerWidth / 2

        const getLefts = () => {
          let offset = 0

          const halfArrowWidth = Math.ceil(arrowRef.current.clientWidth / 2)

          if (triggerCenter - popupOuterWidth / 2 < MINIMUM_EDGE_OFFSET) {
            if (triggerCenter > MINIMUM_EDGE_OFFSET + halfArrowWidth) {
              offset = MINIMUM_EDGE_OFFSET
            }

            return {
              popup: popupOuterWidth / 2 + offset,
              arrow: triggerCenter - offset,
            }
          } else if (
            triggerCenter + popupOuterWidth / 2 >
            window.innerWidth - MINIMUM_EDGE_OFFSET
          ) {
            if (
              triggerCenter <
              window.innerWidth - MINIMUM_EDGE_OFFSET - halfArrowWidth
            ) {
              offset = MINIMUM_EDGE_OFFSET
            }

            const popupLeft = window.innerWidth - offset - popupOuterWidth / 2

            return {
              popup: popupLeft,
              arrow: triggerCenter - popupLeft + popupOuterWidth / 2,
            }
          } else {
            return {
              popup: triggerCenter,
            }
          }
        }

        const lefts = getLefts()

        setStyle({
          ...(direction === 'up' && {
            top: triggerTop,
          }),
          ...((!direction || direction === 'down') && {
            top: triggerTop + triggerHeight,
          }),
          left: lefts.popup,
          width: Math.ceil(popupOuterWidth),
        })

        setArrowStyle({
          display: 'block',
          left: lefts.arrow,
        })
      }
    }, [
      anchorSelector,
      centered,
      containerRef,
      getPopupPaddings,
      sizeByFooter,
      direction,
    ])

    useLayoutEffect(() => {
      const resetAndReposition = () => {
        setStyle(null)
        setArrowStyle(null)

        setTimeout(reposition, 0)
      }

      resetAndReposition()

      window.addEventListener('resize', resetAndReposition)
      window.addEventListener('scroll', reposition)

      return () => {
        window.removeEventListener('resize', resetAndReposition)
        window.removeEventListener('scroll', reposition)
      }
    }, [content, reposition])

    return (
      <div>
        {overlay && <div className={css.overlay} onClick={onOverlayClick} />}

        {loading ? (
          <div className={css.loader}>
            <Loader />
          </div>
        ) : (
          <div
            className={classNames(
              css[variant],
              css[`direction-${direction}`],
              css[`offset-${offset}`],
              onCenter ? { [css.centered]: centered } : ''
            )}
            style={{
              ...style,
              maxWidth,
            }}
            ref={ref}
          >
            <i className={css.arrow} style={arrowStyle} ref={arrowRef} />

            {header}

            {typeof content === 'string' ? (
              <span ref={contentRef}>{content}</span>
            ) : (
              <div ref={contentRef}>{content}</div>
            )}

            {footer && (
              <div
                className={classNames(
                  css.footerContainer,
                  css[`footer-container-${variant}`]
                )}
              >
                <div className={css.footer} ref={footerRef}>
                  {footer}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
)

Dialog.displayName = 'Dialog'

Dialog.propTypes = {
  variant: PropTypes.string,
  offset: PropTypes.string,
  overlay: PropTypes.bool,
  anchorSelector: PropTypes.string,
  containerRef: PropTypes.object,
  header: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
  loading: PropTypes.bool,
  sizeByFooter: PropTypes.bool,
  maxWidth: PropTypes.number,
  direction: PropTypes.string,
  onOverlayClick: PropTypes.func,
  onCenter: PropTypes.bool,
}

export default Dialog
