import React from 'react'
import PropTypes from 'prop-types'

import {
  FormSection,
  FormRow,
  FormCol,
  CustomerCategoryDropdown,
  IndustryDropdown,
  StageDropdown,
  RegionDropdown,
} from '../Form'

const InvestorCriteria = ({
  // revenue, activeUsers, previousCapital, fundingSources,
  customerCategory,
  industries,
  stages,
  regions,
  onUpdate,
  onInvalid,
}) => (
  <FormSection
    heading="2. Investor Criteria"
    description="Tell us about your investment criteria so that we can match you with the right startups"
  >
    <FormRow offset="25">
      <FormCol>
        <CustomerCategoryDropdown
          label="Customer Category"
          name="customerCategory"
          {...customerCategory}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>

      <FormCol>
        <IndustryDropdown
          label="Industry"
          name="industries"
          {...industries}
          multiple
          emptyIsAll
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>
    </FormRow>

    <FormRow offset="25">
      <FormCol>
        <StageDropdown
          label="Stage"
          name="stages"
          {...stages}
          multiple
          emptyIsAll
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>

      <FormCol>
        <RegionDropdown
          label="Region"
          name="regions"
          {...regions}
          multiple
          emptyIsAll
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>
    </FormRow>

    {/* <FormRow offset='25'>
      <FormCol>
        <YesNoDropdown
          label='Active Users'
          name='activeUsers'
          {...activeUsers}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>

      <FormCol>
        <YesNoDropdown
          label='Previous Capital Raised'
          name='previousCapital'
          {...previousCapital}
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>
    </FormRow>

    <FormRow offset='25'>
      <YesNoDropdown
        label='Revenue (Last 6 Months)'
        name='revenue'
        {...revenue}
        onUpdate={onUpdate}
        onInvalid={onInvalid}
      />

      <FormCol size='half'>
        <FundingSourcesDropdown
          label='Funding Sources To Date'
          name='fundingSources'
          {...fundingSources}
          multiple
          onUpdate={onUpdate}
          onInvalid={onInvalid}
        />
      </FormCol>
    </FormRow> */}
  </FormSection>
)

InvestorCriteria.propTypes = {
  customerCategory: PropTypes.object,
  industries: PropTypes.object,
  stages: PropTypes.object,
  regions: PropTypes.object,
  // revenue: PropTypes.object,
  // activeUsers: PropTypes.object,
  // previousCapital: PropTypes.object,
  // fundingSources: PropTypes.object,
  onUpdate: PropTypes.func,
  onInvalid: PropTypes.func,
}

export default InvestorCriteria
