import css from './index.module.sass'

import React, { useContext } from 'react'
import classNames from 'classnames'

import Logo from '../Logo'
import UnauthNav from './UnauthNav'
import AuthNav from './AuthNav'

import CurrentUserContext from '../CurrentUserContext'
import { HEADER_CLASSNAME } from '../../constants/classnames'

const Header = () => {
  const {
    currentUser: { accountType },
    loading,
  } = useContext(CurrentUserContext)
  return (
    <header className={classNames(css.container, HEADER_CLASSNAME)}>
      <div className={css.floating}>
        <div className={css.logo}>
          <Logo />
        </div>

        {!loading && (!accountType ? <UnauthNav /> : <AuthNav />)}
      </div>
    </header>
  )
}

export default Header
