import css from './FormFooter.module.sass'

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const FormFooter = ({ offset, children }) => (
  <footer className={classNames(css.container, css[`offset-${offset}`])}>
    {children}
  </footer>
)

FormFooter.propTypes = {
  offset: PropTypes.string,
  children: PropTypes.node,
}

export default FormFooter
