import css from './UnauthNav.module.sass'

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter, matchPath } from 'react-router-dom'

import Button from '../Button'
import Text from '../Text'

import DevicesContext from '../DevicesContext'

import { HOME_PATH, SIGNIN_PATH, SIGNUP_PATH } from '../../constants/routes'
import { injectParams } from '../../helpers/routes'
import Link from '../Link'

const UnauthNav = ({ location }) => {
  const { mobile } = useContext(DevicesContext)

  return (
    <>
      {matchPath(location.pathname, { path: HOME_PATH, exact: true }) && (
        <ul className={css.rightMenu}>
          {!mobile && (
            <>
              <li className={css.rightMenuItem}>
                <Button
                  to={SIGNUP_PATH}
                  params={{ accountType: 'founder' }}
                  variant="outline"
                >
                  Join as founder
                </Button>
              </li>
              <li className={css.rightMenuItem}>
                <Button
                  to={SIGNUP_PATH}
                  params={{ accountType: 'investor' }}
                  variant="outline"
                >
                  Join as investor
                </Button>
              </li>
            </>
          )}

          <li className={css.rightMenuItem}>
            <Button to={SIGNIN_PATH} variant="primary">
              Log In
            </Button>
          </li>
        </ul>
      )}

      {matchPath(location.pathname, {
        path: injectParams(SIGNUP_PATH, { accountType: 'founder' }),
      }) && (
        <ul className={css.rightMenu}>
          {!mobile && (
            <li className={css.rightMenuText}>
              <Text variant="standardLarger" tag="p">
                Investors - Interested in finding your outlier?
              </Text>
            </li>
          )}
          <li className={css.rightMenuItem}>
            <Link
              to={SIGNUP_PATH}
              params={{ accountType: 'investor' }}
              variant="inherit"
            >
              Join as investor
            </Link>
          </li>
        </ul>
      )}

      {matchPath(location.pathname, {
        path: injectParams(SIGNUP_PATH, { accountType: 'investor' }),
      }) && (
        <ul className={css.rightMenu}>
          {!mobile && (
            <li className={css.rightMenuText}>
              <Text variant="standardLarger" tag="p">
                Founders - Interested in <b>meeting</b> more investors?
              </Text>
            </li>
          )}
          <li className={css.rightMenuItem}>
            <Link
              to={SIGNUP_PATH}
              params={{ accountType: 'founder' }}
              variant="inherit"
            >
              Join as founder
            </Link>
          </li>
        </ul>
      )}
    </>
  )
}

UnauthNav.propTypes = {
  location: PropTypes.object,
}

export default withRouter(UnauthNav)
