export function formatGraphQLError(error) {
  return error && error.graphQLErrors && error.graphQLErrors.length
    ? error.graphQLErrors.map((e) => e.message).join(', ')
    : error.message
}

export function isErrorCode(error, code) {
  return (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].code === code
  )
}
