import css from './SectionTitle.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Asterisk from './Asterisk'
import Text from './Text'

const SectionTitle = ({
  required,
  optional,
  title,
  nextToTitle,
  description,
  highlight,
  warning,
}) => {
  return (
    <div className={css.container}>
      <div>
        <Text tag="h1" variant="blockTitle" inline highlight={highlight}>
          {title} {required && <Asterisk />}
          {optional && (
            <Text italic weight="300">
              &nbsp;(Optional)
            </Text>
          )}
        </Text>{' '}
        {nextToTitle && <span className={css.nextToTitle}>{nextToTitle}</span>}
      </div>
      {warning && <div className={css.warning}>{warning}</div>}

      {description && (
        <Text tag="p" variant="light" italic offset="quarter-top">
          {description}
        </Text>
      )}
    </div>
  )
}

SectionTitle.propTypes = {
  required: PropTypes.bool,
  warning: PropTypes.node,
  optional: PropTypes.bool,
  title: PropTypes.node,
  nextToTitle: PropTypes.node,
  description: PropTypes.node,
  highlight: PropTypes.bool,
}

export default SectionTitle
