import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import Text from '../Text'

import Thumbnail from './Thumbnail'

const TapeButton = ({ active, videoData, className, children, onClick }) => {
  return (
    <Button
      className={className}
      variant="tape"
      active={active}
      icon={
        videoData ? (
          <Thumbnail video={videoData} />
        ) : (
          <Ionicon
            name="videocam"
            size="24"
            color={active ? 'red' : 'lightGray'}
          />
        )
      }
      onClick={onClick}
    >
      <Text variant="standardLarger" color={active ? 'red' : void 0}>
        {children}
      </Text>
    </Button>
  )
}

TapeButton.propTypes = {
  active: PropTypes.bool,
  videoData: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default TapeButton
