import css from './Section.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import Loader from '../Loader'

const Section = ({ title, loading, children }) => {
  return (
    <article className={css.container}>
      <Text
        tag="h1"
        variant="standardLarger"
        weight="500"
        offset="single-bottom"
      >
        {title}
      </Text>

      {loading ? <Loader /> : children}
    </article>
  )
}

Section.propTypes = {
  title: PropTypes.node,
  loading: PropTypes.bool,
  children: PropTypes.node,
}

export default Section
