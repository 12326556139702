import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'

import TextField from './TextField'

import { ZIP_CODE_REGEX } from '../../constants/forms'
import { ZIP_CODE_ERROR } from '../../constants/errors'

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
}

class ZipCodeField extends React.PureComponent {
  static propTypes = PROP_TYPES

  state = {
    error: null,
  }

  handleValid = () => {
    const { name, value, onValid, onInvalid } = this.props

    if (value && !ZIP_CODE_REGEX.test(value)) {
      this.setState({ error: ZIP_CODE_ERROR })
      return onInvalid && onInvalid(name)
    }

    this.setState({ error: null })
    onValid && onValid(name)
  }

  render() {
    const textFieldProps = omit(this.props, Object.keys(PROP_TYPES))

    return (
      <TextField
        {...textFieldProps}
        name={this.props.name}
        value={this.props.value}
        error={this.props.error || this.state.error}
        onValid={this.handleValid}
        onInvalid={this.props.onInvalid}
      />
    )
  }
}

export default ZipCodeField
