import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Layout from '../components/Layout'
import Text from '../components/Text'
import Button from '../components/Button'
import Loader from '../components/Loader'
import { Consumer as CurrentUserConsumer } from '../components/CurrentUserContext'
import List from '../components/List'

import { SCHEDULE_1_ON_1_URL } from '../constants/urls'
import { PLAN_NAMES } from '../constants/plans'
import { CREATE_PATH, PITCHTAPE_PATH } from '../constants/routes'

const SubscriptionUpgradedScreen = ({ location }) => {
  if (!location.state || !location.state.upgraded) {
    return <Redirect to={PITCHTAPE_PATH} />
  }

  return (
    <CurrentUserConsumer>
      {({
        currentUser: {
          subscription: { plan },
        },
        refetching,
      }) => (
        <Layout variant="dashboard">
          {refetching ? (
            <Loader />
          ) : (
            <>
              <Text tag="h1" variant="h2" offset="half-bottom">
                You have upgraded to the {PLAN_NAMES[plan]} plan!
              </Text>

              <Text
                tag="p"
                variant="large"
                offset="double-bottom"
                style={{ maxWidth: '620px' }}
              >
                We are glad to have you onboard. You can now access premium
                benefits and contact investors who want to connect with you.
              </Text>

              {plan === 'scale' && (
                <Text
                  tag="p"
                  variant="large"
                  offset="double-bottom"
                  style={{ maxWidth: '620px' }}
                >
                  To schedule a 1-on-1 session, click on the schedule button
                  below.
                </Text>
              )}

              <List variant="horizontalOnDesktop" gap="20">
                <List.Item>
                  <Button variant="outline" block to={CREATE_PATH}>
                    Review pitchtape
                  </Button>
                </List.Item>

                {plan === 'scale' && (
                  <List.Item>
                    <Button
                      variant="outline"
                      block
                      external
                      href={SCHEDULE_1_ON_1_URL}
                    >
                      Schedule 1-on-1
                    </Button>
                  </List.Item>
                )}
              </List>
            </>
          )}
        </Layout>
      )}
    </CurrentUserConsumer>
  )
}

SubscriptionUpgradedScreen.propTypes = {
  location: PropTypes.object,
}

export default SubscriptionUpgradedScreen
