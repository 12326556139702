import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import VideosRecorder from '../components/VideosRecorder'

import { CREATE_PATH, CREATE_VIDEOS_PATH } from '../constants/routes'

const PracticeScreen = ({ history }) => {
  const handleBeforeSave = useCallback(() => {
    history.push(CREATE_VIDEOS_PATH)
  }, [history])

  const topics = [
    {
      id: 0,
      subject: 'Intro',
      question:
        'What is your name, title, company, and background/relevant experience?',
      teleprompt:
        "Example script: I'm Nick, the Founder and CEO of Pitchtape. I’ve spent most of my career " +
        'advising hundreds of startups and investors as a lawyer in New York City, most recently at a boutique ' +
        'firm that I founded. I’ve always had a special passion for entrepreneurship, and through my experience ' +
        'in the industry formed a thesis around video tech that ultimately led me to start Pitchtape.',
    },
  ]

  return (
    <Layout noHeader noFooter>
      <VideosRecorder
        topics={topics}
        topic={topics[0]}
        practice
        exitUrl={CREATE_PATH}
        onBeforeSave={handleBeforeSave}
      />
    </Layout>
  )
}

PracticeScreen.propTypes = {
  history: PropTypes.object,
}

export default PracticeScreen
