/* eslint-disable max-len */

export const UNLOAD_TEXT =
  'Leaving the page while the changes are being saved may result in data loss.'

export const PASSWORD_MIN_LENGTH = 6

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
export const ZIP_CODE_REGEX = /^$|[0-9A-Za-z\s\-]+$/ // eslint-disable-line no-useless-escape

export const REQUIRED_PITCHTAPE_FIELDS = [
  'name',
  'locations',
  'description',
  'customerCategory',
  'industries',
  'stage',
]

export const OPTIONAL_PITCHTAPE_FIELDS = [
  'members',
  'yearFounded',
  'website',
  'regions',
  'highlights',
]

export const REQUIRED_INVESTOR_PROFILE_FIELDS = [
  'locations',
  'investorTypes',
  'accreditedInvestor',
  'numberOfInvestments',
]

export const OPTIONAL_INVESTOR_PROFILE_FIELDS = [
  'title',
  'profileUrl',
  'name',
  'organizationSize',
  'website',
  'customerCategory',
  'industries',
  'stages',
  'regions',
]
