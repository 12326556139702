import css from './index.module.sass'

import React, { useEffect, useRef, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Loader from '../Loader'
import Alert from '../Alert'
import Connection from './Connection'

import { formatGraphQLError } from '../../helpers/errors'

const ConnectionList = ({
  connections,
  loading,
  error,
  selectedId,
  noConnectionsMessage,
  onFetchMore,
}) => {
  const [fetchingMore, setFetchingMore] = useState()

  const listRef = useRef()
  const fetchingMoreRef = useRef()

  const handleFetchMore = useCallback(() => {
    if (onFetchMore && !fetchingMoreRef.current) {
      fetchingMoreRef.current = true
      setFetchingMore(true)

      return onFetchMore().finally(() => {
        fetchingMoreRef.current = false
        setFetchingMore(false)
      })
    }
  }, [onFetchMore])

  useEffect(() => {
    const el = listRef.current

    if (!el || !onFetchMore) {
      return
    }

    const handleScroll = () => {
      if (
        el.scrollHeight - el.clientHeight / 2 <=
        el.scrollTop + el.clientHeight
      ) {
        handleFetchMore()
      }
    }

    el.addEventListener('scroll', handleScroll)

    return () => {
      el.removeEventListener('scroll', handleScroll)
    }
  }, [onFetchMore, handleFetchMore])

  useEffect(() => {
    if (
      connections &&
      listRef.current.clientHeight > listRef.current.scrollHeight
    ) {
      handleFetchMore()
    }
  }, [handleFetchMore, connections])

  const renderContent = () => {
    if (loading || !connections) {
      return <Loader variant="centered" />
    }

    if (error) {
      return <Alert variant="error">{formatGraphQLError(error)}</Alert>
    }

    if (!connections.length) {
      return (
        <div className={css.empty}>
          {noConnectionsMessage || 'Connections will be listed here'}
        </div>
      )
    }

    return (
      <>
        {connections.map((connection) => (
          <div className={css.item} key={connection.id}>
            <Connection
              {...connection}
              selected={selectedId === String(connection.id)}
            />
          </div>
        ))}

        {fetchingMore && 'Loading more...'}
      </>
    )
  }

  return (
    <div className={css.list} ref={listRef}>
      {renderContent()}
    </div>
  )
}

ConnectionList.propTypes = {
  connections: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  selectedId: PropTypes.string,
  noConnectionsMessage: PropTypes.node,
  onFetchMore: PropTypes.func,
}

export default ConnectionList
