import css from './Layout.module.sass'

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import SimpleFooter from './SimpleFooter'

import { LAYOUT_BODY_CLASSNAME } from '../constants/classnames'

const Layout = ({
  variant = 'base',
  noHeader,
  noFooter,
  sidebar,
  background,
  children,
}) => {
  useEffect(() => {
    if (!background) {
      return
    }

    document.body.classList.add(background)

    return () => {
      document.body.classList.remove(background)
    }
  }, [background])

  return (
    <div className={css.container}>
      {!noHeader && <Header />}

      <div className={`${css[variant]} ${LAYOUT_BODY_CLASSNAME}`}>
        <div className={css.inner}>
          {sidebar && <div className={css.sidebar}>{sidebar}</div>}

          <div className={css.content}>{children}</div>
        </div>
      </div>

      {!noFooter && <SimpleFooter />}
    </div>
  )
}

Layout.propTypes = {
  variant: PropTypes.string,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  sidebar: PropTypes.node,
  background: PropTypes.string,
  children: PropTypes.node,
}

export default Layout
