import React from 'react'
import PropTypes from 'prop-types'

import Button from '../components/Button'
import Loader from '../components/Loader'
import { Consumer as CurrentUserConsumer } from '../components/CurrentUserContext'

import {
  ACTIVE_STATUS,
  START_PLAN_NAME,
  CANCELED_STATUS,
} from '../constants/plans'

const SubscribeButton = ({
  plan,
  chosenPlan,
  description,
  amount,
  loading,
  disabled,
  onUncancel,
  onCancel,
  onSubscribe,
}) => (
  <CurrentUserConsumer>
    {({ currentUser: { subscription }, refetching: refetchingCurrentUser }) => {
      const isCurrentPlan = subscription.plan === plan
      const isDisabled = disabled || refetchingCurrentUser || loading

      if (chosenPlan === plan && isDisabled) {
        return <Loader offset="quarter" />
      }

      if (
        isCurrentPlan &&
        subscription.status === ACTIVE_STATUS &&
        !subscription.cancelAtPeriodEnd
      ) {
        return (
          <Button
            variant="outline"
            disabled={isDisabled}
            onClick={() => {
              onCancel(plan)
            }}
          >
            Cancel
          </Button>
        )
      }

      return (
        <Button
          variant="primary"
          disabled={isDisabled}
          onClick={() => {
            if (
              subscription.plan === START_PLAN_NAME ||
              subscription.status === CANCELED_STATUS
            ) {
              onSubscribe(description, amount > 0 ? amount : 0, plan)
            } else if (subscription.status === ACTIVE_STATUS) {
              if (subscription.cancelAtPeriodEnd && isCurrentPlan) {
                onUncancel(plan)
              } else {
                throw new Error('Something went wrong')
              }
            } else {
              throw new Error('Something went wrong')
            }
          }}
        >
          Upgrade
        </Button>
      )
    }}
  </CurrentUserConsumer>
)

SubscribeButton.propTypes = {
  plan: PropTypes.string,
  chosenPlan: PropTypes.string,
  description: PropTypes.string,
  amount: PropTypes.number,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onUncancel: PropTypes.func,
  onCancel: PropTypes.func,
  onSubscribe: PropTypes.func,
  onUpgrade: PropTypes.func,
  onDowngrade: PropTypes.func,
}

export default SubscribeButton
