import css from './Alert.module.sass'

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Text from './Text'
import Button from './Button'
import Icon from './Icon'

class Alert extends PureComponent {
  static propTypes = {
    variant: PropTypes.oneOf(['error', 'success', 'upgrade']),
    offset: PropTypes.string,
    noClose: PropTypes.bool,
    sticky: PropTypes.bool,
    ttl: PropTypes.number,
    centered: PropTypes.bool,
    children: PropTypes.node,
    onClose: PropTypes.func,
    textColor: PropTypes.oneOf(['white', 'black']),
  }

  state = {
    children: void 0,
    closed: false,
  }

  static getDerivedStateFromProps(props, state) {
    if (state.children !== props.children) {
      return {
        children: props.children,
        closed: false,
      }
    }

    return null
  }

  componentDidMount() {
    const { ttl } = this.props

    if (ttl) {
      this.ttlTimeout = setTimeout(this.handleClose, ttl)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.ttlTimeout)
  }

  handleClose = () => {
    this.setState({
      closed: true,
    })

    this.props.onClose && this.props.onClose()
  }

  render() {
    const { closed } = this.state
    const { children, variant, offset, noClose, sticky, centered, textColor } =
      this.props

    if (!children || closed) {
      return null
    }
    const renderButton = () => {
      if (textColor === 'black') {
        return (
          <Button variant="iconBlack" onClick={this.handleClose}>
            <Icon name="xBlack" />
          </Button>
        )
      } else {
        return (
          <Button variant="iconWhite" onClick={this.handleClose}>
            <Icon name="xWhite" />
          </Button>
        )
      }
    }

    return (
      <div
        className={classNames(css[variant], css[`offset-${offset}`], {
          [css.sticky]: sticky,
          [css.centered]: centered,
        })}
      >
        <Text color={textColor}>{children}</Text>

        {!noClose && <div className={css.btnClose}>{renderButton()}</div>}
      </div>
    )
  }
}

Alert.defaultProps = {
  textColor: 'white',
}
export default Alert
