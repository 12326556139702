import css from './ScheduleBar.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import { Consumer as CurrentUserConsumer } from '../components/CurrentUserContext'

import { isSubscriptionActive } from '../helpers/subscription'
import { SCALE_PLAN_NAME } from '../constants/plans'
import { SCHEDULE_1_ON_1_URL } from '../constants/urls'

const ScheduleBar = ({ variant = 'base' }) => {
  return (
    <CurrentUserConsumer>
      {({ currentUser: { subscription } }) =>
        isSubscriptionActive(subscription) &&
        subscription.plan === SCALE_PLAN_NAME ? (
          <div className={css[variant]}>
            <Button variant="outline" external href={SCHEDULE_1_ON_1_URL}>
              Schedule 1-on-1
            </Button>
          </div>
        ) : null
      }
    </CurrentUserConsumer>
  )
}

ScheduleBar.propTypes = {
  variant: PropTypes.string,
}

export default ScheduleBar
