import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Alert from '../../Alert'
import Button from '../../Button'
import Ionicon from '../../Ionicon'

import { useUploadCompanyVideoSegment } from '../../../helpers/hooks'
import { formatGraphQLError } from '../../../helpers/errors'

const Footer = ({ topic, currentPage, onCancel }) => {
  const [uploadCompanyVideoSegment, { error: savingError, loading: saving }] =
    useUploadCompanyVideoSegment(topic.id)

  const handleAddClick = useCallback(() => {
    uploadCompanyVideoSegment({
      variables: {
        input: {
          topic: topic.id,
          pitchdeckPage: currentPage,
        },
      },
    })
  }, [currentPage, topic, uploadCompanyVideoSegment])

  const handleRemoveClick = useCallback(() => {
    uploadCompanyVideoSegment({
      variables: {
        input: {
          topic: topic.id,
          pitchdeckPage: null,
        },
      },
    })
  }, [topic, uploadCompanyVideoSegment])

  const hasSelectedPage = topic.video.pitchdeckPage != null

  return (
    <>
      {savingError && (
        <Alert variant="error" offset="double">
          {formatGraphQLError(savingError)}
        </Alert>
      )}

      <Button variant="outline" onClick={onCancel}>
        Cancel
      </Button>

      {hasSelectedPage && (
        <Button
          variant="outlineRed"
          offset="single-left"
          icon={
            !hasSelectedPage && <Ionicon name="add" color="white" size="24" />
          }
          iconOffset="half"
          disabled={saving}
          onClick={handleRemoveClick}
        >
          Remove slide
        </Button>
      )}

      <Button
        variant="primary"
        offset="single-left"
        icon={
          !hasSelectedPage && <Ionicon name="add" color="white" size="24" />
        }
        iconOffset="half"
        disabled={saving || topic.video.pitchdeckPage === currentPage}
        onClick={handleAddClick}
      >
        {hasSelectedPage ? 'Replace' : 'Add'} slide
      </Button>
    </>
  )
}

Footer.propTypes = {
  topic: PropTypes.object,
  currentPage: PropTypes.number,
  onCancel: PropTypes.func,
}

export default Footer
