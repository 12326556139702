import css from './LandingScreen.module.sass'

import React from 'react'

import Layout from '../components/Layout'
import Text from '../components/Text'
import Image from '../components/Image'
import { Consumer as DevicesConsumer } from '../components/DevicesContext'
import { Redirect } from 'react-router-dom'
import { LANDING_PAGE_ENABLED } from '../constants/enterpriseConfig'
import { SIGNIN_PATH } from '../constants/routes'

const LandingScreen = () => {
  if (!LANDING_PAGE_ENABLED) {
    return <Redirect to={SIGNIN_PATH} />
  }
  return (
    <Layout variant="landing">
      <aside className={css.hero}>
        <div className={css.heroInner}>
          <div className={css.heroText}>
            <DevicesConsumer>
              {({ mobile }) => (
                <Text
                  tag="h1"
                  variant={mobile ? 'h3' : 'h2'}
                  offset="half-bottom"
                >
                  Next gen fundraising{' '}
                  <Text variant={null} nowrap>
                    for startups
                  </Text>
                  .
                </Text>
              )}
            </DevicesConsumer>

            <Text tag="p" variant="large">
              A dynamic video pitch platform connecting startup founders and
              venture investors.
            </Text>
          </div>

          <div className={css.heroImage}>
            <Image fileName="phone.webp" media="(min-width: 768px)" />
          </div>
        </div>
      </aside>

      <article className={css.article}>
        <Text tag="h1" variant="h1" offset="single-bottom">
          What is Pitchtape
        </Text>

        <Text tag="p" variant="large" offset="single-bottom">
          Pitchtape is a digital platform for startup founders to create and
          share a video pitch, and for venture investors to discover and connect
          with the next generation of innovators.
        </Text>
        <Text tag="p" variant="large" offset="single-bottom">
          Through our platform, founders create what we call a “pitchtape” - a
          dynamic online pitch profile consisting of three 60-second videos
          telling investors what they’re doing, who they are, and why they’re
          doing it, along with short-form answers to standard pitch deck topics.
        </Text>
        <Text tag="p" variant="large" offset="single-bottom">
          Each pitchtape is then presented to our network of investors in a
          private web and mobile feed, curated for each investor based on their
          selected investment criteria. Investors can simply click a button if
          they like a pitch, which will notify the founders and give both sides
          the chance to connect to advance the fundraising process.
        </Text>
      </article>
    </Layout>
  )
}

export default LandingScreen
