import {
  MILLISECONDS_IN_A_DAY,
  MILLISECONDS_IN_AN_HOUR,
  MILLISECONDS_IN_A_MINUTE,
} from '../constants/dates'
import { Duration } from 'luxon'

export function formatDate(_date) {
  const date = new Date(_date)
  const year = String(date.getFullYear()).slice(2)
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${month}/${day}/${year}`
}

export function formatDateTime(_date, overrides) {
  const date = new Date(_date)

  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    ...overrides,
  })
}

export function formatSecondsAsTime(totalSeconds) {
  return Duration.fromObject({ seconds: Math.round(totalSeconds) }).toFormat(
    'm:ss'
  )
}

export function daysLeft(_date) {
  const current = new Date()
  const date = new Date(_date)
  return Math.floor((date - current) / MILLISECONDS_IN_A_DAY)
}

export function timeLeft(_date) {
  const current = new Date()
  const date = new Date(_date)
  const diff = date - current

  if (diff < 0) {
    return ''
  }

  if (diff < MILLISECONDS_IN_A_MINUTE) {
    return 'less than a minute left'
  }

  if (diff < MILLISECONDS_IN_AN_HOUR) {
    const minutes = Math.floor((date - current) / MILLISECONDS_IN_A_MINUTE)
    return `${minutes} minute${minutes !== 1 ? 's' : ''} left`
  }

  if (diff < MILLISECONDS_IN_A_DAY) {
    const hours = Math.floor((date - current) / MILLISECONDS_IN_AN_HOUR)
    return `${hours} hour${hours !== 1 ? 's' : ''} left`
  }

  return Math.floor((date - current) / MILLISECONDS_IN_A_DAY) + ' days left'
}

export function hasExpired(_date) {
  const current = new Date()
  const date = new Date(_date)
  return date <= current
}

export function timetokenToDate(timetoken) {
  return new Date(Number(timetoken.slice(0, -4)))
}

export function startOfYear(_date) {
  const date = startOfDay(_date)

  date.setMonth(0)
  date.setDate(0)

  return date
}

export function startOfDay(_date) {
  const date = _date ? new Date(_date) : new Date()

  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)

  return date
}

export function formatRelativeDateFromTimetoken(timetoken) {
  const startOfToday = startOfDay()
  const startOfYesterday = startOfToday - MILLISECONDS_IN_A_DAY
  const startOfCurrentYear = startOfYear()

  const date = timetokenToDate(timetoken)

  if (date > startOfToday) {
    return 'Today'
  } else if (date > startOfYesterday) {
    return 'Yesterday'
  } else if (date > startOfCurrentYear) {
    return date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
    })
  } else {
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }
}

export function areSameDayTimetokens(timetoken1, timetoken2) {
  return (
    Number(startOfDay(timetokenToDate(timetoken1))) ===
    Number(startOfDay(timetokenToDate(timetoken2)))
  )
}

export function formatMilliSecondsAsTime(seconds) {
  let duration = Duration.fromObject({ seconds }).toFormat('hh:mm:ss.SS')
  const durationArray = duration.split('.')
  let milliseconds = durationArray[1]
  const millisecondsSize = 3
  const number = '0'
  if (milliseconds.length < millisecondsSize) {
    milliseconds =
      milliseconds + number.repeat(millisecondsSize - milliseconds.length)
    duration = durationArray[0] + '.' + milliseconds
  }
  return duration
}
