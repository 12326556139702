import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'

import Copier from './Copier'

import { gtmTrack } from '../helpers/tracking'
import { toWithParams } from '../helpers/routes'
import { isCompanyShareable } from '../helpers/companies'
import { STARTUP_PATH } from '../constants/routes'
import { GET_USER_COMPANY } from '../constants/queries'
import { GTM_PITCHTAPE_URL_COPIED } from '../constants/gtm'

const CompanyUrlCopier = ({ children }) => (
  <Query query={GET_USER_COMPANY}>
    {({ data }) => {
      const disabled =
        !data || !data.company || !isCompanyShareable(data.company)

      return (
        <Copier
          value={
            disabled
              ? ''
              : window.location.origin +
                toWithParams(STARTUP_PATH, { companyId: data.company.id })
          }
          onCopy={() => {
            gtmTrack(GTM_PITCHTAPE_URL_COPIED, {
              pitchtape: { id: data.company.id },
            })
          }}
        >
          {React.cloneElement(children, { disabled })}
        </Copier>
      )
    }}
  </Query>
)

CompanyUrlCopier.propTypes = {
  children: PropTypes.node,
}

export default CompanyUrlCopier
