import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'
import { gql } from 'graphql-tag'
import { ApolloConsumer } from 'react-apollo'

import TextField from './TextField'

import { EMAIL_REGEX } from '../../constants/forms'
import {
  EMAIL_ERROR,
  EMAIL_EXISTS_ERROR,
  EMAIL_NOT_EXISTS_ERROR,
} from '../../constants/errors'

const GET_EMAIL_AVAILABILITY = gql`
  query emailAvailability($email: String!) {
    emailAvailable: emailAvailability(email: $email)
  }
`

// making sure accepted props won't get cleared by some optimizer
const PROP_TYPES = {
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isErrorVisible: PropTypes.bool,
  shouldNotExist: PropTypes.bool,
  shouldExist: PropTypes.bool,
  onInvalid: PropTypes.func,
  onValid: PropTypes.func,
}

class EmailField extends React.PureComponent {
  static propTypes = PROP_TYPES

  state = {
    error: void 0,
    isErrorVisible: false,
    isValidating: false,
  }

  checkExistence(client) {
    const { shouldExist, shouldNotExist, value: email } = this.props

    this.setError()
    this.setState({ isValidating: true })

    this.emailExistTimeout = setTimeout(() => {
      client
        .query({
          query: GET_EMAIL_AVAILABILITY,
          variables: {
            email,
          },
        })
        .then(({ data: { emailAvailable } }) => {
          if (this.props.value !== email) {
            // ignore the response if the value changed since it started
            return
          }

          this.setState({ isValidating: false })

          if (emailAvailable && shouldExist) {
            this.setError(EMAIL_NOT_EXISTS_ERROR, true)
          } else if (!emailAvailable && shouldNotExist) {
            this.setError(EMAIL_EXISTS_ERROR, true)
          } else {
            this.setError()
          }
        })
        .catch(() => {
          // TODO handle failed requests
          this.setState({ isValidating: false })
        })
    }, 700)
  }

  setError(error, isErrorVisible) {
    this.setState({
      error,
      isErrorVisible,
    })

    if (error) {
      this.props.onInvalid && this.props.onInvalid()
    } else {
      this.props.onValid && this.props.onValid()
    }
  }

  handleValid = (client) => {
    const { value, shouldNotExist, shouldExist } = this.props

    clearTimeout(this.emailExistTimeout)

    if (value) {
      if (!EMAIL_REGEX.test(value)) {
        return this.setError(EMAIL_ERROR)
      }

      if (shouldNotExist || shouldExist) {
        return this.checkExistence(client)
      }
    }

    this.setError()
  }

  handleInvalid = () => {
    clearTimeout(this.emailExistTimeout)
    this.setState({ isValidating: false })

    this.props.onInvalid && this.props.onInvalid()
  }

  render() {
    const textFieldProps = omit(this.props, Object.keys(PROP_TYPES))

    return (
      <ApolloConsumer>
        {(client) => (
          <TextField
            {...textFieldProps}
            isValidating={this.state.isValidating}
            value={this.props.value}
            onValid={() => this.handleValid(client)}
            onInvalid={this.handleInvalid}
            error={this.props.error || this.state.error}
            isErrorVisible={
              this.props.isErrorVisible || this.state.isErrorVisible
            }
            type="email"
          />
        )}
      </ApolloConsumer>
    )
  }
}

export default EmailField
