import css from './Footer.module.sass'

import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import List from '../List'
import Link from '../Link'
import Ionicon from '../Ionicon'

const Footer = forwardRef(
  ({ noDeck, noHighlights, noTeam, activeIndex, onSectionSelect }, ref) => {
    const [reducedGap, setReducedGap] = useState()

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 1000) {
          if (!reducedGap) {
            setReducedGap(true)
          }
        } else if (reducedGap) {
          setReducedGap(false)
        }
      }

      if (reducedGap === void 0) {
        handleResize()
      }

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [reducedGap])

    return (
      <div className={css.container} ref={ref}>
        <div className={css.floating}>
          <List variant="horizontal" gap={reducedGap ? '20' : void 0}>
            <List.Item>
              <Link
                variant="pitchtapeFooter"
                active={activeIndex === 0}
                onClick={onSectionSelect.bind(null, 0)}
              >
                <Ionicon
                  name={
                    activeIndex === 0
                      ? 'video_square_white'
                      : 'video_square_black'
                  }
                  size="24"
                  offset="right-10"
                  color="deepBlue"
                />
                VIDEO
              </Link>
            </List.Item>

            {!noDeck && (
              <List.Item>
                <Link
                  variant="pitchtapeFooter"
                  active={activeIndex === 1}
                  onClick={onSectionSelect.bind(null, 1)}
                >
                  <Ionicon
                    name={activeIndex === 1 ? 'deck_white' : 'deck_black'}
                    size="24"
                    offset="right-10"
                    color="deepBlue"
                  />
                  DECK
                </Link>
              </List.Item>
            )}

            {!noHighlights && (
              <List.Item>
                <Link
                  variant="pitchtapeFooter"
                  active={activeIndex === 2}
                  onClick={onSectionSelect.bind(null, 2)}
                >
                  <Ionicon
                    name={
                      activeIndex === 2
                        ? 'highlights_white'
                        : 'highlights_black'
                    }
                    size="24"
                    offset="right-10"
                    color="deepBlue"
                  />
                  HIGHLIGHTS
                </Link>
              </List.Item>
            )}

            {!noTeam && (
              <List.Item>
                <Link
                  variant="pitchtapeFooter"
                  active={activeIndex === 3}
                  onClick={onSectionSelect.bind(null, 3)}
                >
                  <Ionicon
                    name={activeIndex === 3 ? 'team_white' : 'team_black'}
                    size="24"
                    offset="right-10"
                    color="deepBlue"
                  />
                  TEAM
                </Link>
              </List.Item>
            )}
          </List>
        </div>
      </div>
    )
  }
)

Footer.displayName = 'Footer'

Footer.propTypes = {
  noDeck: PropTypes.bool,
  noHighlights: PropTypes.bool,
  noTeam: PropTypes.bool,
  activeIndex: PropTypes.number,
  onSectionSelect: PropTypes.func,
}

export default Footer
