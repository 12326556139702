import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'

import { Provider as CurrentUserProvider } from './components/CurrentUserContext'
import { Provider as DevicesProvider } from './components/DevicesContext'
import { Provider as TourTooltipProvider } from './components/TourTooltipContext'
import RootScreen from './screens/RootScreen'

import client from './apollo'

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <CurrentUserProvider>
          <DevicesProvider>
            <TourTooltipProvider>
              <Router>
                <Route component={RootScreen} />
              </Router>
            </TourTooltipProvider>
          </DevicesProvider>
        </CurrentUserProvider>
      </ApolloProvider>
    )
  }
}

export default App
