import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { graphql } from 'react-apollo'

import Ionicon from '../Ionicon'
import Dropdown from './Dropdown'

const GET_CUSTOMER_CATEGORIES = gql`
  query getCustomerCategories {
    categories: getCustomerCategories {
      id
      name
    }
  }
`

const CustomerCategoryDropdown = ({ data, ...dropdownProps }) => {
  const options = useMemo(() => {
    return data.categories
      ? [
          { value: { id: '' }, label: '\u00A0' },
          ...data.categories.map((x) => ({ value: x, label: x.name })),
        ]
      : []
  }, [data.categories])

  return (
    <Dropdown
      options={options}
      valueIdKey="id"
      icon={<Ionicon name="briefcase" color="deepBlue" />}
      renderLabel={(value) => value.name}
      {...dropdownProps}
    />
  )
}

CustomerCategoryDropdown.propTypes = {
  data: PropTypes.object,
}

export default graphql(GET_CUSTOMER_CATEGORIES)(CustomerCategoryDropdown)
