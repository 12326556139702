import css from './Coupon.module.sass'

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { Query } from 'react-apollo'

import Button from './Button'
import Modal from './Modal'
import Loader from './Loader'
import Text from './Text'
import Icon from './Icon'
import ErrorModal from './ErrorModal'
import { Form, TextField } from './Form'

import { isErrorCode } from '../helpers/errors'
import { COUPON_DOES_NOT_EXIST_CODE } from '../constants/codes'

const GET_COUPON = gql`
  query getCoupon($coupon: String) {
    getCoupon(coupon: $coupon) {
      id
      name
      amountOff
      currency
      valid
      metadata {
        allowedPlans
      }
    }
  }
`

const Coupon = ({ onUpdate }) => {
  const [coupon, setCoupon] = useState('')
  const [applied, setApplied] = useState()
  const [error, setError] = useState()

  const handleChange = useCallback((event) => {
    setCoupon(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()

      if (coupon.length) {
        setApplied(true)
      }
    },
    [coupon]
  )

  const setInvalidCouponError = () => {
    setError('No such coupon: ' + coupon)
  }

  return (
    <div className={css.container}>
      {error && (
        <Modal
          variant="error"
          onClosed={() => {
            setError(null)
          }}
        >
          {error}
        </Modal>
      )}

      {applied ? (
        <Query
          query={GET_COUPON}
          variables={{ coupon }}
          notifyOnNetworkStatusChange // to trigger onCompleted when loading from cache
          onCompleted={({ getCoupon: { id, amountOff, valid, metadata } }) => {
            if (!valid) {
              setApplied(false)
              setInvalidCouponError()
            } else {
              onUpdate({
                id,
                amountOff,
                allowedPlans:
                  metadata.allowedPlans && metadata.allowedPlans.split(','),
              })
            }
          }}
          onError={(error) => {
            setApplied(false)

            if (isErrorCode(error, COUPON_DOES_NOT_EXIST_CODE)) {
              setInvalidCouponError()
            } else {
              console.error(error) // eslint-disable-line no-console
            }
          }}
        >
          {({ data, loading, error }) => {
            if (!data || loading) {
              return (
                <div className={css.loader}>
                  <Loader />
                </div>
              )
            }

            if (error) {
              return <ErrorModal error={error} />
            }

            return (
              <div className={css.coupon}>
                <span className={css.couponId}>
                  <Text variant="large">{data.getCoupon.id}</Text>
                </span>

                <Text variant="sectionTitle">
                  ${data.getCoupon.amountOff} off
                </Text>

                <span className={css.btnCouponRemove}>
                  <Button
                    variant="icon"
                    onClick={() => {
                      onUpdate()
                      setApplied(false)
                    }}
                  >
                    <Icon name="xBlack" opacity="50" />
                  </Button>
                </span>
              </div>
            )
          }}
        </Query>
      ) : (
        <Form onSubmit={handleSubmit}>
          <div className={css.form}>
            <div className={css.formTextField}>
              <TextField
                value={coupon}
                placeholder="Coupon code"
                onChange={handleChange}
              />
            </div>

            <Button type="submit" variant="outlineNarrow" offset="half-left">
              Apply
            </Button>
          </div>
        </Form>
      )}
    </div>
  )
}

Coupon.propTypes = {
  onUpdate: PropTypes.func,
}

export default Coupon
