import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { writeText } from 'clipboard-polyfill'

import Text from './Text'
import Tooltip from './Tooltip'

const tooltipContent = <Text variant="small">copied to clipboard</Text>

const Copier = ({ value, children, onCopy }) => {
  const [copied, setCopied] = useState(false)
  const timeoutRef = useRef()

  useEffect(() => {
    const timeout = timeoutRef.current

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  })

  return (
    <Tooltip visible={copied} content={tooltipContent}>
      {React.cloneElement(children, {
        onClick: () => {
          setCopied(true)
          writeText(value)

          onCopy && onCopy()

          clearTimeout(timeoutRef.current)

          timeoutRef.current = setTimeout(() => {
            setCopied(false)
          }, 1000)
        },
      })}
    </Tooltip>
  )
}

Copier.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node,
  onCopy: PropTypes.func,
}

export default Copier
