import css from './index.module.sass'

import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react'
import PropTypes from 'prop-types'

import Alert from '../../Alert'
import Ionicon from '../../Ionicon'
import { FormContextConsumer } from '../../Form/FormContext'
import Link from '../../Link'
import SectionTitle from '../../SectionTitle'
import TeamList from './TeamList'
import TeamMemberFormLinked from './TeamMemberFormLinked'

import {
  HEADER_CLASSNAME,
  CREATION_STEPS_CLASSNAME,
} from '../../../constants/classnames'
import { BILLING_PATH } from '../../../constants/routes'
import { getBounds } from '../../../helpers/ui'
import { isMobile } from '../../../helpers/devices'
import CurrentUserContext from '../../CurrentUserContext'

const EXTRA_SCROLL_OFFSET = 20

const TeamManager = ({ members, onReorder, setValidity, unsetValidity }) => {
  const [adding, setAdding] = useState()
  const [errorVisible, setErrorVisible] = useState()
  const containerRef = useRef()
  const teamListRef = useRef()
  const {
    currentUser: { subscription },
  } = useContext(CurrentUserContext)
  const isPaidUserSubscription = subscription
    ? subscription.isPaidSubscription
    : false

  const showError = () => {
    setErrorVisible(true)
  }

  useLayoutEffect(() => {
    setValidity('members', Boolean(members.length), containerRef, showError)
  }, [members, setValidity])

  useEffect(() => {
    return () => {
      unsetValidity('members')
    }
  }, [unsetValidity])

  return (
    <section ref={containerRef}>
      <SectionTitle
        optional
        title="Team Info"
        description={<>Add information about your founders and team members.</>}
        warning={
          !isPaidUserSubscription && (
            <Alert variant="upgrade" centered textColor={'black'} noClose>
              <Link variant={'inherit'} to={BILLING_PATH}>
                Upgrade to Grow plan to add Team Info to your pitch.
              </Link>
            </Alert>
          )
        }
      />

      {errorVisible && (
        <Alert variant="error" noClose>
          Please add at least one team member.
        </Alert>
      )}

      {members.length > 0 && (
        <div className={css.teamList} ref={teamListRef}>
          <TeamList members={members} onReorder={onReorder} />
        </div>
      )}

      {!adding && (
        <>
          <Link
            disabled={isPaidUserSubscription === false}
            variant="iconOnLeftLarger"
            color="deepBlue"
            icon={<Ionicon name="add" size="24" color="blue" />}
            onClick={() => {
              setErrorVisible(false)
              setAdding(true)
            }}
          >
            Add team member
          </Link>
        </>
      )}

      {adding && (
        <TeamMemberFormLinked
          onCreateSuccess={() => {
            setErrorVisible(false)
            setAdding(false)

            if (isMobile()) {
              const { top: teamListTop } = getBounds(teamListRef.current)
              const { height: headerHeight } = getBounds(HEADER_CLASSNAME)
              const { height: creationStepsHeight } = getBounds(
                CREATION_STEPS_CLASSNAME
              )

              window.scrollTo(
                0,
                teamListTop -
                  headerHeight -
                  creationStepsHeight -
                  EXTRA_SCROLL_OFFSET
              )
            }
          }}
          onCancel={() => setAdding(false)}
        />
      )}
    </section>
  )
}

TeamManager.propTypes = {
  members: PropTypes.array,
  onReorder: PropTypes.func,

  setValidity: PropTypes.func,
  unsetValidity: PropTypes.func,
}

export default function TeamManagerContainer(props) {
  return (
    <FormContextConsumer>
      {({ setValidity, unsetValidity }) => (
        <TeamManager
          {...props}
          setValidity={setValidity}
          unsetValidity={unsetValidity}
        />
      )}
    </FormContextConsumer>
  )
}
