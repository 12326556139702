import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { Mutation } from 'react-apollo'

import Layout from '../components/Layout'
import ResetPasswordForm from '../components/ResetPasswordForm'
import Link from '../components/Link'
import WhiteForm from '../components/WhiteForm'
import Button from '../components/Button'

import * as routes from '../constants/routes'

const RESET_PASSWORD = gql`
  mutation resetPassword(
    $newPassword: String!
    $reNewPassword: String!
    $token: String!
  ) {
    response: resetPassword(
      newPassword: $newPassword
      reNewPassword: $reNewPassword
      token: $token
    ) {
      code
      message
    }
  }
`

class ResetPasswordScreen extends PureComponent {
  static propTypes = {
    token: PropTypes.string,
  }

  state = {
    success: false,
  }

  renderForm() {
    return (
      <WhiteForm
        h2="Reset Password"
        intro="Enter your new password below."
        form={
          <Mutation mutation={RESET_PASSWORD}>
            {(resetPassword, { error, loading }) => (
              <ResetPasswordForm
                error={error}
                loading={loading}
                onReset={(data) => {
                  resetPassword({
                    variables: {
                      newPassword: data.newPassword,
                      reNewPassword: data.reNewPassword,
                      token: this.props.token || '',
                    },
                  }).then(({ error }) => {
                    if (!error) {
                      this.setState({ success: true })
                    }
                  })
                }}
              />
            )}
          </Mutation>
        }
        footer={<Link to={routes.SIGNIN_PATH}>Back to Log In</Link>}
      />
    )
  }

  renderSuccess() {
    return (
      <WhiteForm
        h2="Reset Password"
        intro="Thank you. Your password has been reset. You may log in with your new password."
        form={
          <Button variant="primary" to={routes.SIGNIN_PATH} block>
            Log In
          </Button>
        }
      />
    )
  }

  render() {
    return (
      <Layout variant="centered">
        {this.state.success ? this.renderSuccess() : this.renderForm()}
      </Layout>
    )
  }
}

export default ResetPasswordScreen
