import React, { useState, useRef, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'

import ToggleWrap from './ToggleWrap'
import TextField from '../TextField'

import { usePrevious } from '../../../helpers/hooks'
import {
  DOWN_KEY_CODE,
  UP_KEY_CODE,
  ENTER_KEY_CODE,
  ESC_KEY_CODE,
} from '../../../constants/codes'

const Autocomplete = forwardRef(
  (
    {
      value,
      placeholder,
      hasError,
      clearable,
      focused,
      loading,
      focusedPlaceholder,
      icon,
      renderLabel,
      onClick,
      onClear,
      onAutocomplete,
      onBlur,
      onNavigationKeyDown,
      onFocus,
    },
    ref
  ) => {
    const [filter, setFilter] = useState('')

    const filterRef = useRef()

    const prevValue = usePrevious(value)

    useEffect(() => {
      if (prevValue && !value) {
        filterRef.current.focus()
      }
    })

    const handleUpdate = ({ filter: _filter }) => {
      if (value != null) {
        onClear(true)
      }
      setFilter(_filter)
      onAutocomplete(_filter)
    }

    const handleClick = () => {
      onClick()
      filterRef.current && filterRef.current.focus()
    }

    const handleKeyDown = (event) => {
      if (
        [DOWN_KEY_CODE, UP_KEY_CODE, ENTER_KEY_CODE, ESC_KEY_CODE].includes(
          event.keyCode
        )
      ) {
        onNavigationKeyDown(event)
      }
    }

    return (
      <ToggleWrap
        hasError={hasError}
        clearable={clearable}
        focused={focused}
        loading={loading}
        icon={icon}
        onClick={handleClick}
        ref={ref}
      >
        <TextField
          variant="autocomplete"
          name="filter"
          value={(value && renderLabel(value)) || filter}
          placeholder={
            focused ? focusedPlaceholder || 'Type to search...' : placeholder
          }
          onUpdate={handleUpdate}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          innerRef={(el) => {
            filterRef.current = el
          }}
        />
      </ToggleWrap>
    )
  }
)

Autocomplete.displayName = 'Autocomplete'

Autocomplete.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  focusedPlaceholder: PropTypes.string,
  hasError: PropTypes.bool,
  clearable: PropTypes.bool,
  focused: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.node,
  renderLabel: PropTypes.func,
  onClick: PropTypes.func,
  onClear: PropTypes.func,
  onAutocomplete: PropTypes.func,
  onBlur: PropTypes.func,
  onNavigationKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
}

export default Autocomplete
