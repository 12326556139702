export const VIDEOS_AMOUNT = 3
export const VIDEO_DURATION = 60

export const VIDEO_CREATED_STATUS = 'created'
export const VIDEO_STARTED_STATUS = 'started'
export const VIDEO_FINISHED_STATUS = 'finished'

export const WHAT_WE_ARE_DOING = 'what_we_are_doing'
export const WHO_WE_ARE = 'who_we_are'
export const WHY_WE_ARE_DOING_IT = 'why_we_are_doing_it'

export const VIDEO_TITLES = {
  [WHAT_WE_ARE_DOING]: "What We're Doing",
  [WHO_WE_ARE]: 'Who We Are',
  [WHY_WE_ARE_DOING_IT]: "Why We're Doing It",
}

export const VIDEO_ORDER = [WHO_WE_ARE, WHAT_WE_ARE_DOING, WHY_WE_ARE_DOING_IT]

export const PLAYING_STATE = 'playing'
export const PAUSED_STATE = 'paused'
export const STOPPED_STATE = 'stopped'

export const SELECTED_VIDEO_DEVICE_ID = 'SELECTED_VIDEO_DEVICE_ID'
export const SELECTED_AUDIO_DEVICE_ID = 'SELECTED_AUDIO_DEVICE_ID'
