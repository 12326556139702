import css from './SectionHeading.module.sass'

import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Ionicon from '../Ionicon'
import DevicesContext from '../DevicesContext'

const SectionHeading = ({ icon, children }) => {
  const { mobile } = useContext(DevicesContext)
  return (
    <div className={css.container}>
      <div className={css.inner}>
        <Ionicon
          name={icon}
          size={!mobile ? '32' : '24'}
          offset="right-10"
          color="green"
        />

        <h1 className={css.h1}>{children}</h1>
      </div>
    </div>
  )
}

SectionHeading.propTypes = {
  icon: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
}

export default SectionHeading
