import css from './MemberDragCell.module.sass'

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const MemberDragCell = ({ onDragStart }) => {
  const dragRef = useRef()

  useEffect(() => {
    const dragEl = dragRef.current

    dragEl.addEventListener('touchstart', onDragStart, { passive: false })

    return () => {
      dragEl.removeEventListener('touchstart', onDragStart)
    }
  }, [onDragStart])

  return (
    <td className={css.tdDrag} onMouseDown={onDragStart} ref={dragRef}>
      <i className={css.iconDrag} />
    </td>
  )
}

MemberDragCell.propTypes = {
  onDragStart: PropTypes.func,
}

export default MemberDragCell
