import css from './Checkbox.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../Icon'

class Checkbox extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    label: PropTypes.string,
    onUpdate: PropTypes.func,
  }

  handleChange = (event) => {
    this.props.onUpdate({
      [this.props.name]: event.target.checked,
    })
  }

  render() {
    const { name, label, checked } = this.props

    return (
      <div className={css.container}>
        <input
          id={name}
          className={css.input}
          type="checkbox"
          checked={checked}
          onChange={this.handleChange}
        />
        <label htmlFor={name} className={css.label}>
          <span className={css.box}>{checked && <Icon name="tick" />}</span>
          {label}
        </label>
      </div>
    )
  }
}

export default Checkbox
