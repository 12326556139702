import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Button from '../../Button'
import Ionicon from '../../Ionicon'

const StyleButton = ({ icon, title, active, style, onToggle }) => {
  const handleMouseDown = useCallback(
    (event) => {
      event.preventDefault()

      onToggle(style)
    },
    [style, onToggle]
  )

  return (
    <Button
      key={icon}
      variant="iconEditor"
      active={active}
      title={title}
      onMouseDown={handleMouseDown}
    >
      <Ionicon name={icon} size="24" color="deepBlue" />
    </Button>
  )
}

StyleButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool,
  style: PropTypes.string,
  onToggle: PropTypes.func,
}

export default StyleButton
