import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'

import { Consumer as CurrentUserConsumer } from './CurrentUserContext'

import {
  SIGNIN_PATH,
  SIGNUP_INVESTOR_PROFILE_PATH,
  FEED_PATH,
  PITCHTAPE_PATH,
} from '../constants/routes'

const ProtectedRoute = ({
  component: Component,
  render,
  forSignedIn,
  forSignedOut,
  forFounder,
  forInvestor,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <CurrentUserConsumer>
          {({ currentUser, loading, token }) => {
            if (loading) {
              return null
            }

            const isInvestor = currentUser
              ? currentUser.accountType === 'investor'
              : false
            const isFounder = currentUser
              ? currentUser.accountType === 'founder'
              : false
            const isSignedIn = Boolean(token)
            forSignedIn = forSignedIn || forInvestor || forFounder

            if (forSignedIn && !isSignedIn) {
              return (
                <Redirect
                  to={{
                    pathname: SIGNIN_PATH,
                    state: { from: routeProps.location },
                  }}
                />
              )
            }

            if (
              isInvestor &&
              currentUser.incomplete &&
              routeProps.location.pathname !== SIGNUP_INVESTOR_PROFILE_PATH
            ) {
              return <Redirect to={SIGNUP_INVESTOR_PROFILE_PATH} />
            }

            if (
              isSignedIn &&
              ((forInvestor && !isInvestor) ||
                (forFounder && !isFounder) ||
                forSignedOut)
            ) {
              return <Redirect to={isInvestor ? FEED_PATH : PITCHTAPE_PATH} />
            }

            return render ? render(routeProps) : <Component {...routeProps} />
          }}
        </CurrentUserConsumer>
      )}
    />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  render: PropTypes.func,
  forSignedIn: PropTypes.bool,
  forSignedOut: PropTypes.bool,
  forFounder: PropTypes.bool,
  forInvestor: PropTypes.bool,
}

export default ProtectedRoute
