import css from './Star.module.sass'

import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import Ionicon from '../../Ionicon'
import DevicesContext from '../../DevicesContext'

const Star = ({
  variant,
  id,
  name,
  active,
  checked,
  value,
  color,
  onSelect,
}) => {
  const { mobile } = useContext(DevicesContext)

  const handleChange = useCallback(() => {
    onSelect({
      [name]: value,
    })
  }, [name, value, onSelect])

  return (
    <div className={css.container}>
      <input
        className={css.input}
        id={id}
        name={name}
        type="radio"
        checked={checked}
        onChange={handleChange}
      />
      <label className={css.label} htmlFor={id}>
        <Ionicon
          name={active ? 'star' : 'starOutline'}
          size={mobile ? '44' : variant === 'large' ? '36' : '24'}
          color={active ? color || 'yellow' : 'gray'}
        />
      </label>
    </div>
  )
}

Star.propTypes = {
  variant: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.number,
  color: PropTypes.string,
  onSelect: PropTypes.func,
}

export default Star
