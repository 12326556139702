import css from './CompanyDetails.module.sass'

import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Text from '../Text'
import List from '../List'
import Ionicon from '../Ionicon'

import Rating from './Rating'

import DevicesContext from '../DevicesContext'

import { formatLocations } from '../../helpers/strings'

const formatProperty = (property) => {
  if (Array.isArray(property)) {
    return property.map((x) => x.name).join(', ')
  } else if (property !== null && typeof property === 'object') {
    return property.name
  } else if (typeof property === 'boolean') {
    return property ? 'Yes' : 'No'
  }

  return property
}

const CompanyDetails = ({
  company,
  iconColor = 'white',
  forInvestor,
  onRate,
}) => {
  const { mobile, mobileUa } = useContext(DevicesContext)

  return (
    <>
      <Text variant="h2" tag="h1" weight="400" truncated>
        {company.name}
      </Text>
      <Text variant="h3" tag="p" offset="half-bottom">
        {company.description}
      </Text>

      {forInvestor && (mobile || mobileUa) && (
        <div className={css.rating}>
          <Rating company={company} onRate={onRate} />
        </div>
      )}

      <List variant="horizontal" gap="30" offset="5">
        {Boolean(company.locations.length) && (
          <List.Item>
            <Ionicon
              name="pin"
              color={iconColor}
              offset="right-5"
              style={{ verticalAlign: -3 }}
            />
            {formatLocations(company.locations)}
          </List.Item>
        )}
        {Boolean(company.industries.length) && (
          <List.Item>
            <Ionicon
              name="business"
              color={iconColor}
              offset="right-5"
              style={{ verticalAlign: -3 }}
            />
            {formatProperty(company.industries)}
          </List.Item>
        )}
        {company.customerCategory && (
          <List.Item>
            <Ionicon
              name="briefcase"
              color={iconColor}
              offset="right-5"
              style={{ verticalAlign: -3 }}
            />
            {formatProperty(company.customerCategory)}
          </List.Item>
        )}
        {company.stage && (
          <List.Item>
            <Ionicon
              name="growth"
              color={iconColor}
              offset="right-5"
              style={{ verticalAlign: -2 }}
            />
            {formatProperty(company.stage)}
          </List.Item>
        )}
      </List>
    </>
  )
}

CompanyDetails.propTypes = {
  company: PropTypes.object,
  iconColor: PropTypes.string,
  forInvestor: PropTypes.bool,
  onRate: PropTypes.func,
}

export default CompanyDetails
