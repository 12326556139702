import css from './Controls.module.sass'

import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Ionicon from '../Ionicon'
import Text from '../Text'

import Segments from './Segments'

import { PLAYING_STATE } from '../../constants/videos'
import { formatSecondsAsTime } from '../../helpers/dates'
import { getBounds } from '../../helpers/ui'

const Controls = ({
  time,
  type,
  videoState,
  duration,
  muted,
  segments,
  currentSegmentIndex,
  onMute,
  onPlay,
  onPause,
  onFullscreen,
  onSeek,
  onChangeSpeed,
  isPiPEnabled,
  onPictureInPicture,
}) => {
  const progressbarRef = useRef()
  const containerRef = useRef()

  const handleContainerClick = useCallback(
    (event) => {
      if (event.target === containerRef.current) {
        onPause()
      }
    },
    [onPause]
  )

  const handleProgressBarClick = useCallback(
    (event) => {
      const progressbar = progressbarRef.current
      const { left: progressbarLeft } = getBounds(progressbar)
      const x = event.pageX - progressbarLeft
      const width = progressbar.clientWidth
      const position = x / width

      onSeek(position * duration)
    },
    [onSeek, duration]
  )

  const positionFor = useCallback(
    (index) => {
      let position = 0

      for (var i = 0; i < index; i++) {
        position += segments[i].duration
      }

      return position
    },
    [segments]
  )

  const handleBack = useCallback(() => {
    onSeek(positionFor(currentSegmentIndex - 1))
  }, [onSeek, positionFor, currentSegmentIndex])

  const handleForward = useCallback(() => {
    onSeek(positionFor(currentSegmentIndex + 1))
  }, [onSeek, positionFor, currentSegmentIndex])

  const handleSegmentSelect = (index) => {
    onSeek(positionFor(index))
  }

  return (
    <div
      className={css[type]}
      ref={containerRef}
      onClick={handleContainerClick}
    >
      <div className={css.main}>
        {segments && segments.length > 0 && (
          <Button
            variant="icon"
            icon={<Ionicon name="skipBackward" color="white" size="24" />}
            disabled={currentSegmentIndex === 0}
            onClick={handleBack}
          />
        )}

        {videoState === PLAYING_STATE ? (
          <Button
            variant="icon"
            icon={<Ionicon name="pause" color="white" size="24" />}
            onClick={onPause}
          />
        ) : (
          <Button
            variant="icon"
            icon={<Ionicon name="play" color="white" size="24" />}
            onClick={onPlay}
          />
        )}

        {segments && segments.length > 1 && (
          <Button
            variant="icon"
            icon={<Ionicon name="skipForward" color="white" size="24" />}
            disabled={currentSegmentIndex === segments.length - 1}
            onClick={handleForward}
          />
        )}
      </div>

      <div className={css.segmentAndProgressbar}>
        {segments && segments.length > 1 && (
          <Text
            tag="p"
            variant="h3"
            color="green"
            weight="500"
            offset="quarter-bottom"
          >
            {segments &&
              segments[currentSegmentIndex] &&
              segments[currentSegmentIndex].title}
          </Text>
        )}
        <div
          className={css.progressbar}
          ref={progressbarRef}
          onClick={handleProgressBarClick}
        >
          <div
            className={css.progress}
            style={{ width: (time / duration) * 100 + '%' }}
          />
        </div>
      </div>

      <div className={css.time}>
        <Text color="white">
          <Text color="gray">{formatSecondsAsTime(time)}</Text>
          {duration && type !== 'micro' ? (
            <>
              {' '}
              <Text color="gray">/</Text> {formatSecondsAsTime(duration)}
            </>
          ) : (
            ''
          )}
        </Text>
      </div>

      {type !== 'micro' && (
        <div className={css.side}>
          <Button
            variant="icon"
            icon={
              <Ionicon
                name={muted ? 'volumeOff' : 'volumeHigh'}
                color="white"
                size="24"
              />
            }
            onClick={onMute}
          />
          <Button
            variant="icon"
            icon={<Ionicon name="play_speed_white" color="white" size="24" />}
            onClick={onChangeSpeed}
          />

          {isPiPEnabled && (
            <Button
              variant="icon"
              icon={<Ionicon name="pip_icon_white" color="white" size="24" />}
              onClick={onPictureInPicture}
            />
          )}

          <Button
            variant="icon"
            icon={<Ionicon name="expand" color="white" size="24" />}
            onClick={onFullscreen}
          />
        </div>
      )}

      {segments && (
        <Segments
          segments={segments}
          currentIndex={currentSegmentIndex}
          onSelect={handleSegmentSelect}
        />
      )}
    </div>
  )
}

Controls.propTypes = {
  time: PropTypes.number,
  type: PropTypes.string,
  videoState: PropTypes.string,
  duration: PropTypes.number,
  muted: PropTypes.bool,
  segments: PropTypes.array,
  currentSegmentIndex: PropTypes.number,
  onMute: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onFullscreen: PropTypes.func,
  onSeek: PropTypes.func,
  onBack: PropTypes.func,
  onForward: PropTypes.func,
  onChangeSpeed: PropTypes.func,
  isPiPEnabled: PropTypes.bool,
  onPictureInPicture: PropTypes.func,
}

export default Controls
