import React from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import { GET_USER_COMPANY } from '../../../constants/queries'

const PreviewPitchLoader = ({ children }) => (
  <Query query={GET_USER_COMPANY}>
    {({ data }) => {
      const visible = data?.company?.concatenatedVideo
      if (visible) {
        return children
      } else {
        return null
      }
    }}
  </Query>
)

PreviewPitchLoader.propTypes = {
  children: PropTypes.node,
}

export default PreviewPitchLoader
