import './index.sass'

import 'url-search-params-polyfill'
import 'picturefill'
import 'focus-visible'
import smoothscroll from 'smoothscroll-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'

import App from './App'

import * as serviceWorker from './serviceWorker'

import { isStageOrProdEnv } from './helpers/env'
import { SENTRY_DSN } from './constants/logging'
import { version } from '../package.json'

if (isStageOrProdEnv()) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: version,
    environment: process.env.REACT_APP_ENV,
  })
}

smoothscroll.polyfill()

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
