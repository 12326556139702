import React, { useEffect } from 'react'

const RedirectToInvestorsMarketSite = () => {
  useEffect(() => {
    window.location.href = 'https://www.pitchtape.com/investors'
  }, [])

  return (
    <div>
      <h2>Redirecting...</h2>
    </div>
  )
}

export default RedirectToInvestorsMarketSite
