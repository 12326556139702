import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Dropdown from '../Form/Dropdown'
import { useMediaDevices } from 'react-use'

const DevicesDropdown = ({ value, ...dropdownProps }) => {
  const { devices = [] } = useMediaDevices()
  const options = useMemo(() => {
    return devices
      .filter((x) => x.kind === `${dropdownProps.name}input`)
      .map((x) => ({ value: x, label: x.label }))
  }, [devices, dropdownProps.name])

  if (value) {
    value = devices.find(({ deviceId }) => value === deviceId) || options[0]
  } else {
    value = options[0]
  }

  return (
    <Dropdown
      value={value}
      options={options}
      valueIdKey="deviceId"
      renderLabel={(value) => value.label}
      {...dropdownProps}
    />
  )
}

DevicesDropdown.propTypes = {
  data: PropTypes.object,
  value: PropTypes.any,
}

export default DevicesDropdown
