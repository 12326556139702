import css from './index.module.sass'

import React, { useCallback, useContext, useState } from 'react'
import TemplateTopic from './TemplateTopic'
import { useQuery } from 'react-apollo'
import Text from '../../Text'
import Button from '../../Button'
import PropTypes from 'prop-types'
import { GET_TEMPLATE_TOPICS } from '../../../constants/queries'
import Alert from '../../Alert'
import { formatGraphQLError } from '../../../helpers/errors'
import Loader from '../../Loader'
import Link from '../../Link'
import {
  BILLING_PATH,
  CREATE_VIDEOS_NEW_CUSTOM_PATH,
} from '../../../constants/routes'
import { injectParams } from '../../../helpers/routes'
import { useHistory } from 'react-router-dom'
import CurrentUserContext from '../../CurrentUserContext'

const TemplateTopics = (props) => {
  const history = useHistory()
  const [showUpgradeMessageCustomTopic, setShowUpgradeMessageCustomTopic] =
    useState(false)
  const {
    currentUser: { subscription },
  } = useContext(CurrentUserContext)
  const isPaidUserSubscription = subscription
    ? subscription.isPaidSubscription
    : false
  const { loading, fetchingError, data } = useQuery(GET_TEMPLATE_TOPICS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getTemplateTopics }) => {
      let allInUse = true
      getTemplateTopics.forEach((templateTopic) => {
        if (templateTopic.inUse === false) {
          allInUse = false
        }
      })
      props.setAllTemplateTopicsInUse(allInUse)
    },
  }) // fetchPolicy: 'no-cache'

  const handleSelect = (selectedId, action) => {
    if (action === 'add')
      props.setSelectedTemplates([...props.selectedTopicsTemplates, selectedId])
    if (action === 'remove')
      props.setSelectedTemplates((prevState) => {
        if (prevState && Array.isArray(prevState) && prevState.length > 0)
          props.setSelectedTemplates(
            prevState.filter((item) => item !== selectedId)
          )
        else props.setSelectedTemplates([])
      })
  }

  const handleSelectAll = useCallback(() => {
    if (data && data.getTemplateTopics) {
      let topicsToAdd = []
      data.getTemplateTopics.forEach((topic) => {
        if (
          !(
            props.selectedTopicsTemplates.filter(
              (selectedTopic) => selectedTopic === topic.id
            ).length > 0
          ) &&
          !topic.inUse
        ) {
          topicsToAdd.push(topic.id)
        }
      })
      if (topicsToAdd.length > 0)
        props.setSelectedTemplates(
          [...props.selectedTopicsTemplates, ...topicsToAdd].sort()
        )
    }
  }, [props, data])

  const handleNewCustom = () => {
    if (isPaidUserSubscription) {
      props.handleCancel()
      history.replace(injectParams(CREATE_VIDEOS_NEW_CUSTOM_PATH))
    } else {
      setShowUpgradeMessageCustomTopic(true)
    }
  }

  if (props.loading || loading) return <Loader variant={'centered'} />

  return (
    <>
      <div className={css.componentContainer}>
        {fetchingError && (
          <Alert variant="error">{formatGraphQLError(fetchingError)}</Alert>
        )}
        <div className={css.subTitle}>
          <Text variant={'standardLarger'} offset={'half-right'}>
            Select one or more topics from topic templates, and save.
          </Text>

          <Link onClick={handleSelectAll}>
            <Text
              variant={'standardLarger'}
              className={css.selectAll}
              offset={'half-right'}
            >
              Select all
            </Text>
          </Link>
        </div>
        <div className={css.templateContainer}>
          {data.getTemplateTopics?.map((templateTopicInstance) => (
            <TemplateTopic
              instance={templateTopicInstance}
              key={templateTopicInstance.id}
              handleClick={handleSelect}
              selectedTemplateTopics={props.selectedTopicsTemplates}
            />
          ))}
        </div>
        <div className={css.newCustomTopic}>
          <div>
            <Text variant={'standardLarger'}>
              Or, add a new custom topic, one at a time.
            </Text>
          </div>

          <div className={css.customTopicButton}>
            <Button
              variant={'templateTopic'}
              onClick={handleNewCustom}
              disabled={showUpgradeMessageCustomTopic}
            >
              +New Topic
            </Button>
          </div>
        </div>
        {showUpgradeMessageCustomTopic && (
          <div className={css.upgradeMessage}>
            <Alert variant="upgrade" centered textColor={'black'} noClose>
              <Link variant={'inherit'} to={BILLING_PATH}>
                Upgrade to add unlimited custom topics!
              </Link>
            </Alert>
          </div>
        )}
      </div>
    </>
  )
}

TemplateTopics.propTypes = {
  selectedTopicsTemplates: PropTypes.array,
  setSelectedTemplates: PropTypes.func,
  handleCancel: PropTypes.func,
  loading: PropTypes.bool,
  setAllTemplateTopicsInUse: PropTypes.func,
}

export default TemplateTopics
