import React from 'react'
import VideoTour from './index'
import {
  TOUR_VIDEO_BUILDER_RECORD,
  TOUR_VIDEO_BUILDER_STITCH_VIDEO,
  TOUR_VIDEO_BUILDER_TAPES,
  TOUR_VIDEO_BUILDER_TOPICS,
} from '../../../constants/tour'

const VideoTourFlow = () => {
  return (
    <VideoTour
      steps={[
        {
          title: 'Record one topic at a time',
          text: 'Follow your pitch outline and record in short segments. You can add, remove and reorder the topics.',
          selector: '.' + TOUR_VIDEO_BUILDER_TOPICS,
        },
        {
          title: 'Start and stop recording',
          text: 'Click Start to begin recording, and click Stop to end the recording. When you click Start, make sure to begin talking immediately after the countdown.',
          selector: '.' + TOUR_VIDEO_BUILDER_RECORD,
        },
        {
          title: 'Compare your takes and try again',
          text: 'Record each topic as many times as you like in Tape 1 & 2, then select your best Tape and save.',
          selector: '.' + TOUR_VIDEO_BUILDER_TAPES,
        },
        {
          title: 'Stitch and preview your pitch',
          text: 'Combine your recorded topics into one video. To preview and update your pitch, please wait for the stitching process to complete.',
          selector: '.' + TOUR_VIDEO_BUILDER_STITCH_VIDEO,
        },
      ]}
    />
  )
}

export default VideoTourFlow
