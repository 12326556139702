import { isProdEnv } from './env'

export let chatraIdentify = () => {}
export let chatraUnidentify = () => {}

if (isProdEnv()) {
  let identifiedUserId

  chatraIdentify = ({ id, firstName, lastName, email }) => {
    if (identifiedUserId !== id) {
      identifiedUserId = id

      window.Chatra('setIntegrationData', {
        name: `${firstName} ${lastName}`,
        email,
      })
    }
  }

  chatraUnidentify = () => {
    window.Chatra('updateIntegrationData', {
      name: null,
      email: null,
    })
  }
}
