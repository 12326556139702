import {
  START_PLAN_NAME,
  GROW_PLAN_NAME,
  ACTIVE_STATUS,
} from '../constants/plans'

export const isSubscriptionActive = (subscription) =>
  subscription && subscription.status === ACTIVE_STATUS

export const isActiveGrowPlan = (subscription) =>
  subscription &&
  subscription.plan === GROW_PLAN_NAME &&
  subscription.status === ACTIVE_STATUS

export const isActivePaidPlan = (subscription) =>
  subscription &&
  subscription.plan !== START_PLAN_NAME &&
  subscription.status === ACTIVE_STATUS
