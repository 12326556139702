export const injectParams = (path, params) => {
  return path.replace(/\/:(\w+)\??/g, (m, key) =>
    params && params[key] ? '/' + params[key] : ''
  )
}

export const toWithParams = (to, params, hash) => {
  if (typeof to === 'string') {
    return injectParams(to, params) + (hash ? `#${hash}` : '')
  }

  return {
    ...to,
    pathname: injectParams(to.pathname, params),
    hash,
  }
}
