import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { gql } from 'graphql-tag'
import { Query, Mutation } from 'react-apollo'
import omit from 'lodash.omit'
import mapValues from 'lodash.mapvalues'

import InvestorProfileForm from '../components/InvestorProfileForm'
import Loader from '../components/Loader'
import Alert from '../components/Alert'

import { formatGraphQLError } from '../helpers/errors'
import {
  formDataWithErrors,
  prepareFormData,
  formDataToServerData,
} from '../helpers/forms'
import { getBounds } from '../helpers/ui'
import {
  USER_INVESTOR_PROFILE_FRAGMENT,
  ERRORS_FOR_FIELDS_FRAGMENT,
} from '../constants/fragments'
import { GET_USER_INVESTOR_PROFILE } from '../constants/queries'
import { REQUIRED_INVESTOR_PROFILE_FIELDS } from '../constants/forms'

const UPDATE_INVESTOR_PROFILE = gql`
  mutation updateInvestorProfile($input: InvestorProfileUpdateMutationInput!) {
    updateInvestorProfile(input: $input) {
      obj {
        ...userInvestorProfile
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${USER_INVESTOR_PROFILE_FRAGMENT}
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

const InvestorProfileFormLinked = ({ onSuccess, submitButtonText }) => {
  const [formData, setFormData] = useState()
  const [success, setSuccess] = useState(false)
  const formRef = useRef()

  return (
    <Mutation
      mutation={UPDATE_INVESTOR_PROFILE}
      onCompleted={({ updateInvestorProfile: { errors } }) => {
        if (errors) {
          setFormData(formDataWithErrors(formData, errors))
        } else {
          if (onSuccess) {
            onSuccess()
          } else {
            const { top } = getBounds(formRef.current)
            window.scrollTo(0, top - 100)
            setSuccess(true)
          }
        }
      }}
      update={(
        cache,
        {
          data: {
            updateInvestorProfile: { obj },
          },
        }
      ) => {
        if (obj) {
          cache.writeQuery({
            query: GET_USER_INVESTOR_PROFILE,
            data: {
              getUserInvestorProfile: obj,
            },
          })
        }
      }}
    >
      {(updateInvestorProfile, { loading: updating, error: updatingError }) => (
        <Query
          query={GET_USER_INVESTOR_PROFILE}
          notifyOnNetworkStatusChange
          onCompleted={(data) => {
            if (!formData) {
              setFormData(
                prepareFormData(
                  omit(data.getUserInvestorProfile, ['id', '__typename']),
                  REQUIRED_INVESTOR_PROFILE_FIELDS
                )
              )
            }
          }}
        >
          {({ error: gettingError, loading: getting }) => {
            if (getting) {
              return <Loader />
            }

            if (gettingError) {
              return (
                <Alert variant="error">
                  {formatGraphQLError(gettingError)}
                </Alert>
              )
            }

            if (!formData) {
              return <Loader />
            }

            return (
              <InvestorProfileForm
                ref={formRef}
                success={success}
                error={updatingError}
                formData={formData}
                saving={updating}
                submitButtonText={submitButtonText}
                onUpdate={(patch) => {
                  setFormData({
                    ...formData,
                    ...prepareFormData(patch, REQUIRED_INVESTOR_PROFILE_FIELDS),
                  })
                }}
                onSubmit={() => {
                  setSuccess(false)

                  updateInvestorProfile({
                    variables: {
                      input: {
                        ...formDataToServerData(
                          mapValues(formData, (v) => v.value),
                          { urlKeys: ['profileUrl', 'website'] }
                        ),
                        fundingSources: [],
                      },
                    },
                  })
                }}
              />
            )
          }}
        </Query>
      )}
    </Mutation>
  )
}

InvestorProfileFormLinked.propTypes = {
  submitButtonText: PropTypes.string,
  onSuccess: PropTypes.func,
}

export default InvestorProfileFormLinked
