import css from './Doc.module.sass'

import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const Doc = ({
  pdf,
  pageNumber,
  selectedPageNumber,
  maxWidth,
  maxHeight,
  onResize,
}) => {
  const canvasContainerRef = useRef()
  const canvasRef = useRef()
  const queueRef = useRef([])
  const renderingRef = useRef()

  useEffect(() => {
    const renderPage = async () => {
      if (renderingRef.current) {
        queueRef.current = renderPage
        return
      }

      queueRef.current = null
      renderingRef.current = pageNumber

      const page = await pdf.getPage(pageNumber)

      let viewport = page.getViewport({ scale: 1 })
      let scale = (maxWidth * 2) / viewport.width
      if (maxHeight && scale * viewport.height > maxHeight * 2) {
        scale = (maxHeight * 2) / viewport.height
      }
      viewport = page.getViewport({ scale })

      const canvas = canvasRef.current
      const context = canvas.getContext('2d')
      canvas.height = viewport.height
      canvas.width = viewport.width
      const canvasHeight = Math.floor(viewport.height / 2)
      const canvasWidth = Math.floor(viewport.width / 2)
      canvas.style.height = canvasHeight + 'px'
      canvas.style.width = canvasWidth + 'px'

      onResize && onResize({ width: canvasWidth, height: canvasHeight })

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }

      const renderTask = page.render(renderContext)

      renderTask.promise.then(() => {
        renderingRef.current = false

        if (queueRef.current) {
          queueRef.current()
        }
      })
    }

    renderPage()
  }, [pdf, maxWidth, maxHeight, pageNumber, onResize])

  return (
    <div className={css.container} ref={canvasContainerRef}>
      <canvas className={css.canvas} ref={canvasRef} />

      {selectedPageNumber === pageNumber && (
        <div className={css.selected}>SELECTED</div>
      )}
    </div>
  )
}

Doc.propTypes = {
  pdf: PropTypes.object,
  pageNumber: PropTypes.number,
  selectedPageNumber: PropTypes.number,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  onResize: PropTypes.func,
}

export default Doc
