import { useRef, useEffect, useMemo } from 'react'
import { gql } from 'graphql-tag'
import { useMutation } from 'react-apollo'

import { gtmTrack } from './tracking'

import {
  GTM_PITCHTAPE_VIEWED,
  GTM_PITCHTAPE_VIEWING_STOP,
} from '../constants/gtm'
import { GET_USER_COMPANY, SAVE_COMPANY_TELEPROMPT } from '../constants/queries'
import {
  COMPANY_VIDEO_SEGMENT_FRAGMENT,
  ERRORS_FOR_FIELDS_FRAGMENT,
} from '../constants/fragments'
import cloneDeep from 'lodash.clonedeep'

export function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export function useMountedRef() {
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  })

  return mountedRef
}

export function usePitchtapeTracking(company) {
  useEffect(() => {
    if (!company) {
      return
    }

    gtmTrack(GTM_PITCHTAPE_VIEWED, {
      pitchtape: {
        id: company.id,
      },
    })

    const loadedDate = new Date()

    return () => {
      gtmTrack(GTM_PITCHTAPE_VIEWING_STOP, {
        pitchtape: {
          id: company.id,
          viewDuration: Math.floor((new Date() - loadedDate) / 1000),
        },
      })
    }
  }, [company])
}

const UPLOAD_COMPANY_VIDEO_SEGMENT = gql`
  mutation uploadCompanyVideoSegment(
    $binary: Upload
    $topic: Int!
    $duration: String
    $metadata: JSONString
  ) {
    uploadCompanyVideoSegment(
      input: {
        binary: $binary
        topic: $topic
        duration: $duration
        metadata: $metadata
      }
    ) {
      obj {
        ...companyVideoSegment
      }
      errors {
        ...errorsForFields
      }
    }
  }
  ${COMPANY_VIDEO_SEGMENT_FRAGMENT}
  ${ERRORS_FOR_FIELDS_FRAGMENT}
`

export function useUploadCompanyVideoSegment(topicId, options) {
  return useMutation(UPLOAD_COMPANY_VIDEO_SEGMENT, {
    update: (
      cache,
      {
        data: {
          uploadCompanyVideoSegment: { obj, errors },
        },
      }
    ) => {
      if (errors) {
        return
      }

      const { company } = cache.readQuery({ query: GET_USER_COMPANY })

      const topic = company.videoTopics.find((t) => t.id === topicId)
      const updatedTopic = {
        ...topic,
        video: obj,
      }
      const nextVideoTopics = company.videoTopics.map((t) =>
        t.id !== topicId ? t : updatedTopic
      )

      const videoRequiresUpdate =
        !topic.video || topic.video.binary !== updatedTopic.video.binary

      cache.writeQuery({
        query: GET_USER_COMPANY,
        data: {
          company: {
            ...company,
            videoTopics: nextVideoTopics,
            concatenatedVideo:
              videoRequiresUpdate && company.concatenatedVideo
                ? {
                    ...company.concatenatedVideo,
                    videoIsUpToDate: false,
                  }
                : company.concatenatedVideo,
          },
        },
      })
    },
    ...options,
  })
}

export function useDownloadVideoUrl(company) {
  return useMemo(() => {
    if (company.concatenatedVideo && company.concatenatedVideo.binary) {
      const match = company.concatenatedVideo.binary.match(/[^/]+$/)

      if (match) {
        return '/download/' + match[0]
      }
    }

    return null
  }, [company])
}

export function useSaveCompanyTelepromptMutation(options) {
  return useMutation(SAVE_COMPANY_TELEPROMPT, {
    update: (
      cache,
      {
        data: {
          createOrUpdateCompanyVideoTopic: { obj, errors },
        },
      }
    ) => {
      if (errors) {
        return
      }
      const telePrompt = obj?.teleprompt
      if (telePrompt && obj?.id) {
        const { company } = cache.readQuery({ query: GET_USER_COMPANY })

        let mutableObject = cloneDeep(company)
        mutableObject.videoTopics.forEach((videoTopic) => {
          if (videoTopic.id === obj.id)
            Object.assign(videoTopic, { teleprompt: telePrompt })
        })

        cache.writeQuery({
          query: GET_USER_COMPANY,
          data: {
            company: {
              ...mutableObject,
            },
          },
        })
      }
    },
    ...options,
  })
}
