import css from './Content.module.sass'

import React from 'react'
import PropTypes from 'prop-types'

import Text from '../../Text'
import List from '../../List'
import Button from '../../Button'
import CompanyUrlCopier from '../../CompanyUrlCopier'
import { Consumer as DevicesConsumer } from '../../DevicesContext'

import { CREATE_PATH } from '../../../constants/routes'

const Content = ({ heading, text, textMaxWidth, status, buttons }) => (
  <DevicesConsumer>
    {({ mobile }) => (
      <div className={css.container}>
        <div className={css.main}>
          {heading && (
            <Text variant="h3" tag="h1" offset="single-bottom">
              {heading}
            </Text>
          )}

          <div className={css.paragraph} style={{ maxWidth: textMaxWidth }}>
            {typeof text === 'string' ? <Text tag="p">{text}</Text> : text}
          </div>

          {buttons && <div className={css.buttons}>{buttons}</div>}
        </div>

        {status && (
          <div className={css.status}>
            <Text variant="sectionTitleSmall" tag="p">
              Status: {status}
            </Text>
          </div>
        )}

        {!mobile && (
          <List variant="centeredActions">
            <List.Item style={{ maxWidth: 160 }}>
              <Button
                variant="iconOnTop"
                external
                href="http://pitchtape.com/blog"
                icon="blogBlue"
                iconMaxHeight={24}
              >
                Read our blog for tips on improving your pitch
              </Button>
            </List.Item>

            <List.Item style={{ maxWidth: 90 }}>
              <Button
                variant="iconOnTop"
                to={CREATE_PATH}
                icon="pencilBlue"
                iconMaxHeight={24}
              >
                Update your pitch
              </Button>
            </List.Item>

            <List.Item style={{ maxWidth: 150 }}>
              <CompanyUrlCopier>
                <Button variant="iconOnTop" icon="shareBlue" iconMaxHeight={24}>
                  Share your pitch with your network
                </Button>
              </CompanyUrlCopier>
            </List.Item>
          </List>
        )}
      </div>
    )}
  </DevicesConsumer>
)

Content.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.node,
  textMaxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  buttons: PropTypes.node,
}

export default Content
