import React from 'react'
import PropTypes from 'prop-types'

import {
  Form,
  FormRow,
  FormCol,
  FormErrors,
  EmailField,
  TextField,
} from './Form'
import Alert from './Alert'
import Button from './Button'

import { formatGraphQLError } from '../helpers/errors'
import { GTM_FORM_CHANGE_USER_DETAILS } from '../constants/gtm'

class ChangeUserDetailsForm extends React.Component {
  static propTypes = {
    error: PropTypes.object,
    loading: PropTypes.bool,
    message: PropTypes.string,
    formErrors: PropTypes.array,
    defaultValues: PropTypes.object,
    onSubmit: PropTypes.func,
  }

  state = {
    formData: {
      firstName: '',
      lastName: '',
      email: '',
      ...this.props.defaultValues,
    },
    valid: false,
  }

  handleFieldUpdate = (patch) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...patch,
      },
    })
  }

  render() {
    const { formErrors, error, loading, message } = this.props
    const { formData, valid } = this.state

    return (
      <Form
        gtm={GTM_FORM_CHANGE_USER_DETAILS}
        onSubmit={(event) => {
          event.preventDefault()

          if (valid && !loading) {
            this.props.onSubmit(formData)
          }
        }}
        onValid={() => this.setState({ valid: true })}
        onInvalid={() => this.setState({ valid: false })}
      >
        {formErrors && <FormErrors errors={formErrors} />}
        {message && (
          <Alert variant="success" offset="double">
            {message}
          </Alert>
        )}
        {error && (
          <Alert variant="error" offset="double">
            {formatGraphQLError(error)}
          </Alert>
        )}

        <FormRow>
          <FormCol>
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              required
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset="20">
          <FormCol>
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              required
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset="20">
          <FormCol>
            <EmailField
              label="Email Address"
              name="email"
              value={formData.email}
              required
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset="20">
          <FormCol>
            <Button variant="primary" type="submit" disabled={loading}>
              Save details
            </Button>
          </FormCol>
        </FormRow>
      </Form>
    )
  }
}

export default ChangeUserDetailsForm
