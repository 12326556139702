import React from 'react'
import PropTypes from 'prop-types'

import { Form, FormRow, FormCol, PasswordField } from './Form'
import Button from './Button'
import Alert from './Alert'

import { formatGraphQLError } from '../helpers/errors'
import { GTM_FORM_CHANGE_PASSWORD } from '../constants/gtm'

class ChangeEmailForm extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    message: PropTypes.string,
    onSubmit: PropTypes.func,
  }

  state = {
    formData: {
      newPassword: '',
      password: '',
    },
    valid: false,
  }

  handleFieldUpdate = (patch) => {
    this.setState({
      formData: {
        ...this.state.formData,
        ...patch,
      },
    })
  }

  render() {
    const { loading, error, message } = this.props
    const { formData, valid } = this.state

    return (
      <Form
        gtm={GTM_FORM_CHANGE_PASSWORD}
        onSubmit={(event) => {
          event.preventDefault()

          if (!loading && valid) {
            this.props.onSubmit(formData)
          }
        }}
        onValid={() => this.setState({ valid: true })}
        onInvalid={() => this.setState({ valid: false })}
      >
        {message && (
          <Alert variant="success" offset="double">
            {message}
          </Alert>
        )}
        {error && (
          <Alert variant="error" offset="double">
            {formatGraphQLError(error)}
          </Alert>
        )}

        <FormRow>
          <FormCol>
            <PasswordField
              label="Current Password"
              name="password"
              value={formData.password}
              required
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset="20">
          <FormCol>
            <PasswordField
              label="New Password"
              name="newPassword"
              value={formData.newPassword}
              required
              onUpdate={this.handleFieldUpdate}
            />
          </FormCol>
        </FormRow>

        <FormRow offset="20">
          <FormCol>
            <Button variant="primary" type="submit" disabled={loading}>
              Save password
            </Button>
          </FormCol>
        </FormRow>
      </Form>
    )
  }
}

export default ChangeEmailForm
