import css from './MessageField.module.sass'

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import PubNub from 'pubnub'

import Button from '../Button'
import List from '../List'
import { Form, TextArea } from '../Form'
import EmailLink from './EmailLink'
import AttachLink from './AttachLink'
import Attachments from './Attachments'
import { Consumer as CurrentUserConsumer } from '../CurrentUserContext'
import { Consumer as DevicesConsumer } from '../DevicesContext'

import { PUBNUB_SUBSCRIBE_KEY, PUBNUB_PUBLISH_KEY } from '../../constants/keys'
import { ENTER_KEY_CODE } from '../../constants/codes'
import { GTM_CHAT_MESSAGE_SENT } from '../../constants/gtm'
import { gtmTrack } from '../../helpers/tracking'

const MessageField = ({
  connecting,
  channel,
  authKey,
  connection,
  currentUserId,
  onChange,
}) => {
  const [message, setMessage] = useState('')
  const [attachments, setAttachments] = useState([])
  const [sending, setSending] = useState(false)

  const pubnubRef = useRef()
  const textareaRef = useRef()

  const uploading = useMemo(() => {
    return attachments.some((a) => !a.url)
  }, [attachments])

  useEffect(() => {
    onChange()
  }, [message, attachments, onChange])

  const sendMessage = useCallback(() => {
    if (!uploading && !connecting && !sending) {
      if (!message && !attachments.length) {
        return
      }

      setSending(true)

      textareaRef.current.focus()

      pubnubRef.current.publish(
        {
          channel: channel,
          message: {
            from: currentUserId,
            ...(Boolean(message) && {
              message,
            }),
            ...(Boolean(attachments.length) && {
              attachments: attachments.map(({ id, url, file }) => ({
                id,
                url,
                name: file.name,
              })),
            }),
          },
        },
        (status) => {
          if (status.error) {
            // eslint-disable-next-line no-console
            return console.log(status)
          }

          gtmTrack(GTM_CHAT_MESSAGE_SENT)

          setMessage('')
          setAttachments([])
          setSending(false)
        }
      )
    }
  }, [
    uploading,
    connecting,
    sending,
    attachments,
    message,
    channel,
    currentUserId,
  ])

  const handleChange = useCallback((event) => {
    setMessage(event.target.value)
  }, [])

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()

      sendMessage()
    },
    [sendMessage]
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === ENTER_KEY_CODE && !event.ctrlKey) {
        sendMessage()
      }
    },
    [sendMessage]
  )

  useEffect(() => {
    pubnubRef.current = new PubNub({
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      publishKey: PUBNUB_PUBLISH_KEY,
      authKey,
      uuid: currentUserId,
    })
  }, [authKey, currentUserId])

  const handleFileUploadStart = useCallback((attachment) => {
    setAttachments((attachments) => attachments.concat(attachment))
  }, [])

  const handleFileUploadEnd = useCallback((updatedAttachment) => {
    setAttachments((attachments) => {
      return attachments.map((attachment) => {
        if (attachment.localId === updatedAttachment.localId) {
          return updatedAttachment
        }

        return attachment
      })
    })
  }, [])

  const handleFileRemove = useCallback((removedAttachment) => {
    setAttachments((attachments) =>
      attachments.filter((attachment) => {
        return attachment.localId !== removedAttachment.localId
      })
    )
  }, [])

  return (
    <DevicesConsumer>
      {({ mobile }) => (
        <div className={css.container}>
          <div className={css.field}>
            <Form onSubmit={handleSubmit}>
              <div className={css.textarea}>
                <TextArea
                  variant="transparent"
                  name="message"
                  placeholder="Click to type message..."
                  value={message}
                  autoHeight
                  block
                  rows="1"
                  disabled={sending}
                  focused={!mobile}
                  innerRef={(el) => {
                    textareaRef.current = el
                  }}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <div className={css.btnSend}>
                <Button variant="primaryNarrow" type="submit">
                  Send
                </Button>
              </div>
            </Form>
          </div>

          {Boolean(attachments.length) && (
            <div className={css.attachments}>
              <Attachments
                attachments={attachments}
                onRemove={handleFileRemove}
              />
            </div>
          )}

          <div className={css.actions}>
            <List variant="horizontal" gap="20">
              <List.Item>
                <AttachLink
                  chatId={channel && channel.replace(/^channel_/, '')}
                  onUploadStart={handleFileUploadStart}
                  onUploadEnd={handleFileUploadEnd}
                />
              </List.Item>

              {connection && connection.investorProfile && (
                <List.Item>
                  <EmailLink investorProfile={connection.investorProfile} />
                </List.Item>
              )}
            </List>
          </div>
        </div>
      )}
    </DevicesConsumer>
  )
}

MessageField.propTypes = {
  connecting: PropTypes.bool,
  channel: PropTypes.string,
  authKey: PropTypes.string,
  connection: PropTypes.object,
  currentUserId: PropTypes.number,
  onChange: PropTypes.func,
}

export default function MessageFieldContainer(props) {
  return (
    <CurrentUserConsumer>
      {({ currentUser }) => (
        <MessageField {...props} currentUserId={currentUser.id} />
      )}
    </CurrentUserConsumer>
  )
}
